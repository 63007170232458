/**************************************************************************
    会社情報　トップ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.top {

// メインビジュアル
.bl_mvSub {
    background-image: url('../img/company/top/mv.webp');

    .el_title_icon {
        width: 40px;

        @include g.mq('md') {
            width: 57px;
        }

        .company {
            fill: g.color('white');
        }
    }
}

// アンカーリンク
.bl_anchorLink {
    margin-top: 50px;

    @include g.mq('md') {
        margin-top: 85px;
    }
}

// トップメッセージ
.bl_message {
    margin-top: 70px;

    @include g.mq('md') {
        margin-top: 128px;
    }
}

// 会社概要
.un_about {
    margin-top: 50px;

    @include g.mq('md') {
        margin-top: 90px;
    }

    .el_btn {
        margin-top: 30px;

        @include g.mq('md') {
            margin-top: 50px;
        }
    }
}

// ○分でわかる東武トップツアーズ
.bl_about {
    margin-top: 80px;

    @include g.mq('md') {
        margin-top: 161px;
    }
}

// 考え方・価値創造プロセス
.un_thinking {
    padding: 110px 0 75px;

    @include g.mq('md') {
        padding: 195px 0 128px;
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;

        @include g.mq('md') {
            gap: 75px;
        }
    }

    .bl_contentsRow {
        $this: '.bl_contentsRow';
    
        @include g.mq('lg') {
            padding-top: 0;
        }
    
        &_title, &_subTitle, &_text {
            color: g.color('white');
        }
    
        &_img {
            @include g.mq('lg') {
                bottom: 0;
                margin: auto;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    
        // 経営理念
        &.is_philosophy {
            #{$this}_bg {
                background: url('../img/company/top/philosophy_bg.webp') no-repeat border-box center/cover;
            }
        }
    
        // サステナビリティ
        &.is_sustainability {
            #{$this}_bg {
                background: url('../img/company/top/sustainability_bg.webp') no-repeat border-box center/cover;
            }
        }
    }
}


// 中期経営計画PDF
.un_pdf {
    margin-top: 50px;
    grid-template-columns: 1fr;
    
    @include g.mq('md') {
        margin-top: 90px;
    }
    
    @include g.mq('lg') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 60px;
    }

    &_text {
        font-size: 1.6rem;
        line-height: math.div(34, 18);

    @include g.mq('md') {
        font-size: 1.8rem;
    }

        @include g.mq('md') {
            text-align: center;
        }

        @include g.mq('lg') {
            text-align: left;
        }
    }

    .el_pdfBtn {
        @include g.mq('lg_max') {
            margin-top: 20px;
        }
    }
}

// サービス紹介
.un_service {
    padding: 75px 0 90px;
    background-image: linear-gradient(-135deg, #00d380 0%, #4f8aff 100%);
    
    @include g.mq('md') {
        padding: 90px 0 129px;
    }

    .bl_subNav {
        margin-top: 30px;
    }
}

// その他リンク
.un_others {
    padding: 75px 0;

    @include g.mq('md') {
        padding: 105px 0;
    }

    .el_btn {
        margin-top: 35px;

        @include g.mq('md') {
            margin-top: 70px;
        }
    }
}

// キャラクター紹介
.un_character {
    padding: 40px 0 50px;
    background-color: g.color('white');

    @include g.mq('md') {
        padding: 87px 0 80px;
    }

    .ly_inner {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;

        @include g.mq('lg') {
            grid-template-columns: 450px 1fr;
            align-items: end;
            gap: 70px;
        }
    }

    &_text {
        font-size: 1.6rem;
        line-height: math.div(39.685, 18);

        @include g.mq('md') {
            font-size: 1.8rem;
        }
    }

    .el_btn {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }
}

}