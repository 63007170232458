/*------------------------------------------------------------------------
	タブ切り替え
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_tab {
    &_head {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        max-width: g.$inner-width-sp;
        margin-inline: auto;
        
        @include g.mq('md') {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
        
        @include g.mq('lg') {
            gap: 60px;
        }
        
        @include g.mq('xl') {
            max-width: g.$inner_width_medium;
        }
    }

    &_headItem {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        font-size: 1.6rem;
        letter-spacing: 0;
        color: g.color('key');
        border: 1px solid g.color('key');
        border-radius: 100vmax;
        transition: opacity 0.2s ease;
        
        @include g.mq('md') {
            font-size: 2rem;
            height: 79px;
        }
        
        &:not(.is_active) {
            cursor: pointer;
    
            &:hover {
                opacity: 0.7;
            }
        }
        
        &.is_active {
            color: g.color('white');
            background-color: g.color('key');
            
            @include g.mq('md') {
                @include g.triangle('bottom', 30px, 15px, g.color('key'), 'bottom');

                &::after {
                    transform: translateY(100%);
                }
            }
        }
    }

    &_body {
        margin-top: 50px;

        @include g.mq('md') {
            margin-top: 90px;
        }
    }

    &_bodyItem {
        display: none;

        &.is_active {
            display: block;
        }
    }
}

