/*------------------------------------------------------------------------
    別タブアイコン付きリンク
------------------------------------------------------------------------*/
@use "../../global" as g;

.el_link_blank {
    display: inline-flex;
    gap: 3px;

    &::after {
        content: '';
        width: 12px;
        height: 9px;
        margin-top: 8px;
        background: url('../img/common/icon_blank.svg') no-repeat border-box center/cover;
    }
}

.el_text_blank {
    color: g.color('key');
    word-break: break-all;

    &::after {
        content: '';
        display: inline-block;
        margin-left: 4px;
        width: 12px;
        height: 9px;
        margin-top: 8px;
        background: url('../img/common/icon_blank_key.svg') no-repeat border-box center/cover;
    }
}