/**************************************************************************
    会社情報　沿革
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.history {
  // メインビジュアル
  .bl_mvSub {
    background-image: url("../img/company/history/mv.webp");

    .el_title_icon {
      width: 40px;

      @include g.mq('md') {
          width: 57px;
      }

      .company {
          fill: g.color('white');
      }
    }
  }

  // セクション大枠
  .un_section {
    padding: 70px 0 120px;
    background: url("../img/company/history/bg_03.webp") repeat-y border-box 50%
      50%;
    background-size: 100% auto;

    @include g.mq("md") {
      padding: 120px 0 200px;
    }

    // グラデーション
    &:first-of-type {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        inset: 0 auto auto 0;
        width: 100%;
        height: 350px;
        background-image: linear-gradient(to bottom, #fff, transparent);
      }

      .ly_inner {
        position: relative;
        z-index: 1;
      }
    }
  }

  // 沿革リスト
  .un_timeline {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .un_timeline_listItem {
    display: flex;
    &:last-child {
      .un_timeline_date::before {
        display: none;
      }
    }
  }

  // 日付
  .un_timeline_date {
    position: relative;
    width: 20%;
    padding: 0 14px 0 0;
    text-align: right;

    @include g.mq("md") {
      padding: 0 30px 0 0;
    }

    // 沿革縦ライン
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0.5lh;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: g.color("border_dark");
      z-index: 2;
    }
  }

  // 出来事
  .un_timeline_content {
    position: relative;
    width: 80%;
    padding: 0 0 60px 14px;

    @include g.mq("md") {
      padding: 0 20px 60px 30px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0.8rem;
      left: -7px;
      width: 14px;
      height: 14px;
      background-color: g.color("key");
      border-radius: 10px;
      z-index: 3;
      @include g.mq("md") {
        top: 1rem;
      }
    }

    img {
      background-color: g.color("border_dark");
      width: 95%;
      display: block;
      margin-top: 10px;

      @include g.mq("md") {
        width: 45%;
      }
    }

    &_caption {
      display: block;
      font-size: 1.2rem;
    }
  }

  .is_hasimage {
    position: relative;
    &::before {
      display: block;
      position: absolute;
      content: "";
      top: -30px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../img/company/history/bg_box.webp") center center /
        cover repeat-y;
      box-shadow: 0 0 8px 0 g.color("border");
      border-radius: 3px;
      z-index: 0;
    }
  }

  // 合併以降
  .un_mergerBox {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    background-image: linear-gradient(45deg, #00b5ce 0%, #003dc7 100%);
    border-radius: 100px;
    color: g.color("white");

    @include g.mq("md") {
      padding: 25px 0;
    }
  }

  .un_mergerBox_content {
    text-align: center;
    font-size: 1.2em;

    span {
      font-size: 1.2em;
    }
  }

  .is_afterMerger{
    margin-top: 100px;
  }
}
// #company.history
