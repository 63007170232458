/*------------------------------------------------------------------------
	ページ内リンク
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_anchorLink {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @include g.mq('sm') {
        width: 95%;
    }
    
    @include g.mq('md') {
        gap: 15px 30px;
    }
    
    @include g.mq('xl') {
        grid-template-columns: repeat(4, 1fr);
    }
    
    &.is_col3 {
        @include g.mq('lg') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .el_btn {
        justify-content: space-between;
        width: 100%;
        height: 50px;
        gap: 0;

        @include g.mq('md') {
            padding: 0 22px 0 37px;
            height: 62px;
        }

        span {
            font-size: 1.2rem;
            
            @include g.mq('md') {
                font-size: 2rem;
            }
        }
    }
}

