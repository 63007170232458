/**************************************************************************
    CONTACT US
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#en.contactus {

// メインビジュアル
.bl_mvSub {
    background-image: url('../img/en/contactus/mv.webp');
}

// セクション
.un_section {
    position: relative;
    z-index: 1;
    padding: 70px 0 100px;

    @include g.mq('md') {
        padding: 128px 0 160px;
    }

    &_row {
        margin-top: 30px;

        @include g.mq('md') {
            display: flex;
            gap: 40px;
            margin-top: 50px;
        }
    }

    &_img {
        text-align: center;
        
        @include g.mq('md') {
            width: min(40%, 400px);
        }
    }

    &_textArea {
        @include g.mq('md') {
            flex: 1;
        }
    }

    &_text {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 0;
        }
    }

    .el_btn {
        margin-top: 30px;
        margin-inline: auto;

        @include g.mq('md') {
            margin-top: 50px;
        }
    }
}

}