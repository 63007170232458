/**************************************************************************
    サステナビリティ　人権の尊重とダイバーシティ＆インクルージョンの推進
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability.social-diversity {

// セクション大枠
.un_section {
  padding: 70px 0 120px;

  @include g.mq('md') {
    padding: 120px 0 200px;
  }

  // 背景画像
  &.is_bg_A {
    background: url('../img/sustainability/materiality/social-diversity/bg_A.webp') no-repeat border-box center/cover;
  }
  &.is_bg_loop {
    background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
    background-size: 100% auto;
  }

  // グラデーション
  &:first-of-type {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto auto 0;
      width: 100%;
      height: 350px;
      background-image: linear-gradient(to bottom, #fff, transparent);
    }

    .ly_inner {
      position: relative;
      z-index: 1;
    }
  }
}

// イントロ文
.un_intro {
  max-width: 1000px;
  margin: 0 auto 70px;

  @include g.mq('md') {
    margin-bottom: 120px;
  }
}

// グリッドレイアウト
.un_grid {
  @include g.mq('md') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3%;
  }

  &.is_gapLarge {
    @include g.mq('md') {
      gap: 8%;
    }
  }

  &.is_style2 {
    @include g.mq('md') {
      grid-template-columns: auto 30%;
    }
  }

  &_item:nth-child(1) {
  }
  &.is_reverse .un_grid_item:nth-child(1) {
    @include g.mq('md') {
      order: 2;
    }
  }

  &_item:nth-child(2) {
    margin-top: 20px;

    @include g.mq('md') {
      margin-top: 0;
    }
  }
  &.is_reverse .un_grid_item:nth-child(2) {
    @include g.mq('md') {
      order: 1;
    }
  }
}

// figure
.un_figure {
  display: block;

  img {
    display: block;
  }
  &.is_heightFixed img {
    height: 200px;

    @include g.mq('md') {
      height: 300px;
    }
  }

  figcaption {
    margin: 10px 0 0;
    line-height: 1.5;
    font-size: 1.2rem;

    @include g.mq('md') {
      font-size: 1.4rem;
    }
  }
}

// 最小見出し
.un_smallTitle {
  padding-left: 10px;
  margin-block: calc((1em - 1lh) / 2);
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
  border-left: 3px solid g.color('key');
  color: g.color('key');

  @include g.mq('md') {
    font-size: 1.8rem;
  }
}

// 主な取組方針 - コンテナ
.un_policyWrapper {
  padding: 10px;
  background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
  border-radius: 6px;

  @include g.mq('lg') {
    padding: 30px 24px 47px;
    border-radius: 10px;
  }

  > * + * {
    margin-top: 10px;

    @include g.mq('lg') {
      margin-top: 20px;
    }
  }

  &_title {
    line-height: 1.5;
    color: g.color('key');
    font-weight: bold;

    @include g.mq('lg') {
      margin-right: 15px;
      font-size: 2.7rem;
    }

    &_label {
      display: inline-block;
      background-color: g.color('key');
      color: #fff;
      border-radius: 9999px;
      padding: 5px 10px;
      margin: 0 10px 5px 0;
      font-size: 1.4rem;

      @include g.mq('lg') {
        padding: 5px 20px;
        margin-right: 15px;
        font-size: 2.7rem;
      }
    }

    &_text {
      display: inline-block;
      font-size: 1.6rem;

      @include g.mq('lg') {
        font-size: 2.3rem;
      }
    }
  }

  &_grid {
    @include g.mq('lg') {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.7%;
    }
  }
}

// 主な取組方針
.un_policy {
  display: grid;
  grid-row: span 3;
  grid-template-rows: subgrid;
  gap: 10px;
  padding: 24px 18px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 15px rgb(0 0 81 / 0.1);
  line-height: 1.5;

  @include g.mq('lg') {
    gap: 20px;
    padding: 40px 24px;
    border-radius: 10px;
  }

  & + .un_policy {
    margin-top: 10px;

    @include g.mq('lg') {
      margin-top: 0;
    }
  }

  &_header {
    > * + * {
      margin-top: 10px;
    }
  }

  &_icon {
    display: block;
    width: 50px;
    aspect-ratio: 1/1;
    margin-inline: auto;

    &.is_heart {
      background: url('../img/sustainability/materiality/social-diversity/icon_heart.svg') no-repeat center/contain;
    }

    &.is_flower {
      background: url('../img/sustainability/materiality/social-diversity/icon_flower.svg') no-repeat center/contain;
    }
  }

  &_title {
    text-align: center;
    color: g.color('key');
    font-weight: bold;
    font-size: 1.8rem;

    @include g.mq('lg') {
      font-size: 2.3rem;
    }
  }

  &_subtitle {
    text-align: center;
    color: g.color('key');
    font-weight: bold;
    font-size: 1.6rem;

    @include g.mq('lg') {
      font-size: 1.8rem;
    }
  }

  &_text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: g.color('key');
    font-weight: 500;
  }

  &_list {
    list-style: disc;
    padding-left: 24px;

    > li + li {
      margin-top: 10px;

      @include g.mq('lg') {
        margin-top: 20px;
      }
    }
  }
}

.un_logoImage {
  display: block;
  width: 200px;
  margin-inline: auto;
}

}