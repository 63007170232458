/*------------------------------------------------------------------------
	フロートバナー
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";
@use "sass:map";

.el_floatBanner {
	position: fixed;
	inset: auto auto 0 0;
    z-index: map.get(g.$z_index, 'banner');
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.is_show {
        visibility: visible;
        opacity: 1;
    }

    &_closeWrap {
        position: relative;
        width: 1em;
        height: 1em;
        margin: 0 0 5px 5px;
        font-size: 25px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid g.color('base');
    }
    
    &_close {
        @include g.close-icon(12px, 1px, g.color('base'));
        position: absolute;
        inset: 0;
        margin: auto;
    }

    a {
        display: block;
    }

    img {
        @include g.mq('sm') {
            width: 100%;
        }
    }
}