/*------------------------------------------------------------------------
	about
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_about {
	$this: &;
	position: relative;

	// 会社概要
	&.is_company {
		@include g.mq('lg') {
			background-repeat: no-repeat;
			background-position: center left;
			background-size: auto 100%;
		}
	}
	
	&_bg {
		overflow: hidden;
		position: relative;
		z-index: 0;
		padding: 50px g.get-inner-side(g.$inner-width-sp);
		color: #fff;

		@include g.mq('lg') {
			display: grid;
			place-content: center start;
			width: calc(g.get-inner-side(g.$inner-width-sp) + 527px);
			min-width: 728px;
			height: 628px;
			padding: 0 g.get-inner-side(g.$inner-width-sp) 0 120px;
			margin-left: auto;
		}

		@include g.mq('xl') {
			width: calc(g.get-inner-side() + 530px);
			padding-right: g.get-inner-side();
		}

		&::before, &::after {
			content: '';
			position: absolute;
			inset: 0;
		}
		
		// ぼかし背景
		&::before {
			z-index: -1;
			filter: blur(8px);
			background-repeat: no-repeat;
			background-position: center left;
			background-size: cover;

			@include g.mq('lg') {
				background-position: center left calc((100vw - 742px) * -1);
				background-size: auto 100%;
			}
			
			@media screen and (min-width: 1700px) {
				background-position: center left -56vw;
			}
		}

		// 乗算黒板
		&::after {
			z-index: 0;
			background-color: rgba(#8e8e8e, 0.3);
			mix-blend-mode: multiply;
		}
	}

	&_textArea {
		position: relative;
		z-index: 1;

		@include g.mq('lg') {
			margin-top: 5px;
		}
	}

	&_title {
		font-size: 2.6rem;
		font-weight: bold;
		line-height: math.div(56.6929, 36);

		@include g.mq('md') {
			font-size: 3.5rem;
		}
	}

	&_text {
		margin-top: 20px;
		font-size: 1.6rem;
		line-height: math.div(40, 18);

		@include g.mq('md') {
			font-size: 1.8rem;
		}
	}

	&_img {
		position: relative;
		z-index: 1;
		width: g.$inner-width-sp;
		margin-top: -30px;
		margin-inline: auto;
		text-align: center;
	}

	// 会社概要
	&.is_company {
		@include g.mq('lg') {
			background-image: url('../img/company/top/about_bg.webp');
		}
		
		#{$this}_bg {
			&::before {
				background-image: url('../img/company/top/about_bg.webp');
			}
		}
	}

	.el_btn {
		margin-top: 20px;

		@include g.mq('lg') {
			margin-top: 25px;
		}
	}
}
