/**************************************************************************
    プライバシーポリシー
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#policy.privacy {

    // メインビジュアル
    .bl_mvSub {
        background-image: url(../img/privacy/mv.webp);
        height: 300px;

        @include g.mq('md') {
            height: 436px;
        }
    }

    // ページ内リンク
    .bl_anchorLink {
        margin-top: 20px;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;

        @include g.mq('md') {
            margin-top: 30px;
        }

        @include g.mq('xl') {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    // コンテンツ
    .un_sec {
        padding: 70px 0 80px;

        @include g.mq('md') {
            padding: 100px 0;
        }

        &.is_bg {
            background: #f5f7fb;
        }

        p {
            text-align: left;
        }

        &_mainTit {
            width: fit-content;
            margin-inline: auto;
            margin-bottom: 20px;
            line-height: 1.5;
            font-weight: bold;
            font-size: 3rem;

            @include g.mq('md') {
                font-size: 4.2rem;
                margin-bottom: 40px;
            }

            &.is_bordered {
                width: 100%;
                padding-bottom: 10px;
                text-align: center;
                border-bottom: 1px solid g.color('key');

                @include g.mq('md') {
                    padding-bottom: 20px;
                }
            }
        }

        &_list {
            counter-reset: tit;
        }

        &_item {
            margin-top: 52px;

            >p {
                margin-top: 1em;
            }
        }

        &_tit {
            counter-increment: tit;
            color: g.color('key');
            font-size: 1.6rem;
            margin-bottom: 6px;
            position: relative;
            padding-left: 2.5rem;
            margin-top: 0.5em;
            line-height: 1.8;
            display: flex;
            gap: .2em;

            @include g.mq('md') {
                font-size: 1.8rem;
            }

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counter(tit) ".";
            }
        }

        &_titSmall {
            margin-top: 20px;
            font-weight: 700;
        }

        &_discList {
            list-style-type: disc;
            padding-left: 3.5rem;
        }

        &_numList {
            margin-top: 1em;
            counter-reset: numList;

            > li {
                counter-increment: numList;
                position: relative;
                padding-left: 3.5rem;
                margin-top: 0.5em;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "(" counter(numList) ")";
                }
            }

            > li > ul {
                margin-left: -3.5rem;
            }
        }

        &_kanaList {
            margin-top: .5em;
            counter-reset: kanaList;

            > li {
                counter-increment: kanaList;
                position: relative;
                padding-left: 3.5rem;
                margin-top: 0.5em;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: counter(kanaList,katakana);
                }
            }
        }

        &_kanaList02 {
            counter-reset: kanaList02;

            > li {
                counter-increment: kanaList02;
                position: relative;
                padding-left: 3.5rem;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "(" counter(kanaList02,katakana) ")";
                }
            }
        }

        &_endTxt {
            margin-top: 20px;

            >p {
                font-size: 1.2rem;
                text-align: right;

                @include g.mq('md') {
                    font-size: 1.4rem;
                }
            }
        }

        &_largeTxt {
            font-size: 1.6rem;

            @include g.mq('md') {
                font-size: 1.8rem;
            }
        }

        &_info {
            padding: 15px 20px;
            background: rgb(g.color('key'), .07);
            margin-bottom: 2em;

            @include g.mq('md') {
                padding: 30px 50px;
            }
        }

        &_pdfLink {
            color: g.color('key');
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            line-height: 1.8;

            &::after {
                content: '';
                width: 14px;
                height: 18px;
                background: url('../img/common/icon_pdf.svg') no-repeat border-box center/cover;
                display: block;
            }
        }

        &_telList {
            dt, dd {
                @include g.mq('md') {
                display: inline-block;
                }
            }

            dd {
                @include g.mq('md') {
                margin-left: 1em;
                }
            }
        }

    }
}