/*------------------------------------------------------------------------
	ロゴ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_logo {
    a {
        display: inline-block;
    }

    img {
        @include g.mq('sm') {
            width: 253px;
        }
    }
}