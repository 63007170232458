/*------------------------------------------------------------------------
    メイン
------------------------------------------------------------------------*/
@use "../../global" as g;

.ly_main {
    padding-top: map-get(g.$header_h, 'sm');
    
    @include g.mq('md') {
        padding-top: map-get(g.$header_h, 'md');
    }
    
    @include g.mq('lg') {
        padding-top: map-get(g.$header_h, 'lg');
    }
    
    &.is_en {
        @include g.mq('lg') {
            padding-top: map-get(g.$header_h, 'md');
        }
    }

    #top & {
        padding: 0;
    }
}