/**************************************************************************
    採用情報 トップ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#employment.top {

/*------------------------------------------------------------------------
  パンくずリスト
------------------------------------------------------------------------*/
.bl_breadcrumb {
  margin-bottom: 40px;

  @include g.mq('md') {
    margin-bottom: 54px;
  }
}

/*------------------------------------------------------------------------
  subNav
------------------------------------------------------------------------*/
.bl_subNav_list.is_col2 {
  grid-template-columns: 1fr;

  @include g.mq('lg') {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

.bl_subNav_text {
  margin-top: -10px;
  padding: 10px 15px;
  background-color: #fff;

  @include g.mq('md') {
    padding: 0 27px 30px;
  }

  @include g.mq('lg') {
    padding: 0 27px 30px;
  }
}

/*------------------------------------------------------------------------
  ミッション
------------------------------------------------------------------------*/
.un_mission {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  background: url(../img/employment/mission_bg_sp.webp) no-repeat top center/cover;

  @include g.mq('md') {
    margin-bottom: 70px;
    background-image: url(../img/employment/mission_bg_pc.webp);
  }

  @include g.mq('lg') {
    margin-bottom: 85px;
  }

  &_animation {
    position: absolute;
    inset: auto auto 0 0;
    width: 100%;

    &_plane, &_logo {
      position: absolute;
    }
    
    &_plane {
      bottom: g.get_vw(-67, 1920);
      left: 27.5%;
      translate: 103% -103%;
      aspect-ratio: 494/214;
      width: g.get_vw(494, 1920);
      background: url(../img/employment/mission_bottom_plane.svg) no-repeat top center/cover;

      // wow.jsで飛行機を動かす
      &.animate__animated {
        animation: move-to-right-top 3s ease-out both;

        @keyframes move-to-right-top {
          0% {
            translate: 0 0;
          }
          100% {
            translate: 103% -103%;
          }
        }
      }
    }
  
    &_logo {
      bottom: -1px;
      left: 50%;
      content: '';
      width: 100%;
      max-width: 1920px;
      aspect-ratio: 1920/270;
      translate: -50% 0;
      background: url(../img/employment/mission_bottom.svg) no-repeat top center/contain;
    }
  }
}

// レイアウト枠
.un_mission_inner {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;

  @include g.mq('md') {
    display: grid;
    grid-template-columns: 30% 1fr;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: 40% 1fr;
  }
}

// 内容
.un_mission_contents {
  order: 2;
  width: g.$inner_width_sp;
  padding: 40px 0 15vw;
  margin: 0 auto;

  @include g.mq('md') {
    width: 80%;
    padding: 60px 0 20vw;
  }

  @include g.mq('lg') {
    width: 75%;
    padding-top: 80px;
  }

  @include g.mq('xl') {
    width: 70%;
    padding-top: 90px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: 145px;
    padding-bottom: 380px;
  }
}

// タイトル
.un_mission_title {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  color: g.color('key');

  @include g.mq('lg') {
    margin-bottom: 20px;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    margin-right: 15px;
    background: g.color('key');

    @include g.mq('md') {
      width: 50px;
    }
  }
}

// メッセージ
.un_mission_message {
  margin: 0 0 20px;
  line-height: 2;
  font-size: 1.5rem;

  @include g.mq('md') {
    margin-bottom: 30px;
    line-height: 2.7;
    font-size: 1.8rem;
  }

  @include g.mq('lg') {
    margin-bottom: 40px;
    font-size: 2.2rem;
  }

  @include g.mq('xl') {
    margin-bottom: 50px;
    font-size: 2.6rem;
  }
}

// イメージ画像
.un_mission_image {
  order: 1;
  position: relative;
  display: block;
  height: 40vw;

  @include g.mq('md') {
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include g.mq('md') {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/*------------------------------------------------------------------------
  背景画像用ラッパー
------------------------------------------------------------------------*/
.un_wrapper {
  padding: 0 0 60px;
  background: url(../img/employment/bg_sp.webp) no-repeat top center;
  background-size: cover;

  @include g.mq('md') {
    padding-bottom: 80px;
    background-image: url(../img/employment/bg_pc.webp);
  }

  @include g.mq('lg') {
    padding-bottom: 110px;
  }

  @include g.mq('xl') {
    padding-bottom: 130px;
  }

  @media screen and (min-width: 1920px) {
    padding-bottom: 165px;
  }

  > * + * {
    margin-top: 50px;

    @include g.mq('md') {
      margin-top: 90px;
    }

    @include g.mq('lg') {
      margin-top: 110px;
    }

    @include g.mq('xl') {
      margin-top: 130px;
    }

    @media screen and (min-width: 1920px) {
      margin-top: 148px;
    }
  }
}

/*------------------------------------------------------------------------
  汎用セクション
------------------------------------------------------------------------*/
.un_section {
  padding: 40px 0 0;
}

// カラムレイアウト
.un_section_row {
  width: g.$inner_width_sp;
	max-width: 1920px;
	margin: 0 auto;

  @include g.mq('lg') {
    display: flex;
    width: 100%;
  }
}
.un_section_col {
  position: relative;

  @include g.mq('lg') {
    width: 50%;

    &.is_items_center {
      display: flex;
      align-items: center;
    }
  }

  &:nth-child(2) {
    margin-top: -40px;

    @include g.mq('lg') {
      margin-top: 0;
    }
  }
}
.un_section.is_reverse .un_section_col {
  @include g.mq('lg') {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
}

// 英字テキスト
.un_section_typography {
  z-index: 2;
  position: absolute;
  top: -1.2em;
  left: 0;
  display: inline-block;
  width: 100vw;
  overflow: hidden;
  line-height: 1.4;
  white-space: nowrap;
  word-break: keep-all;
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: 0;
  
  span {
    background-clip: text;
    opacity: 0.08;
    -webkit-text-fill-color: transparent;
  }

  @include g.mq('md') {
    width: auto;
    overflow: visible;
    font-size: 7rem;
  }

  @include g.mq('lg') {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0.2em;
    margin: 0.1em 0 0.1em;
    line-height: 1.3;
  }

  @include g.mq('xl') {
    font-size: 9rem;
  }

  @media (min-width: 1920px) {
    font-size: 12.9rem;
  }

  br {
    display: none;

    @include g.mq('lg') {
      display: initial;
    }
  }
}
.un_section:nth-child(3n-2) .un_section_typography span {
  background-image: linear-gradient(90deg, #ffe207 0%, #008ae7 100%);
}
.un_section:nth-child(3n-1) .un_section_typography span {
  background-image: linear-gradient(90deg, #00d3ff 0%, #000099 100%);
}
.un_section:nth-child(3n) .un_section_typography span {
  background-image: linear-gradient(90deg, #45e2a9 0%, #008ae7 100%);
}
.un_section.is_reverse .un_section_typography {
  @include g.mq('lg') {
    padding-left: 0;
    padding-right: 0.4em;
    text-align: right;
  }
}

// タイトル
.un_section_title {
  line-height: 1.5;
  font-weight: bold;
}

// タイトル（日本語）
.un_section_title_ja {
  margin: 0 0 0.3em;
  font-size: 2.4rem;

  @include g.mq('md') {
    font-size: 3.6rem;
  }

  @include g.mq('xl') {
    font-size: 5.4rem;
  }
}

// タイトル（英語）
.un_section_title_en {
  text-align: left;

  @include g.mq('md') {
    font-size: 1.6rem;
  }

  @include g.mq('xl') {
    font-size: 2.4rem;
  }
}

// イメージ画像
.un_section_image {
  position: relative;
  z-index: 2;

  img {
    display: block;
    width: 100%;
  }
}

// 内容
.un_section_body {
  position: relative;
  width: 100%;
  padding: 30px 0 70px;
  color: #fff;

  @include g.mq('md') {
    padding: 40px 0 80px;
  }

  @include g.mq('lg') {
    padding: 15% 10%;
  }

  @include g.mq('xl') {
    padding: 13% 10%;
  }

  @media (min-width: 1920px) {
    padding: 135px 0 140px 145px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 100%;

    @include g.mq('md') {
      height: 100%;
    }

    @include g.mq('lg') {
      width: 100%;
      height: 100%;
      top: 0;
      left: auto;
      right: -100px;
      width: calc(100% + 100px);
    }
  }
}
.un_section:nth-child(3n-2) .un_section_body::before {
  background: linear-gradient(45deg, #ffe207 0%, #008ae7 100%);
}
.un_section:nth-child(3n-1) .un_section_body::before {
  background: linear-gradient(45deg, #00d3ff 0%, #000099 100%);
}
.un_section:nth-child(3n) .un_section_body::before {
  background: linear-gradient(135deg, #45e2a9 0%, #008ae7 100%);
}
.un_section.is_reverse .un_section_body {
  @include g.mq('lg') {
    padding-right: 40px;

    &::before {
      left: -100px;
      right: auto;
    }
  }

  @include g.mq('xl') {
    padding-left: 0;
    padding-right: 80px;
  }

  @media (min-width: 1920px) {
    padding-right: 140px;
  }
}

// 内容 > インナー
.un_section_body_inner {
  position: relative;

  @include g.mq('xl') {
    width: calc(100% - 80px);
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('md') {
      margin-top: 40px;
    }

    @include g.mq('lg') {
      margin-top: 50px;
    }
  }
}
.un_section.is_reverse .un_section_body_inner {
  @include g.mq('lg') {
    padding: 0;
    margin: 0 0 0 auto;
  }
}

/*------------------------------------------------------------------------
  派遣業務バナー
------------------------------------------------------------------------*/
.un_banner {
  position: relative;
  max-width: 940px;
  padding: 55px 20px;
  margin-inline: auto;

  @include g.mq('md') {
    padding: 70px 40px;
  }

  @include g.mq('lg') {
    padding: 80px 40px;
  }
}

// リンク
.un_banner_link {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

// アイコン
.un_banner_icon {
  display: grid;
  place-items: center;
  width: 50px;
  aspect-ratio: 1/1;
  margin-right: 10px;
  border-radius: 50%;
  background: #fff;

  @include g.mq('md') {
    width: 70px;
    margin-right: 15px;
  }

  @include g.mq('lg') {
    width: 100px;
    margin-right: 20px;
  }

  img {
    display: block;
    width: 53%;
  }
}

// リンクラベル
.un_banner_label {
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.8rem;

  @include g.mq('md') {
    font-size: 2.4rem;
  }

  @include g.mq('lg') {
    font-size: 3.4rem;
  }

  span {
    font-size: 1.3rem;

    @include g.mq('md') {
      font-size: 1.8rem;
    }

    @include g.mq('lg') {
      font-size: 2.6rem;
    }
  }
}

// イメージ画像
.un_banner_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------------------------------------------------------
  会社情報セクション
------------------------------------------------------------------------*/
.un_companySection {
}

.un_companySection_header {
  position: relative;
  padding: 13vi 0 20vi;

  @include g.mq('xl') {
    padding: 140px 0 280px;
  }
}

.un_companySection_title {
  z-index: 1;
  position: relative;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;

  a {
    display: inline-block;
  }
}

.un_companySection_title_en {
  margin: 0 0 0.3em;
  font-size: 2rem;

  @include g.mq('md') {
    font-size: 3.5rem;
  }

  @include g.mq('lg') {
    font-size: 6rem;
  }
}

.un_companySection_title_ja {
  width: fit-content;
  margin: 0 auto;
  padding: 10px 0 0;
  border-top: 1px solid g.color('key');

  @include g.mq('xl') {
    padding-top: 20px;
  }
}

.un_companySection_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vi;
  height: 100%;
  margin-inline: calc(50% - 50vi);
  background-color: #000;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }
}

.un_companySection_body {
  position: relative;
  margin: -10vi 0 0;

  @include g.mq('xl') {
    margin-top: -175px;
  }

  > * + * {
    margin-top: 15px;

    @include g.mq('lg') {
      margin-top: 30px;
    }
  }
}

}