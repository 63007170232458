/*------------------------------------------------------------------------
    パンくず
------------------------------------------------------------------------*/
@use "../../global" as g;

.bl_breadcrumb {
    display: flex;
    flex-wrap: wrap;
    gap: 0 18px;
    margin-top: 29px;

    &_item {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.2rem;
        letter-spacing: 0;
        color: g.color('base');
        
        @include g.mq('md') {
            font-size: 1.4rem;
        }

        &:not(:last-of-type) {
            &::after {
                @include g.arrow('right', 8px, 1px, g.color('key'));
                content: '';
            }
        }

        &.is_home {
            a {
                width: 13px;
                aspect-ratio: 1/1;
                background: url('../img/common/icon_home.svg') no-repeat border-box center/cover;
            }
        }
    }
}