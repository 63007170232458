/**************************************************************************
    事業紹介　詳細
**************************************************************************/
@use "../../global" as g;
@use "sass:math";
@use 'sass:color';

#business.travel {

    // リンク
    .bl_mvSub {
        background-image: url(../img/business/individual/travel/mv.webp);
        background-position: center left;
    }

    .un_travelCont {
        margin: 70px 0;

        @include g.mq('md') {
            position: relative;
            margin: 105px auto 120px;
        }

        &:last-of-type {
            margin-bottom: 120px;

            @include g.mq('md') {
                margin-bottom: 170px;
            }
        }

        &.is_salesSite {
            @include g.mq('md') {
                margin-bottom: 152px;
            }
        }

        figure {
            img {
                width: 100%;
            }
        }

        &_head {
            margin-bottom: 30px;

            @include g.mq('md') {
                margin-bottom: 53px;
                margin-right: auto;
            }

            @include g.mq('lg') {
                width: min(500px, 52.5%);
            }

            @include g.mq('xl') {
                width: min(calc(500px + 40px + 15px), 100%);
                padding-left: 40px;
            }

            &.is_salesSite {
                @include g.mq('lg') {
                    margin-right: 0;
                    margin-left: auto;
                }

                @include g.mq('xl') {
                    width: 685px;
                    padding-left: 0;
                    padding-right: 40px;
                    margin-bottom: 40px;
                }
            }

            p {
                letter-spacing: 0;
                line-height: math.div(28, 16);
            }
        }

        .el_gradTitle_en {
            @include g.mq('md') {
                display: block;
                margin-right: auto;
                text-align: left;
                margin-bottom: 16px;
            }

            &.is_salesSite {
                @include g.mq('md') {
                    margin-bottom: 7px;
                }
            }

            &.is_counterStore {
                @include g.mq('md') {
                    margin-bottom: 10px;
                }
            }
        }

        .el_gradTitle_ja {
            margin-bottom: 30px;

            @include g.mq('md') {
                text-align: left;
                line-height: math.div(64.54, 42);
                margin-bottom: 21px;
            }
        }

        &_imgList {
            width: g.$inner_width_sp;
            margin: 0 auto 30px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 3vw 5%;

            @include g.mq('md') {
                gap: 27px;
                margin-bottom: 53px;
            }

            @include g.mq('lg') {
                position: absolute;
                top: 70px;
                right: 0;
                gap: 10px;
                margin-right: 10px;
                margin-bottom: 0;
                width: 43%;
                max-width: 750px;
            }

            @include g.mq('xl') {
                width: calc(g.get-inner-side() + 640px);
                right: auto;
                left: calc(600px + g.get-inner-side());
                margin-right: 40px;
                gap: 27px;
            }
        }

        &_img {
            width: g.$inner_width_sp;
            margin: 0 auto;
            margin-bottom: 30px;

            @include g.mq('md') {
                margin-bottom: 53px;
            }

            @include g.mq('lg') {
                position: absolute;
                top: 12px;
                right: 0;
                margin: 0;
                width: 43%;
            }

            @include g.mq('xl') {
                width: calc(g.get-inner-side() + 507px);
            }

            &.is_salesSite {
                @include g.mq('lg') {
                    right: auto;
                    left: 0;
                }
            }

            &.is_counterStore {
                @include g.mq('lg') {
                    margin-top: -21px;
                }
            }
        }

        &_foot {
            padding: 30px 0;
            background-image: linear-gradient(45deg, rgb(0 211 128 / .8) 0%, rgb(79 138 255 / .8) 100%);

            @include g.mq('md') {
                padding: 70px 0;
            }

            @include g.mq('lg') {
                width: 90%;
                padding: 76px calc((100% - g.$inner_width_sp) / 2) 77px;
                margin-right: auto;
            }

            @include g.mq('xl') {
                width: calc(1145px + g.get-inner-side());
                padding: 76px 24px 50px calc(g.get-inner-side() + 40px + 7px);
            }

            &.is_salesSite {
                background-image: linear-gradient(-45deg, rgb(0 0 153 / .8) 0, rgb(0 164 204 / .8) 50%, rgb(124 211 255 / .8) 100%);

                @include g.mq('lg') {
                    margin-right: 0;
                    margin-left: auto;
                }

                @include g.mq('xl') {
                    width: calc(1040px + g.get-inner-side());
                    padding: 82px calc(g.get-inner-side() + 40px + 7px) 82px 358px;
                }
            }

            &.is_counterStore {
                background-image: linear-gradient(45deg, rgb(0 155 255 / .8) 0%, rgb(0 199 58 / .8) 100%);

                @include g.mq('xl') {
                    width: calc(1123px + g.get-inner-side());
                    padding: 87px 24px 94px calc(g.get-inner-side() + 40px + 3px);
                }
            }

            .ly_inner {
                @include g.mq('lg') {
                    width: 100%;
                }
            }
        }
    }

    // グラデ背景内ポイント
    .un_point {
        color: #fff;
        display: grid;
        gap: 20px;

        @include g.mq('md') {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }

        &_point {
            letter-spacing: 0;
            font-weight: 500;
            font-size: 1.6rem;

            @include g.mq('md') {
                font-size: 2rem;
            }
        }

        &_wrap {
            padding-left: 18px;
            border-left: 4px solid rgb(255 255 255 / .3);
        }

        &_tit {
            line-height: math.div(34, 20);
            font-weight: 500;
            font-size: 1.6rem;
            margin-bottom: 6px;

            @include g.mq('md') {
                font-size: 2rem;
            }
        }

        &_txt {
            line-height: math.div(28, 16);
        }
    }

    // グラデ背景内コンテンツ
    .un_bgCont {
        color: #fff;

        @include g.mq('lg') {
            width: min(500px, 59%);
            padding-left: 18px;
            border-left: 4px solid rgb(255 255 255 / .3);
        }

        @include g.mq('xl') {
            width: 100%;
        }

        &.is_salesSite {
            @include g.mq('md') {
                margin-left: auto;
            }
        }

        &.is_counterStore {
            @include g.mq('md') {
                max-width: 490px;
            }

            @include g.mq('lg') {
                padding-left: 37px;
            }
        }

        &_tit {
            letter-spacing: 0.06em;
            line-height: math.div(34, 20);
            font-weight: 500;
            font-size: 1.7rem;
            margin-bottom: 22px;

            @include g.mq('md') {
                font-size: 2.1rem;
            }

            &.is_counterStore {
                font-weight: 700;
                font-size: 1.8rem;

                @include g.mq('md') {
                    font-size: 2.4rem;
                }
            }
        }

        &_txt {
            line-height: math.div(28, 16);
        }

        &_list {
            line-height: math.div(37, 20);
            font-size: 1.6rem;
            margin: 10px auto 25px;

            @include g.mq('md') {
                font-size: 2rem;
            }

            >li {
                display: grid;
                grid-template-columns: 1em 1fr;
                gap: 0;
                margin-left: -0.2em;

                &::before {
                    content: "・";
                }
            }
        }

        .el_btn {
            @include g.mq('md') {
                margin-left: -10px;
            }
        }
    }







}