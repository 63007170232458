/*------------------------------------------------------------------------
	フッターナビ（サブ）
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_subFnav {
	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 0 17px;

		@include g.mq('md') {
			gap: 0;
		}
	}

	li {
		font-size: 1.3rem;
		font-weight: 600;
		letter-spacing: 0.02em;
		text-align: center;
		
		@include g.mq('md') {
			line-height: 0.8;
		}
		
		&:not(:first-of-type) {
			@include g.mq('md') {
				padding-left: 17px;
				border-left: 1px solid #fff;
			}
		}
		
		&:not(:last-of-type) {
			@include g.mq('md') {
				padding-right: 17px;
			}
		}
	}
	
	a {
		display: block;
	}
}