/**************************************************************************
    ニュースリリース
**************************************************************************/
@use "../global" as g;
@use "sass:math";

#newsrelease {

// メインビジュアル
.bl_mvSub {
    background-image: url(../img/newsrelease/mv.webp);

    .el_title_icon {
        width: 40px;
        aspect-ratio: 1;

        @include g.mq('md') {
            width: 55px;
        }

        .newsrelease {
            fill: g.color('white');
        }
    }
}

.un_main {
    padding: 50px 0 75px;

    @include g.mq('md') {
        padding: 75px 0 100px;
    }
}

// ニュースリスト
.bl_newsList {
    margin-top: 30px;

    @include g.mq('md') {
        margin-top: 75px;
    }
}

}
