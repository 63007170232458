/**************************************************************************
    サステナビリティ　マテリアリティの特定
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability.materiality {

// セクション大枠
.un_section {
  padding: 70px 0 120px;

  @include g.mq('md') {
    padding: 120px 0 200px;
  }

  // 背景画像
  &.is_bg_A {
    background: url('../img/sustainability/materiality/bg_A.webp') no-repeat border-box center/cover;
  }
  &.is_bg_B {
    background: url('../img/sustainability/materiality/bg_B.webp') no-repeat border-box center/cover;
  }
  &.is_bg_loop {
    background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
    background-size: 100% auto;
  }

  // グラデーション
  &:first-of-type {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto auto 0;
      width: 100%;
      height: 350px;
      background-image: linear-gradient(to bottom, #fff, transparent);
    }

    .ly_inner {
      position: relative;
      z-index: 1;
    }
  }
}

// イントロ文
.un_intro {
  max-width: 1000px;
  margin: 0 auto 70px;

  @include g.mq('md') {
    margin-bottom: 120px;
  }
}

// カードコンテナ
.un_cards {
  @include g.mq('md') {
    display: flex;
    flex-wrap: wrap;
  }

  @include g.mq('lg') {
    justify-content: center;
  }

  > * + * {
    margin-top: 20px;

    @include g.mq('md') {
      margin-top: 0;
    }
  }
}

// カード
.un_card {
  display: grid;
  grid-template-rows: 80px 1fr;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0 0 81 / 10%);

  @include g.mq('md') {
    grid-template-rows: 140px 1fr;
    width: 48%;
    margin-right: 4%;
    margin-bottom: 30px;

    &:nth-child(2n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @include g.mq('lg') {
    grid-template-rows: 150px 1fr;
    width: 30.8%;
    margin-right: 3.8%;
    margin-bottom: 40px;

    &:nth-child(2n) {
      margin-right: 3.8%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    padding: 20px;
    color: g.color('key');
    line-height: 1.48;
    font-weight: 500;
    font-size: 1.8rem;

    @include g.mq('md') {
      padding: 20px 32px;
    }

    @include g.mq('lg') {
      font-size: 2.3rem;
    }
  }

  &_body {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 15px 20px;

    @include g.mq('md') {
      padding: 20px 30px 40px;
    }
  }

  &_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
  }

  &_list {
    list-style: disc;
    padding-left: 20px;
    padding-bottom: 20px;
    line-height: 1.5;

    @include g.mq('md') {
      min-height: 170px;
    }

    > li + li {
      margin-top: 8px;
    }
  }
}

// サステナブル経営委員会開催状況
.un_history {
  &_title {
    padding: 0 0 5px;
    border-bottom: 2px solid g.color('key');
    color: g.color('key');
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    font-size: 2.5rem;

    @include g.mq('md') {
      border-width: 3px;
      font-size: 5rem;
    }
  }

  &_item {
    padding: 15px 0;
    border-bottom: 2px solid #e1e1e1;
    font-size: 1.5rem;
    line-height: 1.5;

    @include g.mq('md') {
      padding: 20px 0;
      font-size: 1.8rem;
    }
  }
}




}