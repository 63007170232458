/**************************************************************************
    サステナビリティ　イニシアティブへの参画・賛同社外認証取得
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability.initiative {

// セクション大枠
.un_section {
  padding: 70px 0 120px;
  background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
  background-size: 100% auto;

  @include g.mq('md') {
    padding: 120px 0 200px;
  }

  // グラデーション
  &:first-of-type {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto auto 0;
      width: 100%;
      height: 350px;
      background-image: linear-gradient(to bottom, #fff, transparent);
    }

    .ly_inner {
      position: relative;
      z-index: 1;
    }
  }
}

// イントロ文
.un_intro {
  max-width: 1000px;
  margin: 0 auto 70px;

  @include g.mq('md') {
    margin-bottom: 120px;
  }
}

// イニシアティブ - コンテナ
.un_initiativeWrapper {
  @include g.mq('md') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 56px 5%;
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('md') {
      margin-top: 0;
    }
  }
}

// イニシアティブ
.un_initiative {
  display: flex;
  flex-direction: column;

  &_title {
    order: 2;
    padding: 0 0 0 15px;
    margin: 10px 0;
    line-height: 1.5;
    color: g.color('key');
    border-left: 3px solid g.color('key');
    font-size: 1.5rem;
    font-weight: 500;

    @include g.mq('md') {
      margin: 20px 0;
      font-size: 1.8rem;
    }
  }

  &_body {
    order: 3;

    > * + * {
      margin-top: 10px;
    }

    a {
      color: g.color('key');
      text-decoration: underline;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &_small {
    font-size: 1.1rem;

    @include g.mq('md') {
      font-size: 1.3rem;
    }
  }

  &_image {
    order: 1;

    img {
      display: block;
      width: 100%;
      aspect-ratio: 475/245;
      object-fit: contain;
    }
  }
}

}