/*------------------------------------------------------------------------
    背景ありのコンテンツ
------------------------------------------------------------------------*/
@use "../../global" as g;

.ly_contentsBg {
    $this: &;
    position: relative;
    background: url('../img/common/bg_02.webp') repeat border-box top center/100% auto;

    
    // ページ全体で使用する場合
    &.is_forDocument {
        background-size: auto 1000px;
        
        @include g.mq('md') {
            background-size: 100% auto;
        }
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 156px;
            background: linear-gradient(to bottom, #fff 0%, transparent 100%);
        }
    
        #{$this}_inner {
            position: relative;
            z-index: 1;
        }
    }

}