/*------------------------------------------------------------------------
	ポップアップ
------------------------------------------------------------------------*/
@use "../../global" as g;

.js_popup {
    &_body {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        position: fixed;
        inset: 0;
        z-index: map-get(g.$z_index, 'popup');
    
        &.is_active {
            visibility: visible;
            opacity: 1;
        }
    }
    
    &_overlay {
        position: absolute;
        inset: 0;
        z-index: 1;
        height: 100%;
        background-color: rgba(#000, 0.9);
    }
    
    &_close {
        $_size: 30px;
        @include g.close-icon($_size, 2px, #fff);
        width: $_size;
        height: $_size;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
    }
    
    &_display {
        position: relative;
        width: 100%;
        height: 100%;

        &:not(.is_scroll) {
            display: grid;
            place-content: center;
        }

        &.is_scroll {
            overflow-y: scroll;
        }
    }
    
    &_contents {
        position: relative;
        z-index: 1;
        padding: 40px 0;
        max-width: g.$inner_width_sp;
        
        @include g.mq('lg') {
            max-width: 1001px;
        }
    }
}
