/*------------------------------------------------------------------------
	絞り込み
------------------------------------------------------------------------*/
@use "../../global" as g;

.js_filter_target {
    display: none;

    &.is_show {
        display: block;
    }
}