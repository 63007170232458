/**************************************************************************
    経営理念
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.philosophy {
	// メインビジュアル
	.bl_mvSub {
		background-image: url(../img/company/philosophy/mv.webp);

		.el_title_icon {
			width: 45px;

			@include g.mq('md') {
				width: 56px;
			}

			.philosophy {
				stroke: g.color('white');
			}
		}
	}

	// ページ内リンク
	.bl_anchorLink {
		margin-top: 40px;

		@include g.mq('md') {
			margin-top: 70px;
		}

		&_item:nth-of-type(4) .el_btn {
			@include g.mq('xl') {
				padding-left: 20px;
	
				span {
					letter-spacing: 0;
				}
			}
		}
	}

	// タイトル
	.un_title {
		color: g.color('key');

		h2 {
			font-size: 3.5rem;
			font-weight: bold;
			line-height: 1.7;
			letter-spacing: 0;
			text-align: center;

			@include g.mq('md') {
				font-size: 6rem;
			}
		}

		p {
			position: relative;
			padding-top: 5px;
			font-weight: 500;
			text-align: center;

			&::before {
				content: '';
				position: absolute;
				inset: 0 auto auto 50%;
				translate: -50%;
				width: 50px;
				height: 1px;
				background-color: g.color('key');
			}
		}
	}

	// 経営理念
	.un_philosophy {
		padding: 70px 0 150px;
		margin-top: 40px;
		background: url('../img/company/philosophy/philosophy_bg.webp') no-repeat border-box center/cover;

		@include g.mq('md') {
			padding: 130px 0 286px;
			margin-top: 65px;
		}
	}

	.un_catchCopy {
		margin-top: 30px;

		@include g.mq('md') {
			margin-top: 65px;
		}

		@include g.mq('lg') {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		&_titleArea {
			padding: 50px 20px;
			background-image: linear-gradient(135deg, rgba(#003dc7, 0.4), rgba(#00b5ce, 0.4));

			@include g.mq('lg') {
				display: grid;
				place-content: center;
			}
		}

		&_title {
			h3 {
				text-align: center;
			}

			img {
				@include g.mq('sm') {
					width: 250px;
				}
			}

			p {
				margin-top: 20px;
				line-height: 1;
				font-size: 2rem;
				text-align: center;
				color: g.color('white');

				@include g.mq('md') {
					font-size: 2.6rem;
				}
			}
		}

		&_textArea {
			background-color: g.color('white');
			padding: 35px;
			
			@include g.mq('lg') {
				display: grid;
				place-content: center;
				padding: 75px;
			}
		}

		&_text {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: math.div(39.685, 18);
			color: g.color('key');

			@include g.mq('md') {
				font-size: 1.8rem;
			}
		}
	}

	.un_philosophyList {
		position: relative;
		margin-top: 40px;

		@include g.mq('md') {
			margin-top: 120px;
		}

		@include g.mq('lg') {
			gap: 70px;
		}

		&_catch {
			font-size: 2.5rem;
			font-weight: bold;
			line-height: math.div(48.189, 31);
			text-align: center;
			color: g.color('white');

			@include g.mq('md') {
				position: absolute;
				inset: 52% auto auto 50%;
				translate: -50% -50%;
				font-size: 3rem;
			}
			
			@include g.mq('lg') {
				top: 54%;
			}
			
			@include g.mq('xl') {
				top: 56.5%;
				font-size: 3.5rem;
			}

			.vertical {
				writing-mode: vertical-rl;
			}
		}

		&_contents {
			margin-top: 30px;

			@include g.mq('lg') {
				margin-top: 0;
			}
		}

		&_row {
			@include g.mq('md') {
				display: flex;
				justify-content: center;
				gap: 35px;
			}
			
			@include g.mq('xl') {
				gap: 180px;
			}
			
			&:not(:first-of-type) {
				margin-top: 30px;
				
				@include g.mq('md') {
					margin-top: 90px;
				}
				
				@include g.mq('lg') {
					margin-top: 70px;
				}
			}
		}
	}

	.un_philosophyItem {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 1em;
		height: 1em;
		font-size: 320px;
		margin-inline: auto;
		background-color: g.color('white');
		border-radius: 50%;
		box-shadow: 0 0 6px rgba(#151c49, 0.15);

		@include g.mq('lg') {
			font-size: 420px;
		}

		&:not(:first-of-type) {
			margin-top: 30px;
			
			@include g.mq('md') {
				margin-top: 0;
			}
		}

		img {
			@include g.mq('lg_max') {
				width: 65px;
				height: 65px;
				object-fit: contain;
			}
		}

		dt {
			margin-top: 10px;
			font-size: 2rem;
			font-weight: bold;
			line-height: math.div(34.0158, 25);
			text-align: center;

			@include g.mq('md') {
				display: flex;
				align-items: center;
			}
			
			@include g.mq('lg') {
				margin-top: 20px;
				font-size: 2.5rem;
			}
		}

		dd {
			padding: 0 60px;
			margin-top: 10px;
			font-size: 1.2rem;
			line-height: math.div(22.6772, 14);
			
			@include g.mq('md') {
				font-size: 1.4rem;
			}
			
			@include g.mq('lg') {
				margin-top: 15px;
			}
		}

		&.is_01 {
			dt {
				color: g.color('key');
			}
		}

		&.is_02 {
			dt {
				color: #00ab80;
			}
		}

		&.is_03 {
			dt {
				color: #ba3840;
			}
		}
	}

	// 経営ビジョン
	.un_vision {
		padding: 70px 0 100px;
		background: url('../img/company/philosophy/vision_bg.webp') no-repeat border-box center/cover;

		@include g.mq('md') {
			padding: 130px 0 160px;
		}

		&_catch {
			margin-top: 20px;
			font-size: 2rem;
			font-weight: 500;
			line-height: math.div(52.8291, 25.2816);
			text-align: center;

			@include g.mq('md') {
				margin-top: 40px;
				font-size: 2.5rem;
			}
		}

		&_text {
			margin-top: 10px;
			font-size: 1.6rem;
			line-height: math.div(39.685, 18);
			text-align: center;
			
			@include g.mq('md') {
				margin-top: 15px;
				font-size: 1.8rem;
			}
		}
	}

	.un_visionList {
		display: grid;
		grid-template-columns: 1fr;
		gap: 60px;
		margin-top: 60px;
		
		@include g.mq('md') {
			margin-top: 95px;
		}
		
		@include g.mq('lg') {
			grid-template-columns: repeat(3, 1fr);
			justify-items: center;
			gap: 90px 30px;
		}
	}

	.un_visionItem {
		position: relative;
		max-width: 376px;
		padding: 50px 25px 25px;
		margin-inline: auto;
		background-color: g.color('white');
		
		@include g.mq('md') {
			padding: 65px 40px 35px;
		}

		&::before {
			content: '';
			position: absolute;
			inset: 15px 0 0 15px;
			width: 1em;
			height: 1em;
			font-size: 30px;
			background: url('../img/company/philosophy/vision_triangle.svg') no-repeat border-box center/cover;
			
			@include g.mq('md') {
				inset: 20px 0 0 20px;
			}
		}
		
		&_icon {
			position: absolute;
			inset: 0 auto auto 50%;
			translate: -50% -50%;
			display: grid;
			place-content: center;
			width: 1em;
			height: 1em;
			font-size: 75px;
			background-color: g.color('white');
			border-radius: 50%;;
			box-shadow: 0 0 5px rgba(#000, 0.2);

			@include g.mq('md') {
				font-size: 101px;
			}

			img {
				@include g.mq('sm') {
					width: 35px;
				}
			}
		}

		&_text {
			font-size: 1.5rem;
			line-height: math.div(35.8253, 17);
			letter-spacing: 0.06em;

			@include g.mq('md') {
				font-size: 1.7rem;
			}
		}
	}

	// ページ下部
	.un_bottom {
		padding: 80px 0;

		@include g.mq('md') {
			padding: 144px 0;
		}
	}

	// 行動指針
	.un_guiding {
		padding: 10px;
		background-color: g.color('white');

		@include g.mq('sm') {
			width: 100vw;
			margin-inline: calc(50% - 50vw);
		}

		@include g.mq('md') {
			padding: 18px;
		}

		@include g.mq('lg') {
			display: flex;
			gap: 20px;
		}

		&_textArea {
			padding: 10px;

			@include g.mq('md') {
				padding: 15px 40px 25px;
			}

			@include g.mq('lg') {
				flex: 1;
			}
		}

		&_title {
			font-size: 3rem;
			font-weight: bold;

			@include g.mq('md') {
				font-size: 3.5rem;
			}
		}

		&_text {
			font-size: 1.6rem;
			line-height: math.div(39.685, 18);
			
			@include g.mq('md') {
				margin-top: 10px;
				font-size: 1.8rem;
			}
		}

		&_image {
			margin-top: 10px;
			text-align: center;

			@include g.mq('lg') {
				width: percentage(math.div(445, 1200));
				margin-top: 0;
			}

			@include g.mq('xl') {
				width: 445px;
			}
		}
	}

	// 当社ロゴマークに込めた思い
	.un_symbol {
		max-width: g.$inner-width-small;
		margin-top: 100px;
		margin-inline: auto;

		@include g.mq('md') {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			gap: 60px;
			margin-top: 210px;
		}

		@include g.mq('lg') {
			gap: 85px;
		}
		
		@include g.mq('xl') {
			gap: 140px;
		}

		&_textArea {
			@include g.mq('md') {
				flex: 1;
			}
		}

		.el_subtitle_en {
			font-size: 3.5rem;

			@include g.mq('md') {
				font-size: 6rem;
			}
		}

		&_text {
			margin-top: 20px;
			font-size: 1.6rem;
			font-weight: 500;
			line-height: math.div(46.4749, 22.2407);

			@include g.mq('md') {
				margin-top: 30px;
				font-size: 2.2rem;
			}

			&.is_first {
				@include g.mq('md') {
					margin-top: 65px;
				}
			}
		}

		&_logo {
			margin-top: 20px;
			width: 65%;
			margin-inline: auto;

			@include g.mq('md') {
				width: min(percentage(math.div(372, 1000)), 372px);
				margin-top: 0;
			}
		}
	}
}
