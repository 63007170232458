/*------------------------------------------------------------------------
	フッター
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.ly_footer {
	color: #fff;
	background: url('../img/common/footer_bg_sp.webp') no-repeat border-box center/cover;
	
	@include g.mq('md') {
		background-image: url('../img/common/footer_bg_pc.webp');
	}

	&_top {
		padding-top: 100px;

		@include g.mq('lg') {
			display: flex;
			justify-content: space-between;
			gap: 70px;
			padding: 76px 0;
		}

		&.is_en {
			@include g.mq('lg_max') {
				padding: 100px 0 85px;
			}

			@include g.mq('lg') {
				display: block;
			}

			@include g.mq('xl') {
				display: flex;
			}
		}
	}

	&_col {
		@include g.mq('lg') {
			display: flex;
			flex-direction: column;
			gap: 120px;
			width: 32.5%;
		}

		@include g.mq('xl') {
			justify-content: space-between;
			width: 44.5%;
		}
	}

	.el_logo {
		@include g.mq('lg_max') {
			text-align: center;
		}
	}

	&_bottom {
		padding-bottom: 55px;
		margin-top: 30px;

		@include g.mq('md') {
			margin-top: 50px;
		}

		@include g.mq('lg') {
			padding: 20px 0 30px;
			margin-top: 0;
			border-top: 1px solid #fff;
		}

		&_inner {
			@include g.mq('xl') {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
}