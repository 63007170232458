/*------------------------------------------------------------------------
	グラデタイトル
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_gradTitle {
	$grad: linear-gradient(45deg, #4ab0ff 0%, #000099 100%);
	text-align: center;

	&_en {
		display: inline-block;
		font-size: 1.6rem;
		font-weight: bold;
		letter-spacing: 0;
		background-image: $grad;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		
		@include g.mq('md') {
			font-size: 2rem;
		}
	}

	&_ja {
		font-size: 3rem;
		font-weight: bold;
		line-height: 1.2;
		letter-spacing: 0.06em;
		
		@include g.mq('md') {
			font-size: 4.2rem;
		}
	}


	&.is_border {
		position: relative;
		padding-bottom: 30px;
		
		@include g.mq('md') {
			padding-bottom: 45px;
		}

		&::before {
			content: '';
			position: absolute;
			inset: auto auto 0 50%;
			translate: -50%;
			width: 113px;
			height: 4px;
			background-image: $grad;
		}
	}
}

