/**************************************************************************
    お問い合わせ・よくあるご質問
**************************************************************************/
@use "sass:math";
@use "../global" as g;

#demand {

/* メインビジュアル
  ------------------------------------------------------------------------ */
.bl_mvSub {
  background-image: url(../img/demand/mv.webp);
  height: 300px;

  @include g.mq('md') {
    height: 436px;
  }

  .el_title_icon {
    width: 40px;

    @include g.mq('md') {
      width: 54px;
    }

    .demand {
      fill: g.color('white');
    }
  }
}

/* 頭出し
  ------------------------------------------------------------------------ */
.un_indent {
  display: flex;
}

/* 行間サイズ
  ------------------------------------------------------------------------ */
.un_lineHeight_small {
  line-height: 1.5;
}

/* 文字サイズ
  ------------------------------------------------------------------------ */
.un_font_large {
  font-size: 1.5rem;

  @include g.mq('md') {
    font-size: 1.8rem;
  }
}

.un_font_small {
  font-size: 1.1rem;

  @include g.mq('md') {
    font-size: 1.4rem;
  }
}

/* 縦積みレイアウト
  ------------------------------------------------------------------------ */
.un_stack {
  > * + * {
    margin-top: 30px;

    @include g.mq('md') {
      margin-top: 40px;
    }
  }

  &.is_large {
    > * + * {
      margin-top: 50px;

      @include g.mq('md') {
        margin-top: 80px;
      }
    }
  }

  &.is_small {
    > * + * {
      margin-top: 10px;

      @include g.mq('md') {
        margin-top: 15px;
      }
    }
  }
}

/* カラムレイアウト
  ------------------------------------------------------------------------ */
.un_cols {
  @include g.mq('md') {
    display: flex;
  }

  > * + * {
    margin-top: 20px;

    @include g.mq('md') {
      margin-top: 0;
    }
  }
}
.un_col {
  display: block;

  @include g.mq('md') {
    &.is_1 { width: calc(100% / 12); }
    &.is_2 { width: calc(100% / 6); }
    &.is_3 { width: calc(100% / 4); }
    &.is_4 { width: calc(100% / 3); }
    &.is_5 { width: calc(100% / 12 * 5); }
    &.is_6 { width: calc(100% / 2); }
    &.is_7 { width: calc(100% / 12 * 7); }
    &.is_8 { width: calc(100% / 12 * 8); }
    &.is_9 { width: calc(100% / 12 * 9); }
    &.is_10 { width: calc(100% / 12 * 10); }
    &.is_11 { width: calc(100% / 12 * 11); }
  }

  &.is_center {
    display: flex;
    align-items: center;
  }

  &.is_bottom {
    display: flex;
    align-items: flex-end;
  }

  &.is_right {
    display: flex;
    justify-content: flex-end;
  }
}

/* ページ内ナビゲーション
  ------------------------------------------------------------------------ */
.un_nav {
  padding: 20px 0 40px;

  @include g.mq('md') {
    padding: 30px 0 75px;
  }

  &_text {
    background: linear-gradient(135deg,#003dc7,#00b5ce);
    color: #fff;
    line-height: 1.8;
    font-weight: 500;
    border-radius: 6px 6px 0 0;

    @include g.mq('md') {
      border-radius: 8px 8px 0 0;
    }

    &_inner {
      width: fit-content;
      padding: 15px;
      margin-inline: auto;
      font-size: 1.5rem;

      @include g.mq('md') {
        padding: 20px;
        text-align: center;
        font-size: 1.8rem;
      }
    }
  }

  &_body {
    padding: 12px;
    background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
    border-radius: 0 0 6px 6px;
    gap: 10px;

    @include g.mq('md') {
      padding: 33px 44px;
      border-radius: 0 0 8px 8px;
    }
  }

  &_btnList {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);

    @include g.mq('md') {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      overflow: visible;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }

    &:not(:first-of-type) {
      @include g.mq('md') {
        margin-top: 15px;
      }
    }

    > * + * {
      border-top: 1px solid #e1e1e1;

      @include g.mq('md') {
        border-top: none;
      }
    }
  }

  &_btn {
    position: relative;
    display: block;
    padding: 12px 35px 12px 15px;
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 1.5;

    @include g.mq('md') {
      display: inline-block;
      padding: 17px 50px 17px 30px;
      border-radius: 999px;
      box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);
      font-size: 1.7rem;
    }

    &_small {
      font-size: 1.2rem;

      @include g.mq('md') {
        font-size: 1.5rem;
      }
    }

    &_icon {
      position: absolute;
      top: 50%;
      right: 5px;
      width: 32px;
      aspect-ratio: 1/1;
      rotate: 90deg;
      translate: 0 -50%;

      @include g.mq('md') {
        right: 15px;
      }

      .triArrow {
        fill: none;
        stroke: #00ab80;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
      }
    }
  }
}

/* セクション大枠
  ------------------------------------------------------------------------ */
.un_section {
  position: relative;
  padding: 40px 0 60px;

  @include g.mq('md') {
    padding: 60px 0 80px;
  }

  @include g.mq('lg') {
    padding: 90px 0 126px;
  }

  &.is_bg_blue {
    background-color: #f5f7fb;
  }

  &_inner {
    z-index: 2;
    position: relative;
    width: g.$inner_width_sp;
    max-width: g.$inner_width_medium;
    margin-inline: auto;

    > * + * {
      margin-top: 20px;

      @include g.mq('md') {
        margin-top: 40px;
      }
    }
  }

  &_title {
    width: fit-content;
    margin-inline: auto;
    line-height: 1.5;
    font-weight: bold;
    font-size: 3rem;

    @include g.mq('md') {
      font-size: 4.2rem;
    }

    &.is_bordered {
      width: 100%;
      padding-bottom: 10px;
      text-align: center;
      border-bottom: 1px solid g.color('key');

      @include g.mq('md') {
        padding-bottom: 20px;
      }
    }
  }

  &_bg {
    z-index: 1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* サブセクション
  ------------------------------------------------------------------------ */
.un_subsection {
  > * + * {
    margin-top: 10px;
  }

  &_title {
    padding: 0 0 5px;
    color: g.color('key');
    line-height: 1.5;
    border-bottom: 1px solid g.color('key');
    font-weight: 500;
    font-size: 2rem;

    @include g.mq('md') {
      padding-bottom: 10px;
      font-size: 2.7rem;
    }
  }
}

/* ご相談例
  ------------------------------------------------------------------------ */
.un_example {
  @include g.mq('md') {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 18px;
  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 5px;
    border: 1px solid g.color('key');
    color: g.color('key');

    @include g.mq('md') {
      margin: 0;
    }
  }

  &_text {
    line-height: 1.8;
    font-size: 1.2rem;

    @include g.mq('md') {
      font-size: 1.4rem;
    }
  }
}

/* アイコン付きリンク
  ------------------------------------------------------------------------ */
.un_link {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: g.color('key');
  text-decoration: underline;
}

/* アイコン付きボタン
  ------------------------------------------------------------------------ */
.un_btns {
  @include g.mq('md') {
    display: flex;
    gap: 23px;
  }

  > * + * {
    margin-top: 10px;

    @include g.mq('md') {
      margin-top: 0;
    }
  }
}
.un_btn {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 14px 18px 14px 23px;
  border-radius: 9999px;
  background-color: g.color('key');
  color: #fff;
  line-height: 1.5;

  @include g.mq('md') {
    padding: 19px 23px 19px 30px;
  }

  &.is_block {
    display: flex;
    justify-content: space-between;
  }

  &_icon{
    width: 24px;
    aspect-ratio: 1/1;

    @include g.mq('md') {
      width: 32px;
    }

    &_circle {
      stroke-width: 0px;
      fill: #fff;
    }

    &_arrow {
      fill: none;
      stroke-miterlimit: 10;
      stroke: g.color('key');
    }
  }
}

/* お問い合わせセクション
  ------------------------------------------------------------------------ */
.un_contact {
  background: #fff url('../img/demand/mark.svg') no-repeat;
  background-position: 5px 5px;
  background-size: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);
  overflow: hidden;

  @include g.mq('md') {
    background-position: 10px 10px;
    background-size: 18px;
    border-radius: 7px;
  }

  &_header {
    padding: 15px 15px 20px;
    line-height: 1.68;
    border-bottom: 1px solid #e1e1e1;

    @include g.mq('md') {
      padding: 30px 45px 35px;
    }

    p {
      letter-spacing: 0;
    }

    > * + * {
      margin-top: 10px;
    }
  }

  &_title {
    line-height: 1.5;
    font-size: 2rem;
    font-weight: 500;
    color: g.color('key');

    @include g.mq('md') {
      font-size: 2.7rem;
    }
  }

  &_subtitle {
    margin: 0 0 10px;
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: 500;
    color: #00ab80;

    @include g.mq('md') {
      font-size: 1.8rem;
    }
  }

  &_body {
    padding: 15px 15px 20px;

    @include g.mq('md') {
      padding: 30px 50px 35px;
    }
  }

  &_divide {
    > * + * {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e1e1e1;

      @include g.mq('md') {
        margin-top: 30px;
        padding-top: 30px;
      }
    }
  }

  &_info {
    line-height: 1.5;

    @include g.mq('lg') {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0 30px;

      > * {
        display: flex;
        align-items: center;
      }
    }

    &_tel {
      color: g.color('key');
      font-size: 1.8rem;

      @include g.mq('md') {
        font-size: 2.3rem;
      }
    }
  }

  &_footer {
    padding: 15px 15px 20px;
    background-color: rgba(g.color('key'), 0.07);
    font-size: 1.2rem;

    @include g.mq('md') {
      padding: 30px 50px 35px;
      font-size: 1.4rem;
    }
  }
}

/* カード（よくある質問へのリンク）
  ------------------------------------------------------------------------ */
.un_cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px 3%;

  @include g.mq('md') {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 3%;
  }

  @include g.mq('lg') {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px 2%;
  }
}

.un_card {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);

  &_label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    height: 140px;

    &_icon {
      display: block;
      width: 62px;
      aspect-ratio: 1/1;
      margin-inline: auto;
    }

    &_text {
      display: block;
      padding: 0 15px;
      margin-inline: auto;
      text-align: center;
      color: g.color('key');
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.2;

      @include g.mq('md') {
        font-size: 2.2rem;
      }

      > span {
        font-size: 1.3rem;

        @include g.mq('md') {
          font-size: 1.6rem;
        }
      }
    }
  }

  &_body {
    flex-grow: 1;
    display: block;
    padding: 10px 15px;
    background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
    line-height: 1.6;
    text-align: justify;

    > span {
      font-size: 1.1rem;

      @include g.mq('md') {
        font-size: 1.4rem;
      }
    }

    @include g.mq('md') {
      padding: 24px 20px;
    }
  }
}

}
