/*------------------------------------------------------------------------
	下層メインビジュアル
    ※画像は各ページの個別cssで指定します
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_mvSub {
    $this: &;
    position: relative;
    display: grid;
    align-items: center;
    height: 700px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    @include g.mq('md') {
        height: 933px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
        background: url('../img/common/mv_curve.svg') no-repeat border-box center top/auto 100%;

        @include g.mq('md') {
            height: 81px;
            background-size: cover;
        }
    }

    &_textArea {
        max-width: 870px;
        padding-top: map-get(g.$header_h, 'sm') + 10px;
        margin-bottom: 75px;
        color: g.color('white');
    }

    &_text {
        margin-top: 10px;
        font-size: 1.4rem;
        line-height: math.div(42.5197, 18);
        letter-spacing: 0.06em;
        text-align: center;

        @include g.mq('md') {
            margin-top: 40px;
            font-size: 1.8rem;
        }
    }

    &_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 45px;
        padding: 5px 45px;
        margin-top: 20px;
        margin-inline: auto;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.08em;
        border: 1px solid g.color('white');
        border-radius: 100vmax;
        
        @include g.mq('md') {
            height: 55px;
            margin-top: 40px;
            font-size: 1.7rem;
        }
    }

    &_note {
        position: absolute;
        inset: auto g.get-inner-side(g.$inner-width-sp) -20px auto;
        font-size: 1.2rem;
        
        @include g.mq('md') {
            bottom: -5px;
            font-size: 1.4rem;
        }

        @include g.mq('xl') {
            right: g.get-inner-side();
        }
    }

    // 小さい
    &.is_small, &.is_xs {
        &::before {
            content: '';
            bottom: -1px;
            background-image: url('../img/common/mv_wave.svg');
            
            @include g.mq('md') {
                height: 69px;
            }
        }

        .el_title {
            .el_title_icon {
                margin: 0 0 10px 0;
            }
    
            .el_title_en {
                font-size: 2.5rem;
    
                @include g.mq('md') {
                    font-size: 4.2rem;
                }
            }
        }


        #{$this}_text {
            @include g.mq('md') {
                font-size: 1.6rem;
            }
        }
    }

    &.is_small {
        height: 450px;

        @include g.mq('md') {
            height: 588px;
        }
    }
    
    &.is_xs {
        height: 300px;
    
        @include g.mq('md') {
            height: 436px;
        }
    }

    &.is_pc_aspect {
        @include g.mq('lg') {
            height: auto;
            aspect-ratio: 1920/871;
        }
    }
}
