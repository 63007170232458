/**************************************************************************
    決算情報
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.accounts {
	// メインビジュアル
	.bl_mvSub {
		background-image: url(../img/company/accounts/mv.webp);
	}

	.un_main {
		padding: 50px 0 250px;

		@include g.mq('md') {
			padding: 75px 0 350px;
		}
	}

	// ニュースリスト
	.bl_newsList {
		margin-top: 30px;

		@include g.mq('md') {
			margin-top: 75px;
		}
	}

	// ページネーション
	.bl_pagenation {
		margin-top: 30px;

		@include g.mq('md') {
			margin-top: 50px;
		}
	}
}
