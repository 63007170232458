/*------------------------------------------------------------------------
    カテゴリ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_category {
    display: inline-flex;
    align-items: center;
    height: 27px;
    padding: 0 20px;
    font-size: 1.2rem;
    letter-spacing: 0;
    color: g.color('white');
    background-color: g.color('base_light');
    border-radius: 100vmax;

    @include g.mq('md') {
        font-size: 1.4rem;
    }
    
    &.is_color {
        background-color: g.color('accent_2');
    }
    
    &.is_primary {
        background-color: g.color('accent');
    }

    &.is_news {
        background-color: g.color('key');
    }
}