/*------------------------------------------------------------------------
	スライダーページネーション
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_slider_pagination {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	gap: 10px;

	.swiper-pagination-bullet {
		$_size: 12px;
		width: $_size;
		height: $_size;
		margin: 0!important;
		opacity: 1;
		background-color: #A2A2A2;

		&-active {
			background-color: g.color('key');
		}
	}
}