/*------------------------------------------------------------------------
	下層ナビ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_subNav {
    &_list {
        display: grid;
        gap: 15px 8px;
        
        &.is_col3 {
            grid-template-columns: 1fr;

            @include g.mq('lg') {
                grid-template-columns: repeat(3, 1fr);
                gap: 30px;
            }
        }
        
        &.is_col4 {
            grid-template-columns: repeat(2, 1fr);

            @include g.mq('lg') {
                grid-template-columns: repeat(4, 1fr);
                gap: 15px;
            }
        }
    }

    &_item {
        @include g.bg_flow(g.color('white'), '.el_circleArrow::after', false);
        border-radius: 5px;
        overflow: hidden;
        
        @include g.mq('md') {
            border-radius: 10px;
        }

        
        a {
            display: flex;
            flex-direction: column;
            height: 100%;

            &:hover {
                opacity: 1;

                .el_circleArrow.is_forAnimation {
                    &::before {
                        background-image: url('../img/common/icon_arrow_key.svg');
                    }
                }
            }
        }
    }

    &_img {
        img {
            width: 100%;
        }
    }

    &_title {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        margin-top: -7px;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: math.div(26, 19);
        color: g.color('key');
        background-color: g.color('white');

        @include g.mq('sm') {
            flex: 1;
        }
        
        @include g.mq('md') {
            height: 73px;
            padding: 0 27px;
            font-size: 1.6rem;
        }

        @include g.mq('xl') {
            font-size: 1.9rem;
        }

        .el_circleArrow.is_forAnimation {
            position: absolute;
            top: 0;
            right: 10px;
            translate: 0 -50%;
            
            @include g.mq('md') {
                right: 20px;
            }

            &::before {
                z-index: 2;
            }
        }
    }
}