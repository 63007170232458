/*------------------------------------------------------------------------
	年一覧
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_years {
    position: relative;
    padding: 0 8px;

    @include g.mq('md') {
        padding: 0 35px;
    }

    &_item {
        padding: 0 5px;
        width: calc(100% / 5);
        font-size: 1.2rem;
        color: g.color('key');
        border-right: 1px solid g.color('key');
        
        @include g.mq('md') {
            padding: 0 15px;
            font-size: 1.6rem;
        }
        
        %active {
            color: g.color('white');
            background-color: g.color('key');
        }
        
        a {
            display: block;
            padding: 5px;
            line-height: 1.4;
            letter-spacing: 0;
            text-align: center;
            
            @include g.mq('md') {
                padding: 10px 25px;
            }

            &:hover {
                @extend %active;
                opacity: 1;
            }
        }
        
        &.is_active {
            a {
                @extend %active;
                pointer-events: none;
            }
        }

        &:first-of-type {
            border-left: 1px solid g.color('key');
        }
    }

    &_arrow {
        display: none;
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        font-size: 1.6rem;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        &.is_show {
            display: block;
        }
        
        &.is_prev {
            left: -15px;
            
            @include g.mq('md') {
                left: 0;
            }
        }
        
        &.is_next {
            right: -15px;
            
            @include g.mq('md') {
                right: 0;
            }
        }
    }
}