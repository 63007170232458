/**************************************************************************
    国内拠点・支店
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#branch.domestic {

// リードテキスト
.un_lead {
    margin-top: 50px;
    
    @include g.mq('md') {
        margin-top: 75px;
    }
    
    &_text {
        padding: 50px 20px;
        border-radius: 10px;
        box-shadow:  0 0 11px rgba(#000051, 0.1);
        font-size: 1.6rem;
        
        @include g.mq('md') {
            padding: 77px 20px 108px;
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .un_pageNav {
        margin-top: 50px;
        
        @include g.mq('md') {
            margin-top: 75px;
        }
    }
    
    .un_pageTitle {
        margin-top: 50px;
        
        @include g.mq('md') {
            margin-top: 75px;
        }
    }
}

// 地域リスト
.un_areaList {
    $this: '.un_areaList';
    padding: 15px 0;
    background-color: g.color('white');

    &.is_fixed {
        position: fixed;
        top: map-get(g.$header_h, 'sm');
        left: 0;
        z-index: 1;
        width: 100%;
        margin-top: 0;
        box-shadow: 0 0 6px rgba(#000, 0.2);
        
        @include g.mq('md') {
            top: map-get(g.$header_h, 'md');
        }

        @include g.mq('lg') {
            top: map-get(g.$header_h, 'lg');
        }

        #{$this}_list {
            max-width: g.$inner_width_sp;

            @include g.mq('xl') {
                max-width: g.$inner_width_medium;
            }
        }
    }

    &_wrap {
        margin-top: 15px;

        @include g.mq('md') {
            margin-top: 35px;
        }
    }

    &_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px 0;
        margin-inline: auto;
    }

    &_item {
        padding: 0 5px;
        font-size: 1.2rem;
        border-right: 1px solid g.color('key');
        
        @include g.mq('md') {
            font-size: 1.6rem;
        }
        
        %active {
            color: g.color('white');
            background-color: g.color('key');
        }

        a {
            color: g.color('key');
            display: block;
            padding: 0 10px;
            letter-spacing: 0;
            
            @include g.mq('md') {
                padding: 0 20px;
            }


            &:hover {
                @extend %active;
                opacity: 1;
            }

            &.is_active {
                @extend %active;
                pointer-events: none;
            }
        }

        &:first-of-type {
            border-left: 1px solid g.color('key');
        }
    }
}

// コンテンツ
.un_contents {
    padding: 50px 0;
    margin: 30px 0 50px;

    @include g.mq('md') {
        padding: 75px 0 100px;
        margin: 50px 0 130px;
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-top: 30px;

        @include g.mq('md') {
            gap: 30px;
            margin-top: 50px;
        }
        
        @include g.mq('lg') {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

// コンテンツ中身
.un_contentsItem {
    padding: 20px 20px 30px;
    background-color: g.color('white');
    box-shadow:  0 0 11px rgba(#000051, 0.1);

    @include g.mq('md') {
        padding: 35px 30px 45px;
    }

    .branchData {
        margin-top: 10px;
    
        @include g.mq('md') {
            margin-top: 20px;
        }
    }

    &_notice {
        margin-top: 20px;
        font-size: 1.2rem;

        @include g.mq('md') {
            font-size: 1.4rem;
        }
    }

    &_btnList {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-top: 20px;
        
        @include g.mq('md') {
            grid-template-columns: repeat(2, 1fr);
        }

        &_item {
            p {
                font-size: 1.2rem;
            }
        }
    }
}

}
