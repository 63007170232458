// ブレイクポイント
$breakpoints: (
    'xs': 'screen and (max-width: 375px)',
    'sm': 'screen and (max-width: 767px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 992px)',
    'lg_max': 'screen and (max-width: 991px)',
    'xl': 'screen and (min-width: 1320px)',
    'xl_max': 'screen and (max-width: 1319px)',
)!default;

// inner-width
$inner_width: 1280px;
$inner_width_medium: 1200px;
$inner_width_small: 1000px;
$inner_width_sp: 85.3333%;

// フォント
// 出力例) font('base');
$font: (
    'base': ("Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif),
    'en': ("Montserrat", sans-serif),
    'en_2': ("Lato", sans-serif),
);

// 色管理
// 出力例) color('base');
// 多次元mapの出力例) color('cat', 'event', 'performance');
$color: (
	'base': #333,
	'base_dark': #000,
    'base_light': #808080,
    'white': #fff,
    'key': #003b9a,
    'accent': #ce3840,
    'accent_2': #00bf80,
    'bg': #f1eef1,
    'border': #eae6ea,
    'border_dark': #a2a2a2,
);

// 重ね順管理
$z_index: (
    'popup': 1001,
    'header': 1000,
    'gotop': 999,
    'banner': 998,
);

//ヘッダーの高さ
$header_h: (
    'sm': 62px,
    'md': 90px,
    'lg': 130px,
);