/**************************************************************************
    事業紹介　詳細
**************************************************************************/
@use "../../global" as g;
@use "sass:math";
@use 'sass:color';

#business.detail {

// リンク
%link {
    color: g.color('key');
    text-decoration: underline;
}

// メインビジュアル
.bl_mvSub {
    $pages: ('corporation', 'education', 'government', 'sports', 'tobu-line', 'global');

    @each $page in $pages {
        &.is_#{$page} {
            background-image: url(../img/business/#{$page}/mv_#{$page}.webp);
        }
    }
}

// セクション大枠
.un_section {
    padding: 70px 0 120px;
    
    @include g.mq('md') {
        padding: 120px 0 200px;
    }

    // 背景画像
    $bg_list: ('A', 'B', 'C', 'D', 'E', 'F');
    @each $val in $bg_list {
        &.is_bg_#{$val} {
            background: url('../img/business/detail/bg_#{$val}.webp') no-repeat border-box center/cover;
        }
    }

    // グラデーション
    &:first-of-type {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            inset: 0 auto auto 0;
            width: 100%;
            height: 350px;
            background-image: linear-gradient(to bottom, #fff, transparent);
        }

        .ly_inner {
            position: relative;
            z-index: 1;
        }
    }
}

// リードテキスト
.un_lead {
    margin-top: 50px;
    
    @include g.mq('md') {
        margin-top: 77px;
        font-size: 1.8rem;
    }
    
    &_text {
        font-size: 1.8rem;
        font-weight: 500;
        
        @include g.mq('md') {
            font-size: 2rem;
        }

        a {
            @extend %link;
        }
    }
}

// youtube埋め込み
.un_iframe {
    margin-top: 20px;

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
    }
}

.un_border_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    max-width: 400px;
    padding: 10px;
    margin-inline: auto;
    font-size: 1.6rem;
    color: g.color('base');
    text-decoration: none;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    @include g.mq('md') {
        font-size: 1.8rem;
    }

    &_icon {
        width: 24px;
        aspect-ratio: 1/1;

        @include g.mq('md') {
            width: 32px;
        }

        .arrow {
            fill: none;
            stroke-miterlimit: 10;
            stroke: g.color('key');
        }
    }
}

// 画像エリア
.un_imgArea {
    margin-bottom: 30px;

    @include g.mq('md') {
        margin-bottom: 70px;
    }

    &_img {
        width: fit-content;
        margin-inline: auto;
    }
}

// 各種サポート
.un_support {
    $this: '.un_support';
    margin-top: 30px;

    @include g.mq('md') {
        margin-top: 40px;
    }

    &_imgRow {
        margin-bottom: -30px;
    
        @include g.mq('md') {
            display: flex;
            gap: 20px;
            margin-bottom: -50px;
        }

        &:not(:has(#{$this}_img:nth-of-type(2))) {
            #{$this}_img img {
                object-fit: cover;
                aspect-ratio: 1000 / 400;
            }
        }

        // 画像重ならないパターン
        &.is_mb {
            margin-bottom: 15px;

            @include g.mq('md') {
                margin-bottom: 25px;
            }
        }
    }

    &_img {
        flex: 1;

        &:not(:first-of-type) {
            @include g.mq('sm') {
                margin-top: 20px;
            }
        }
        
        img {
            width: 100%;
        }

        &_caption {
            margin-top: 10px;
        }
    }

    &_contents {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        
        @include g.mq('md') {
            gap: 25px;
        }

        &.is_img {
            @include g.mq('lg') {
                gap: 55px;
                margin-top: 55px;
            }
        }

        &_img {
            margin: 15px 0;

            @include g.mq('lg') {
                margin: 0;
            }

            img {
                aspect-ratio: 600 / 400;
                object-fit: cover;
    
                @include g.mq('lg') {
                    width: 300px;
                }
            }
        }
    }

    &_row {
        position: relative;
        width: min(percentage(math.div(930, 1000)), 930px);
        margin-inline: auto;

        &.is_lg_w100 {
            @include g.mq('lg') {
                width: 100%;
            }
        }

        &.is_img {
            @include g.mq('lg') {
                display: flex;
                gap: 25px;
                width: 100%;
            }

            &.is_reverse {
                @include g.mq('lg') {
                    flex-direction: row-reverse;
                }
            }
        }
    }

    &_textArea {
        @include g.mq('lg') {
            flex: 1;
        }
    }

    &_title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        padding: 5px 30px;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: 1.3;
        color: g.color('key');
        background-color: g.color('white');
    
        @include g.mq('md') {
            min-height: 77px;
            padding: 5px 60px;
            font-size: 3rem;
        }

        &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 1em;
            height: 1em;
            font-size: 12px;
            background: url('../img/common/icon_triangle.svg') no-repeat border-box center/cover;
            
            @include g.mq('md') {
                font-size: 18px;
            }
        }
    }
    
    &_text {
        padding: 20px 30px 30px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: math.div(31.685, 18);
        background-color: #edf4fb;
        border-top: 1px solid #d9e2ef;
        
        @include g.mq('md') {
            padding: 20px 60px 30px;
            font-size: 1.8rem;
        }

        .note {
            font-size: 1.4rem;
            vertical-align: text-top;
        }

        a {
            @extend %link;
        }

        img {
            display: block;
            width: fit-content;
            margin-top: 20px;
            margin-inline: auto;
        }

        // テキスト画像横並び
        &.is_img {
            @include g.mq('lg') {
                display: grid;
                grid-template-columns: 1fr min(40%, 300px);
                gap: 20px;
            }
            
            &.is_reverse {
                @include g.mq('lg') {
                    grid-template-columns: min(40%, 300px) 1fr;
                }
                
            }

            img {
                @include g.mq('lg') {
                    margin-top: 0;
                }
            }
        }
    }
    &_note {
        display: block;
        margin-top: 10px;
        font-size: 1.4rem;
    }

    // 画像3つパターン
    &_contents.is_img {
        #{$this}_row {
            @include g.mq('lg') {
                &:nth-of-type(odd) {
                    #{$this}_title {
                        padding-right: 230px;
                    }
                    
                    #{$this}_text {
                        padding-right: 230px;
    
                        img {
                            right: 0;
                            translate: 100px;
                        }
                    }
                }
        
                &:nth-of-type(even) {
                    #{$this}_title {
                        padding-left: 230px;
    
                        &::before {
                            right: 5px;
                            left: auto;
                            rotate: 90deg;
                        }
                    }
                    
                    #{$this}_text {
                        padding-left: 230px;
    
                        img {
                            left: 0;
                            translate: -100px;
                        }
                    }
                }
            }
        }
    }
}

// 各種サポート（ボックス1つパターン）
.un_supportBox {
    position: relative;
    padding: 50px 30px;
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #d9e2ef;
    
    @include g.mq('md') {
        padding: 75px 60px 100px;
        margin-top: 40px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 1em;
        height: 1em;
        font-size: 12px;
        background: url('../img/common/icon_triangle.svg') no-repeat border-box center/cover;
        
        @include g.mq('md') {
            font-size: 18px;
        }
    }

    &_row {
        @include g.mq('lg') {
            display: flex;
            gap: 30px;
        }

        &:not(:first-of-type) {
            margin-top: 30px;

            @include g.mq('md') {
                margin-top: 50px;
            }
        }

        &.is_mt_l {
            &:not(:first-of-type) {
                margin-top: 50px;

                @include g.mq('md') {
                    margin-top: 80px;
                }
            }
        }

        &.is_reverse {
            @include g.mq('lg') {
                flex-direction: row-reverse;
            }
        }
    }

    &_col {
        @include g.mq('md') {
            flex: 1;
        }
    }
    
    &_title {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: 1.3;
        color: g.color('key');
    
        @include g.mq('md') {
            font-size: 3rem;
        }

        &.is_border {
            padding-bottom: 5px;
            border-bottom: 1px solid g.color('key');

            @include g.mq('md') {
                padding-bottom: 10px;
            }
        }
    }

    &_subtitle {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.3;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 2.2rem;
        }
    }
    
    &_text {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: math.div(31.685, 18);
        
        @include g.mq('md') {
            margin-top: 10px;
            font-size: 1.8rem;
        }

        a {
            @extend %link;
        }
    }

    &_img {
        text-align: center;

        @include g.mq('lg_max') {
            margin-top: 20px;
        }

        img {
            aspect-ratio: 600 / 400;
            object-fit: cover;

            @include g.mq('lg') {
                width: 300px;
            }
        }
    }
}

// サービス利用までの流れ
.un_processList {
    $this: '.un_processList';
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 35px;
    
    @include g.mq('md') {
        margin-top: 60px;
    }
    
    @include g.mq('xl') {
        flex-direction: row;
        justify-content: center;
    }

    &_item {
        display: flex;
        
        @include g.mq('xl') {
            flex: 1;
            flex-direction: column;
            max-width: 240px;
        }
    }

    .un_processNum {
        position: relative;
        z-index: 1;
        margin-inline: auto;

        @include g.mq('md') {
            margin-top: 10px;
        }
        
    }

    &_contents {
        flex: 1;
        margin: 30px 0 0 -30px;
        background-color: g.color('white');
        box-shadow: 0 0 3px rgba(#000, 0.2);

        @include g.mq('xl') {
            display: flex;
            flex-direction: column;
            margin: -20px 0 0 0;
        }
    }

    &_title {
        display: grid;
        place-content: center;
        min-height: 72px;
        padding: 20px;
        font-size: 2rem;
        font-weight: bold;
        line-height: math.div(26.5408, 22);
        letter-spacing: 0.06em;
        text-align: center;
        color: g.color('key');
        
        @include g.mq('md') {
            min-height: 120px;
            font-size: 2.2rem;
        }
    }

    &_text {
        padding: 20px 20px 30px;
        line-height: math.div(25.5118, 16);
        text-align: left;
        background: linear-gradient(45deg, rgba(#00b5ce, 0.07), rgba(#003dc7, 0.07));

        @include g.mq('xl') {
            flex: 1;
        }

        a {
            @extend %link;
        }
    }

    &.is_stretch {
        #{$this}_item {
            @include g.mq('xl') {
                max-width: none;
            }
        }
    }

    // 矢印ありパターン
    &.is_flow {
        #{$this}_item {
            position: relative;

            // 矢印
            &::before, &::after {
                position: absolute;
                left: 20px;
                translate: -50%;
                
                @include g.mq('md') {
                    left: 50px;
                }
                
                @include g.mq('xl') {
                    translate: 0 -50%;
                }
            }
    
            //矢印棒
            &::before {
                top: 80px;
                width: 8px;
                height: calc(93% - 72px);
                background-image: linear-gradient(135deg, #00b5ce 0%, #003dc7 100%);
                
                @include g.mq('md') {
                    top: 120px;
                    height: calc(93% - 100px);
                }
    
                @include g.mq('xl') {
                    top: 60px;
                    left: 75%;
                    width: 53%;
                    height: 8px;
                    background-image: linear-gradient(45deg, #00b5ce 0%, #003dc7 100%);
                }
            }
            
            // 矢印先端
            &::after {
                top: calc(80px + 93% - 72px);
                clip-path: polygon(0 0, 0 100%, 60% 50%);
                width: 1em;
                height: 1em;
                font-size: 23px;
                background-color: g.color('key');
                rotate: 90deg;
                
                @include g.mq('md') {
                    top: calc(120px + 93% - 100px);
                }
    
                @include g.mq('xl') {
                    top: 60px;
                    left: calc(75% + 53% - 1px);
                    rotate: 0deg;
                }
            }

            // 最後の要素以外表示
            &:not(:last-of-type) {
                &::before, &::after {
                    content: '';
                }
            }

            // 数字グラデーション
            &:nth-of-type(2) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -10%);
                }
            }
            &:nth-of-type(3) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -20%);
                }
            }
            &:nth-of-type(4) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -30%);
                }
            }
            &:nth-of-type(5) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -40%);
                }
            }
        }
    }
}

// サービス利用までの流れ　1行パターン
.un_processRow {
    $this: '.un_processRow';

    &_item {
        padding: 20px 20px 35px;

        @include g.mq('md') {
            padding: 30px 35px 35px;
        }

        @include g.mq('lg') {
            display: flex;
            gap: 35px;
        }

        &:not(:first-of-type) {
            margin-top: 20px;

            @include g.mq('md') {
                margin-top: 35px;
            }
        }

        &:nth-of-type(odd) {
            background-color: g.color('white');
        }

        &:nth-of-type(even) {
            background-color: #edf4fb;
        }
    }

    .un_processNum {
        margin-inline: auto;

        @include g.mq('lg') {
            margin-top: 10px;
        }
    }

    &_contents {
        margin-top: 20px;

        @include g.mq('lg') {
            flex: 1;
        }
    }

    &_title {
        font-size: 2rem;
        font-weight: bold;
        color: g.color('key');
        letter-spacing: 0.08em;
        line-height: 1.6;
        text-align: center;

        @include g.mq('md') {
            font-size: 2.4rem;
            text-align: left;
        }
    }

    &_text {
        font-size: 1.6rem;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        a {
            @extend %link;
        }
    }

    &.is_flow {
        #{$this}_item {
            position: relative;
            padding-left: 55px;

            @include g.mq('md') {
                padding-left: 105px;
            }

            @include g.mq('lg') {
                padding-left: 35px;
            }

            // 矢印
            &::before, &::after {
                position: absolute;
                left: 20px;
                translate: -50%;
                z-index: 1;

                @include g.mq('md') {
                    left: 30px;
                }

                @include g.mq('lg') {
                    left: 84px;
                }
            }
    
            //矢印棒
            &::before {
                top: 55px;
                width: 8px;
                height: calc(93% - 82px);
                background-image: linear-gradient(135deg, #00b5ce 0%, #003dc7 100%);
                
                @include g.mq('md') {
                    top: 95px;
                    height: calc(93% - 125px);
                }

                @include g.mq('lg') {
                    top: 155px;
                }
            }
            
            // 矢印先端
            &::after {
                top: calc(55px + 93% - 82px);
                clip-path: polygon(0 0, 0 100%, 60% 50%);
                width: 1em;
                height: 1em;
                font-size: 23px;
                background-color: g.color('key');
                rotate: 90deg;
                
                @include g.mq('md') {
                    top: calc(95px + 93% - 125px);
                }

                @include g.mq('lg') {
                    top: calc(155px + 93% - 125px);
                }
            }

            // 最後の要素以外表示
            &:not(:last-of-type) {
                &::before, &::after {
                    content: '';
                }
            }

            // 数字グラデーション
            &:nth-of-type(2) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -10%);
                }
            }
            &:nth-of-type(3) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -20%);
                }
            }
            &:nth-of-type(4) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -30%);
                }
            }
            &:nth-of-type(5) {
                .un_processNum {
                    background-color: color.scale(#3d6f9f, $lightness: -40%);
                }
            }
        }

        .un_processNum {
            margin-inline: 0;

            @include g.mq('lg_max') {
                position: absolute;
                inset: 0 auto auto 0;
                translate: -20px -20px;
            }
        }
    }
}

// サービス利用までの流れ　数字
.un_processNum {
    $_size: 70px;
    width: $_size;
    height: $_size;
    display: grid;
    place-content: center;
    color: #fff;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1;
    background-color: #3d6f9f;
    border-radius: 50%;
    
    @include g.mq('md') {
        $_size: 101px;
        width: $_size;
        height: $_size;
        padding-bottom: 6px;
        font-size: 5rem;
    }
}

// 導入事例
.un_cases {
    padding: 50px 0;
    background-color: g.color('white');
    border-radius: 10px;
    box-shadow:  0 0 11px rgba(#000051, 0.1);
    
    @include g.mq('md') {
        padding: 77px 0 108px;
    }

    .un_border_link {
        margin-bottom: 20px;
    }

    &_text {
        font-weight: 500;
        line-height: math.div(39.6055, 16);
        letter-spacing: 0.06em;

        a {
            @extend %link;
        }
    }
}

.un_casesList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
    margin-top: 60px;
    
    @include g.mq('md') {
        gap: 90px;
        margin-top: 90px;
    }

    &_item {
        position: relative;
        padding: 50px 20px 35px;
        border: 1px solid g.color('key');
        
        @include g.mq('md') {
            padding: 65px 40px 45px;
        }
    }

    &_num {
        $_size: 75px;
        width: $_size;
        height: $_size;
        position: absolute;
        top: 0;
        left: 25px;
        translate: 0 -50%;
        display: grid;
        place-content: center;
        font-size: 3.5rem;
        font-weight: bold;
        color: #fff;
        background-color: g.color('key');
        
        @include g.mq('md') {
            $_size: 89px;
            width: $_size;
            height: $_size;
            font-size: 5rem;
        }
    }

    &_title {
        padding-bottom: 10px;
        font-size: 2.5rem;
        font-weight: bold;
        line-height: math.div(48.5, 36);
        letter-spacing: 0.08em;
        color: g.color('key');
        border-bottom: 2px solid g.color('key');
        
        @include g.mq('md') {
            padding-bottom: 20px;
            font-size: 3.4rem;
        }
    }

    &_contents {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;

        @include g.mq('md') {
            flex-direction: row;
            gap: 30px;
            margin-top: 40px;
        }
    }
    
    &_img {
        @include g.mq('md') {
            width: min(percentage(math.div(300, 900)), 300px);
        }

        img {
            width: 100%;
        }
    }

    &_text {
        flex: 1;
        font-size: 1.6rem;
        line-height: math.div(31.685, 18);

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        a {
            @extend %link;
        }
    }
}

.un_casesDetail {
    &_text {
        font-size: 1.6rem;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }

    &_list {
        margin-top: 20px;
    }

    &_item {
        display: flex;
        font-size: 1.6rem;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }

    &_imgRow {
        margin-top: 20px;

        &:not(.is_col1) {
            @include g.mq('md') {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }
        }

    }

    &_img {
        max-width: 490px;
        width: 100%;
        margin-inline: auto;

        img {
            width: 100%;
            aspect-ratio: 490/320;
            object-fit: cover;
        }
    }
}

// サービス紹介動画
.un_movie {
    overflow: hidden;
    border-radius: 10px;
    background-color: g.color('white');

    &_title {
        display: grid;
        place-content: center;
        height: 80px;
        padding: 20px;
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.06em;
        line-height: 1.4;
        color: g.color('key');

        @include g.mq('md') {
            height: 105px;
            font-size: 2.7rem;
        }
    }

    &_body {
        padding: 40px 20px 50px;
        text-align: center;
        background-image: linear-gradient(45deg, rgba(#00b5ce, 0.1) 0%, rgba(#003dc7, 0.1) 100%);

        iframe {
            aspect-ratio: 16/9;
            width: 940px;
            max-width: 100%;
        }
    }
}

// ページ下部
.un_others {
    padding-top: 50px;
    background: url('../img/common/bg_02.webp') repeat border-box center/cover;

    @include g.mq('md') {
        padding-top: 100px;
    }

    .bl_contact {
        margin-top: 65px;

        @include g.mq('md') {
            margin-top: 110px;
        }
    }
}

// 関連サービス
.un_related {
    padding: 30px 0 45px;
    background-color: g.color('white');

    
    &_inner {
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: 33% 1fr;
        }
    }

    &_list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 20px;
        
        @include g.mq('lg') {
            gap: 15px;
            margin-top: 10px;
        }
    }
}

// PDF
.un_pdf {
    padding: 35px 0 40px;
    margin-top: 30px;
    background-color: g.color('white');

    &_inner {
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 30px;
        }
        
        @include g.mq('xl') {
            gap: 90px;
        }
    }

    .el_pdfBtn {
        margin-top: 20px;

        @include g.mq('lg') {
            margin-top: 0;
        }
    }
}

}

