/*------------------------------------------------------------------------
    インナー
------------------------------------------------------------------------*/
@use "../../global" as g;

.ly_inner {
	width: g.$inner_width_sp;
	max-width: 100%;
	margin: 0 auto;
    
    @include g.mq('xl') {
		width: g.$inner_width;
		margin: 0 auto;
	}
	
	&.is_pc {
        @include g.mq('sm') {
            width: 100%;     
        }
	}

    &.is_sp {
        @include g.mq('md') {
            width: 100%;
        }
    }

    &.is_small {
        @include g.mq('xl') {
            width: g.$inner_width_small;
        }
    }

    &.is_medium {
        @include g.mq('xl') {
            width: g.$inner_width_medium;
        }
    }
}