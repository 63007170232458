/**************************************************************************
    ABOUT US
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#en.aboutus {

// メインビジュアル
.bl_mvSub {
    background-image: url('../img/en/aboutus/mv.webp');
}

// トップメッセージ
.bl_message {
    padding: 70px 0;

    @include g.mq('md') {
        padding: 128px 0;
    }
}

// リード文
.un_lead {
    padding: 60px 0 80px;

    @include g.mq('md') {
        padding: 75px 0 100px;
    }

    .un_textRow_text {
        margin-top: 10px;
    }
}

// テキスト行
.un_textRow {
    &_title {
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.4;

        @include g.mq('md') {
            font-size: 3rem;
        }

        &.is_small {
            font-size: 2rem;

            @include g.mq('md') {
                font-size: 2.5rem;
            }
        }
    }
}


// セクション
.un_section {
    margin-top: 70px;

    @include g.mq('md') {
        margin-top: 128px;
    }

    .bl_contentsRow {
        color: g.color('white');
        
        &:not(:first-of-type) {
            margin-top: 50px;

            @include g.mq('md') {
                margin-top: 80px;
            }
        }

        &:nth-of-type(1) {
            .bl_contentsRow_bg {
                background: linear-gradient(45deg, #ffe207 0%, #008ae7 100%);
            }
        }

        &:nth-of-type(2) {
            .bl_contentsRow_bg {
                background: linear-gradient(45deg, #00d3ff 0%, #000099 100%);
            }
        }

        &:nth-of-type(3) {
            .bl_contentsRow_bg {
                background: linear-gradient(135deg, #45e2a9 0%, #008ae7 100%);
            }
        }

        .bl_contentsRow_title {
            line-height: 1.4;
            color: g.color('white');
        }
    }

}

// 会社概要
.un_outline {
    padding: 60px 0 80px;
    margin-top: 70px;
    
    @include g.mq('md') {
        padding: 75px 0 100px;
        margin-top: 128px;
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        
        @include g.mq('md') {
            gap: 30px;
        }
    }
}

.un_outlineLogoList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    margin-top: 50px;
    
    @include g.mq('md') {
        margin-top: 70px;
    }
}

.un_outlineRow {
    @include g.mq('md') {
        display: grid;
        grid-template-columns: 1fr 260px;
        gap: 30px;
    }
    
    &_img {
        margin-top: 20px;
        text-align: center;
        
        @include g.mq('md') {
            margin-top: 0;
        }

        img {
            width: 260px;
            height: 134px;
            object-fit: contain;
        }
    }
}

}