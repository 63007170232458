/*------------------------------------------------------------------------
	ヘッダー
------------------------------------------------------------------------*/
@use "../../global" as g;

.ly_header {
	position: fixed;
	top: 0;
	left: 0;
	min-width: 100%;
	z-index: map-get(g.$z-index, 'header');
	border-bottom: 1px solid g.color('white');
	background-color: transparent;
	height: map-get(g.$header_h, 'sm');

	@include g.mq('md') {
		height: map-get(g.$header_h, 'md');
	}
	
	@include g.mq('lg') {
		display: grid;
		grid-template-rows: repeat(2, 1fr);
		height: map-get(g.$header_h, 'lg');
	}
	
	// 背景透明
	&:not(.is_white) {
		@include g.mq('lg') {
			color: g.color('white');
		}
		
		.el_logo {
			img.is_color {
				display: none;
			}
		}

		.el_btn {
			border: 2px solid #fff;
			box-shadow: none;
			background-color: transparent;
			
			span {
				color: g.color('white');
			}
		}

		.bl_languageList > li {
			@include g.mq('lg') {
				border-color: #fff;
			}
		}
	}
	
	// 背景白
	&.is_white {
		color: g.color('base');
		background-color: #fff;
		box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);

		.el_logo {
			img.is_white {
				display: none;
			}
		}

		.el_menubar span {
			background-color: g.color('base');
		}

		.bl_subGnav_item a {
			color: g.color('base');
			border-color: g.color('base');
		}
	}

	// 英語サイト
	&.is_en {
		@include g.mq('lg') {
			display: block;
			height: map-get(g.$header_h, 'md');
		}
	}

	&_flex {
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		height: 100%;

		&.is_spaceBetween {
			justify-content: space-between;
		}

		&.is_end {
			justify-content: flex-end;
		}

		.el_btn {
			flex-shrink: 0;
		}
		
		.bl_subGnav {
			flex-shrink: 0;
		}
	}

	&_gnavWrap {
		@include g.mq('lg_max') {
			width: auto;
		}
	}
}

