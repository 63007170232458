/*------------------------------------------------------------------------
    ページアイコン
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_pageIcons {
    width: 43px;
    aspect-ratio: 1/1;

    // サービス紹介
    .business {
        fill: g.color('base');
        stroke-width: 0px;
    }

    // 支店案内
    .branch {
        fill: g.color('base');
        stroke-width: 0px;
    }

    // 会社情報
    .company {
        fill: g.color('base');
        stroke-width: 0px;
    }

    // 経営理念
    .philosophy {
        fill: none;
        stroke: g.color('base');
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2.31px;
    }

    // 採用情報
    .employment {
        fill: none;
        stroke: g.color('base');
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2.5px;
    }

    // サステナビリティ
    .sustainability {
        fill: g.color('base');
        stroke-width: 0px;
    }
    
    // ニュースリリース
    .newsrelease {
        fill: g.color('base');
        stroke-width: 0px;
    }

    // お問い合わせ
    .demand {
        fill: g.color('base');
        stroke-width:0px;
    }
}

