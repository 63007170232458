/*------------------------------------------------------------------------
    サブタイトル
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_subtitle {
    line-height: 1.5;

    &_en {
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 0;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 2.8rem;
        }
    }

    &_ja {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
        font-size: 1.4rem;
        font-weight: 500;
        
        @include g.mq('md') {
            font-size: 1.6rem;
        }

        &::before {
            content: '';
            width: 50px;
            height: 1px;
            background-color: g.color('key');
        }
    }
}