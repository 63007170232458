/**************************************************************************
    トップメッセージ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.message {
	// メインビジュアル
	.bl_mvSub {
		background-image: url(../img/company/message/mv.webp);
	}

	.un_main {
		padding: 35px 0 80px;
		
		@include g.mq('md') {
			padding: 60px 0 128px;
		}
	}

	.un_sectionHead {
		position: relative;
		width: g.$inner-width-sp;
		max-width: g.$inner-width-small;
		margin-inline: auto;

		&_image {
			img {
				width: 100%;
			}
		}

		&_sign {
			position: absolute;
			inset: auto 10px 10px auto;
			margin-top: 20px;
			font-weight: bold;
			color: g.color('white');
			
			@include g.mq('md') {
				inset: auto 20px 20px auto;
				margin-top: 30px;
			}

			dt {
				font-size: 1.2rem;
				line-height: math.div(28.3465, 15);

				@include g.mq('md') {
					font-size: 1.5rem;
				}
			}

			dd {
				font-size: 1.6rem;
				line-height: math.div(31.6829, 18);

				@include g.mq('md') {
					font-size: 2.1rem;
				}
			}
		}
	}

	.un_sectionBody {
		&_text {
			font-weight: 500;
			line-height: math.div(39.6055, 16);

			&:not(:first-of-type) {
				margin-top: 20px;

				@include g.mq('md') {
					margin-top: 40px;
				}
			}
		}
	}
}
