/*------------------------------------------------------------------------
	about
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_toSite {
    background: url('../img/common/to_site_bg.webp') no-repeat border-box left center/cover;
    
    .ly_inner {
        display: grid;
        place-content: center start;
        min-height: 320px;
    }

    &_textArea {
        max-width: 482px;
    }

    &_title {
        font-size: 2.6rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 3.2rem;
        }
    }

    &_text {
        margin-top: 10px;
        font-size: 1.6rem;
        line-height: 1.6;
        color: g.color('key');
        
        @include g.mq('md') {
            max-width: 378px;
        }
    }

    .el_btn {
        margin-top: 20px;
    }
} 