/*------------------------------------------------------------------------
    お問い合わせ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_contact {
    padding: 40px 0 50px;

    @include g.mq('md') {
        padding: 80px 0 100px;
    }

    &_inner {
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: percentage(math.div(467, 1280)) 1fr;
            align-items: start;
        }
    }

    &_contents {
        @include g.mq('lg_max') {
            margin-top: 20px;
        }
    }

    &_subtitle {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.6;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 2.5rem;
        }
    }

    &_text {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: math.div(31.1811, 18);

        @include g.mq('md') {
            font-size: 1.8rem;
        }
    }

    &_btnArea {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-top: 20px;
        
        @include g.mq('md') {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }

    &_btn {
        $type: (
            'contact': (
                iconWidth: 32px,
                iconHeight: 23px,
                color: #00ab80,
            ),
            'branch': (
                iconWidth: 20px,
                iconHeight: 28px,
                color: #003b86,
            ),
        );

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 75px;
        font-size: 1.8rem;
        color: g.color('white');
        border-radius: 5px;

        @include g.mq('md') {
            height: 96px;
            font-size: 2.1rem;
        }

        &::before {
            content: '';
        }

        @each $key, $val in $type  {
            &.is_#{$key} {
                background-color: g.map-deep-get($val, color);
                
                &::before {
                    width: g.map-deep-get($val, iconWidth);
                    height: g.map-deep-get($val, iconHeight);
                    background: url(../img/common/icon_#{$key}.svg) no-repeat center/cover;
                }
            }
        }
    }
}

