/*------------------------------------------------------------------------
    丸矢印
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_circleArrow {
    width: 24px;
    aspect-ratio: 1/1;
    
    @include g.mq('md') {
        width: 32px;
    }

    // 円
    .circle {
        stroke-width: 0px;
        fill: g.color('key');
    }

    // 矢印
    .arrow {
        fill: none;
        stroke-miterlimit: 10;
        stroke: g.color('white');
    }

    // 三角
    .triArrow {
        fill: none;
        stroke: g.color('white');
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
    }

    // 大きい
    &.is_large {
        width: 30px;

        @include g.mq('md') {
            width: 42px;
        }
    }

    // 下向き
    &.is_bottom {
        rotate: 90deg;
    }

    // アニメーション用
    &.is_forAnimation {
        overflow: hidden;
        background-color: g.color('key');
        border-radius: 50%;

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 1;
            translate: 0 -1px;
            margin: auto;
            width: 10px;
            height: 3px;
            background: url('../img/common/icon_arrow_white.svg') no-repeat border-box center/cover;

            @include g.mq('md') {
                width: 14px;
                height: 4px;
            }
        }

        &.is_large {
            &::before {
                @include g.mq('md') {
                    width: 19px;
                    height: 5px;
                    translate: 0 -2px;
                }
            }
        }
    }
}

