/*------------------------------------------------------------------------
	住所
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_address {
	@include g.mq('lg_max') {
		display: none;
	}
	
    dt {
		font-size: 1.7rem;
		font-weight: bold;
		line-height: math.div(32, 17);
	}
	
	dd {
		font-size: 1.5rem;
		font-weight: 600;
		line-height: math.div(32, 15);
	}
}