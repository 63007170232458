/*------------------------------------------------------------------------
    wow.js用のアニメーション
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.wow {
    // fadeInの要素が初期描画時に一瞬表示されないようにする
    visibility: hidden;
    
    // フェードイン
    &.customFadeIn {
        &.animate__animated {
            animation-name: customFadeIn;
            animation-duration: 1s;
            animation-fill-mode: both;
        
            @keyframes customFadeIn {
                from {
                    opacity: 0;
                    transform: translateY(100px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}
