/*------------------------------------------------------------------------
	数字で見る
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_data {
	position: relative;
	z-index: 1;

	&_container {
		border-top: 1px solid currentColor;
	
		@include g.mq('md') {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			border-top: none;
		}
	}

	// データ
	.bl_dataItem {
		position: relative;
		padding: 20px 0 25px;
		margin: 0 1.66%;
		text-align: center;
		border-bottom: 1px solid currentColor;
	
		@include g.mq('md') {
			width: 46%;
			padding: 34px 0 30px;
			margin: 0 2%;
	
			&:nth-child(-n+2) {
				border-top: 1px solid currentColor;
			}
	
			&:nth-child(even) {
				&::before {
					content: '';
					position: absolute;
					top: 20px;
					left: -4%;
					display: block;
					width: 1px;
					height: calc(100% - 40px);
					background-color: currentColor;
				}
			}
		}
	}
	
	&:not(.is_col2) {
		.bl_dataItem {
			@include g.mq('lg') {
				width: 30%;
				min-height: 208px;
				margin: 0 1.66%;
	
				&:nth-child(-n+3) {
					border-top: 1px solid currentColor;
				}
	
				&:nth-child(even) {
					&::before {
						display: none;
					}
				}
	
				&:nth-child(3n+2),
				&:nth-child(3n+3) {
					&::before {
						content: '';
						position: absolute;
						top: 20px;
						left: -4%;
						display: block;
						width: 1px;
						height: calc(100% - 40px);
						background-color: currentColor;
					}
				}
			}
		}
	}
	
	// データ > タイトル
	.bl_dataItem_title {
		display: inline-flex;
		width: fit-content;
		align-items: center;
		gap: 8px;
		margin: 0 auto 10px;
		text-align: left;
		line-height: 1.5;
		font-size: 1.8rem;
		font-weight: bold;
	
		@include g.mq('md') {
			gap: 10px;
			font-size: 2.4rem;
		}
	
		@include g.mq('lg') {
			font-size: 2.6rem;
		}
	}
	
	.bl_dataItem_title_small {
		font-size: 1.3rem;
	
		@include g.mq('md') {
			font-size: 1.6rem;
		}
	
		@include g.mq('lg') {
			font-size: 1.8rem;
		}
	}
	
	// データ > タイトル > アイコン
	.bl_dataItem_title_icon {
		display: inline-block;
		height: 32px;
	
		@include g.mq('md') {
			height: 38px;
		}
	
		@include g.mq('lg') {
			height: 42px;
		}
	}
	
	// データ > タイトル > 内容
	.bl_dataItem_body {
		width: fit-content;
		margin: 0 auto;
	}
	
	// データ > 数値
	.bl_dataItem_value {
		line-height: 1;
		font-size: 2.5rem;
	
		@include g.mq('md') {
			font-size: 4rem;
		}
	
		@include g.mq('lg') {
			font-size: 4.3rem;
		}
	
		>span {
			font-size: 5rem;
	
			@include g.mq('md') {
				font-size: 6.6rem;
			}
	
			@include g.mq('lg') {
				font-size: 7.1rem;
			}
		}
	}
	
	// データ > 補足
	.bl_dataItem_extra {
		margin: 10px 0 0;
		line-height: 1.5;
		font-size: 1.5rem;
	
		@include g.mq('md') {
			margin-top: 20px;
			font-size: 1.8rem;
		}
	
		@include g.mq('lg') {
			font-size: 2rem;
		}
	
		span {
			display: block;
			font-size: 1.1rem;
	
			@include g.mq('md') {
				font-size: 1.4rem;
			}
		}
	}
	
	// データ > 注釈
	.bl_dataItem_annotation {
		margin: 8px 0 0;
		line-height: 1.5;
		text-align: right;
		font-size: 1.1rem;
	
		@include g.mq('md') {
			font-size: 1.3rem;
		}
	
		@include g.mq('lg') {
			font-size: 1.4rem;
		}
	}

	// 下部注釈
	.bl_dataAnnotation {
		text-align: right;
		width: 96%;
		margin: 10px auto 0;
	}
	
	&:not(.is_col2) {
		.bl_dataAnnotation {
			@include g.mq('md') {
				width: 46%;
			}
	
			@include g.mq('lg') {
				width: 64%;
			}
		}
	}
}

