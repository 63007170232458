/**************************************************************************
    トップページ
**************************************************************************/
@use "../global" as g;
@use "sass:math";

#top {

// サブタイトル
.un_subtitle {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.6;

    @include g.mq('md') {
        font-size: 2.5rem;
    }

    &.is_center {
        text-align: center;
    }

    &.is_color {
        color: g.color('key');
    }
}

/*------------------------------------------------------------------------
    メインビジュアル
------------------------------------------------------------------------*/
.un_mvSliderWrap{
    height: 100%;
    z-index: -1;
}
.un_mvSliderslide{
    @keyframes zoom {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.18);
        }
    }

    &_img {
        height: 100%;
    }
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.swiper-slide-active,
    &.swiper-slide-prev{
        img{
            animation: zoom 7s ease-in forwards;
        }
    }
}
.un_mv {
    position: relative;
    // padding-top: 171%;
    height: 96vh;
    z-index: 1;

    @include g.mq('md') {
        height: 1016px;
        padding: 0;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 13px;
        background: url('../img/top/mv_wave_sp.svg') no-repeat border-box center/auto 100%;
        z-index: 1;
        
        @include g.mq('md') {
            height: 58px;
            background-image: url('../img/top/mv_wave_pc.svg');
            background-size: cover;
        }
    }
    
    &_inner {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    &_box{
        width: 100%;
        height: 100%;
        position: relative;
    }

    &_title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        @include g.mq('sm') {
            left: 0;
            right: 0;
            margin: auto;
        }
        
        @include g.mq('md') {
            left: 0;
        }
    }

    &_scroll {
        position: absolute;
        bottom: 44px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        padding-left: 10px;
        margin: auto;
        font-size: 1.5rem;
        color: #fff;
        letter-spacing: 0;
        
        @include g.mq('md') {
            bottom: 130px;
            left: 0;
            transform: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 0;
            margin-top: 12px;
            background-color: g.color('border_dark');
            animation: to_scroll_sp 1.2s ease-in-out infinite;

            @include g.mq('md') {
                animation-name: to_scroll_pc;
            }
            
            @keyframes to_scroll_sp {
                100% {
                    height: 70px;
                }
            }

            @keyframes to_scroll_pc {
                100% {
                    height: 136px;
                }
            }
        }
    }
}


/*------------------------------------------------------------------------
    導入
------------------------------------------------------------------------*/
.un_introduction {
    position: relative;
    padding-top: 54px;
    background-color: g.color('bg');
    
    @include g.mq('lg') {
        padding: 235px 0 240px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 30%;
        left: 40%;
        transform: translateX(-50%);
        width: 368px;
        height: 385px;
        background: url('../img/top/introduction_bg.webp') no-repeat border-box center/cover;
        
        @include g.mq('md') {
            width: 736px;
            height: 767px;
        }
        
        @include g.mq('lg') {
            top: 100px;
            left: 45%;
        }
    }

    &_inner {
        @include g.mq('md') {
            padding: 0 g.get-inner-side(g.$inner-width-sp);
        }
        
        @include g.mq('lg') {
            display: flex;
            align-items: center;
            gap: 50px;
            padding: 0;
        }
    }

    &_img {
        flex: 1;
        position: relative;
        z-index: 1;
        text-align: center;

        &.is_02 {
            margin-top: 40px;

            @include g.mq('md') {
                margin-top: 90px;
            }

            @include g.mq('lg') {
                margin-top: 0;
            }
        }
    }
    
    &_area {
        position: relative;
        z-index: 1;
        margin-top: 93px;

        @include g.mq('lg') {
            margin-top: 0;
        }
        
        &::before {
            content: '';
            position: absolute;
            top: -20px;
            right: 0;
            left: 0;
            margin: auto;
            z-index: -1;
            $_size: 220px;
            width: $_size;
            height: $_size;
            background: url('../img/top/introduction_circle.svg') no-repeat border-box center/cover;
            animation: rotation 15s linear infinite;
            
            @include g.mq('md') {
                width: 413px;
                height: 408px;
            }

            @include g.mq('lg') {
                top: 0;
                bottom: 0;
            }

            @keyframes rotation {
                100% {
                    rotate: 360deg;
                }
            }
        }
    }

    &_catch {
        margin-top: -5px;
        font-size: 1.6rem;
        letter-spacing: 0;
        text-align: center;
        
        @include g.mq('md') {
            font-size: 3rem;
        }

        @include g.mq('lg') {
            margin-top: 0;
        }
    }

    &_text {
        margin-top: 15px;
        font-size: 1.3rem;
        line-height: math.div(40, 18);
        text-align: center;
        
        @include g.mq('md') {
            margin-top: 20px;
            font-size: 1.8rem;
        }
    }

    &_moreWrap {
        text-align: center;
        
        @include g.mq('md') {
            margin-top: 10px;
        }
    }
    
    &_more {
        display: inline-flex;
        align-items: baseline;
        font-size: 1.3rem;
        
        @include g.mq('sm') {
            justify-content: center;
            width: 176px;
            margin-top: 25px;
            font-family: g.font('en');
            font-weight: bold;
            line-height: 42px;
            border: 1px solid g.color('base');
            border-radius: 100vh;
        }
        
        @include g.mq('md') {
            font-size: 1.8rem;
        }

        &::after {
            content: '';
            width: 10px;
            height: 3px;
            background: url('../img/common/icon_arrow.svg') no-repeat border-box center/cover;
            
            @include g.mq('md') {
                width: 14px;
                height: 4px;
            }
        }
    }
}


/*------------------------------------------------------------------------
    サービス
------------------------------------------------------------------------*/
.un_service {
	padding: 82px 0 95px;
    background: url('../img/top/service_bg_sp.webp') no-repeat border-box top center/cover;
    
    @include g.mq('md') {
        background-image: url('../img/top/service_bg_pc.webp');
    }
    
    @include g.mq('lg') {
        padding: 78px 0 310px;
    }

    &.is_en {
        @include g.mq('lg') {
            padding-bottom: 100px;
        }
    }

    .el_pageIcons {
        margin-bottom: 5px;
        width: 33px;
        
        @include g.mq('md') {
            width: 66px;
        }
        
        .business {
            fill: g.color('key');
        }
    }

    .un_subtitle {
        margin-top: 20px;
        
        @include g.mq('md') {
            margin-top: 30px;
        }
    }

    .bl_subNav {
        margin-top: 29px;
    
        @include g.mq('md') {
            margin-top: 94px;
        }
    }
}

.un_serviceSliderWrap {
    position: relative;
    margin-top: 20px;

    @include g.mq('sm') {
        padding: 0 g.get-inner-side(g.$inner-width-sp) 45px;
        padding-bottom: 45px;
    }
    
    @include g.mq('md') {
        margin-top: 60px;
    }

    // ナビゲーション
    &_arrow {
        // 座標はJSで設定
        position: absolute;
        z-index: 1;
        $_size: 50px;
        width: $_size;
        height: $_size;
        background: url('../img/common/icon_slider_arrow.svg') no-repeat border-box center/cover;
        
        @include g.mq('md') {
            $_size: 80px;
            width: $_size;
            height: $_size;
        }

        &.is_next {
            translate: 0 -50%;
            
            @include g.mq('lg') {
                translate: 50% -50%;
            }
        }
    
        &.is_prev {
            translate: 0 -50%;
            rotate: 180deg;
            
            @include g.mq('lg') {
                translate: -50% -50%;
                rotate: 180deg;
            }
        }
    }
}

.un_serviceSlide {
    width: 100%;

    @include g.mq('md') {
        width: g.$inner-width-sp;
    }

    @include g.mq('lg') {
        width: 900px;
    }
    
    &_img {
        position: relative;

        img {
            width: 100%;
        }
    }

    &_en {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(45%);
        font-size: 3.3rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        color: rgba(g.color('key'), 0.15);
        
        @include g.mq('md') {
            right: 33px;
            font-size: 7rem;
        }
        
        @include g.mq('lg') {
            right: 66px;
        }
    }

    &_area {
        margin-top: 20px;
        
        @include g.mq('md') {
            margin-top: 40px;
        }
        
        @include g.mq('lg') {
            display: flex;
            gap: 75px;
            padding: 0 65px 0 50px;
        }
    }

    &_data {
        flex: 1;
    }

    &_title {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: 1.4;
        color: g.color('key');
        
        @include g.mq('md') {
            font-size: 3.5rem;
        }
    }

    &_text {
        margin-top: 10px;
        font-size: 1.3rem;
        line-height: math.div(35, 18);
        
        @include g.mq('md') {
            margin-top: 20px;
            font-size: 1.8rem;
        }
    }

    &_tagList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 5px;
        margin-top: 24px;
    }

    &_btnWrap {
        text-align: center;
    }

    .el_btn {
        margin-top: 30px;
        margin-inline: auto;
        
        @include g.mq('md') {
            margin-top: 86px;
        }
    }
}


/*------------------------------------------------------------------------
    会社情報
------------------------------------------------------------------------*/
.un_about {
    $this: '.un_about';
    background-color: g.color('bg');
    
    &_wrap {
        padding-top: 70px;
        background-color: g.color('bg');

        @include g.mq('md') {
            padding-top: 100px;
        }
    }

    &_box {
        width: g.$inner-width;
        max-width: g.$inner-width-sp;
        margin: auto;
        background-color: g.color('white');
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    &_area {
        padding: 12px 8px 32px;

        @include g.mq('md') {
            padding: 40px;
        }

        @include g.mq('lg') {
            display: flex;
            align-items: center;
            gap: 30px;
        }
        
        @include g.mq('xl') {
            gap: 77px;
        }
    }

    &:not(.is_en) {
        position: relative;

        #{$this}_box {
            transform: translateY(-46px);

            @include g.mq('lg') {
                position: absolute;
                top: -191px;
                left: 0;
                right: 0;
                transform: translateY(0);
                z-index: 1;
            }
        }
    }

    &_img {
        text-align: center;

        @include g.mq('lg') {
            width: min(43%, 506px);
        }
    }

    &_col {
        padding: 0 16px;
        margin-top: 16px;
        text-align: center;
        
        @include g.mq('lg') {
            padding: 0;
            margin-top: 5px;
        }
    }

    &.is_en {
        #{$this}_col {
            @include g.mq('lg') {
                flex: 1;
            }
        }
    }

    &_conts {
        display: inline-block;
    }

    .el_pageIcons {
        width: 29px;
        
        @include g.mq('md') {
            width: 57px;
        }
        
        .company {
            fill: g.color('accent');
        }
    }

    .el_title {
        margin-bottom: 8px;
    }

    &_text {
        @include g.mq('md') {
            text-align: center;
        }
    }

    &_btnWrap {
        margin-top: 20px;
        text-align: center;
        
        @include g.mq('md') {
            margin-top: 30px;
        }
    }
}


/*------------------------------------------------------------------------
    サステナビリティ
------------------------------------------------------------------------*/
.un_sustainability {
    position: relative;
    padding: 43px 0;
    color: g.color('white');
    overflow: hidden;
    
    @include g.mq('md') {
        padding: 70px 0 80px;
    }
    
    @include g.mq('lg') {
        padding: 397px 0 117px;
    }

    &_bg {
        position: absolute;
        inset: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: url('../img/top/sustainability_bg_sp.webp') no-repeat border-box center/cover;

        @include g.mq('md') {
            background-image: url('../img/top/sustainability_bg_pc.webp');
        }
    }

    .el_pageIcons {
        width: 35px;
        
        @include g.mq('md') {
            width: 65px;
            margin-bottom: -10px;
        }

        .sustainability {
            fill: g.color('white');
        }
    }

    &_text {
        margin-top: 17px;
        line-height: math.div(33, 16);
        
        @include g.mq('md') {
            text-align: center;
        }
    }

    &_btnWrap {
        margin-top: 20px;
        text-align: center;
    }
}

/*------------------------------------------------------------------------
    PR領域
------------------------------------------------------------------------*/
.un_pr {
    margin-top: 30px;
    
    @include g.mq('md') {
        margin-top: 50px;
    }
    
    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 20px;
        background-color: g.color('bg');
        
        @include g.mq('md') {
            padding: 30px;
        }
    }

    &_item {
        font-weight: 500;
        line-height: 1.4;
        color: g.color('key');
        
        @include g.mq('md') {
            text-align: center;
        }

        a {
            text-decoration: underline;
        }
    }
}


/*------------------------------------------------------------------------
    ニュース
------------------------------------------------------------------------*/
.un_news {
    padding-top: 34px;

    @include g.mq('md') {
        padding-top: 70px;
    }

    @include g.mq('lg') {
        padding-top: 104px;
    }

    &_inner {
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: 30.9% 1fr;
        }
    }

    &_head {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &_main {
        margin-top: -2px;

        @include g.mq('md') {
            margin-top: 20px;
        }

        @include g.mq('lg') {
            margin-top: 5px;
        }
    }

    &_btnWrap {
        margin-top: 42px;

        @include g.mq('md') {
            margin-top: 50px;
        }

        @include g.mq('lg') {
            margin-top: 35px;
        }

        .el_btn {
            @include g.mq('lg_max') {
                margin-inline: auto;
            }
        }
    }
}


/*------------------------------------------------------------------------
    ピックアップ
------------------------------------------------------------------------*/
.un_pickup {
    padding: 65px 0 50px;
    background: url('../img/top/pickup_bg_sp.webp') no-repeat border-box center/cover;
    
    @include g.mq('md') {
        padding: 130px 0 120px;
        background-image: url('../img/top/pickup_bg_pc.webp');
    }
    
    @include g.mq('lg') {
        padding: 226px 0 241px;
    }

    &_list {
        padding: 30px 0 60px g.get-inner-side(g.$inner-width-sp);
        margin: -5px auto 0;
        
        @include g.mq('lg') {
            max-width: g.$inner-width-sp;
            padding: 30px 20px; //画像拡大分のpadding確保
        }
        
        @include g.mq('xl') {
            max-width: g.$inner-width;
        }

        .swiper-wrapper {
            @include g.mq('lg') {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 25px;
                margin-top: 39px;
            }

            @include g.mq('xl') {
                gap: 52px;
            }
        }
        
    }

    &_item {
        width: 198px;
        padding-bottom: 17px;

        @include g.mq('lg') {
            width: auto;

            &:nth-of-type(1) {
                margin-top: 33px;
            }

            &:nth-of-type(2) {
                margin-top: 66px;
            }
    
            &:nth-of-type(3) {
                margin-top: 0;
            }

            &:nth-of-type(4) {
                margin-top: 33px;
            }
        }

        a {
            @include g.bg_flow(g.color('white'), '.el_circleArrow::after', false);
            position: relative;
            display: block;

            img {
                transition: 0.3s;
                border-radius: 10px;
            }

            &:hover {
                opacity: 1;

                img {
                    scale: 1.1;
                }

                .el_circleArrow {
                    &::before {
                        background-image: url('../img/common/icon_arrow_key.svg');
                    }
                }
            }
        }
    }

    .el_circleArrow {
        position: absolute;
        right: 25px;
        bottom: 0;
        transform: translateY(50%);
        
        @include g.mq('md') {
            right: 38px;
        }

        &::before {
            z-index: 2;
        }
    }
}


/*------------------------------------------------------------------------
    バナー
------------------------------------------------------------------------*/
.un_banner {
    position: relative;
    padding-top: 29px;

    @include g.mq('md') {
        padding-top: 0;
    }

    &_item {
        max-width: g.$inner-width-sp;
        width: fit-content;
        margin: auto;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 0 8px rgba(#000, 0.3);

        @include g.mq('md') {
            position: absolute;
            top: g.get_vw(-129, 1280);
            left: 0;
            right: 0;
        }

        @include g.mq('xl') {
            max-width: g.$inner_width_small;
        }

        a {
            display: inline-block;
            padding: 10px;
            background-color: g.color('white');
        }
    }

}


/*------------------------------------------------------------------------
    パートナー・事業サービスの紹介
------------------------------------------------------------------------*/
.un_partner {
    padding: 25px 0;
    background-color: g.color('white');
    
    @include g.mq('md') {
        padding: 165px 0 100px;
    }
    
    @include g.mq('lg') {
        padding: 230px 0 100px;
    }

    &.is_en {
        padding: 50px 0 25px;

        @include g.mq('md') {
            padding: 100px 0 50px;
        }
    }

    &_inner {
        position: relative;
    }

    .un_subtitle {
        margin-top: 10px;

        @include g.mq('md') {
            margin-right: 20px;
        }
    }

    &_main {
        margin-top: 15px;
        
        @include g.mq('md') {
            width: 100vw;
            margin-inline: calc(50% - 50vw);
            margin-top: 30px;
        }

        .swiper-wrapper {
            transition-timing-function: linear;

            @include g.mq('sm') {
                transform: none!important;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 15px 20px;
            }
        }
    }

    &_slide {
        width: auto;
        text-align: center;

        a {
            overflow: hidden;
            display: block;
            width: fit-content;
            margin-inline: auto;
            border-radius: 5px;

            img {
                @include g.mq('lg') {
                    width: 233px;
                    height: 233px;
                    transition: 0.3s;
                }
            }

            &:hover {
                opacity: 1;

                img {
                    scale: 1.1;
                }
            }
        }

        &.is_border {
            a {
                border: 1px solid g.color('base');
            }
        }
    }
}

/*------------------------------------------------------------------------
    Key numbers
------------------------------------------------------------------------*/
.un_numbers {
    padding: 50px 0;
    background-color: g.color('bg');

    @include g.mq('md') {
        padding: 75px 0 100px;
    }

    .un_subtitle {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }

    .bl_data {
        margin-top: 30px;

        @include g.mq('md') {
            margin-top: 50px;
        }
    }

    .bl_dataItem_title {
        margin-top: 10px;
        text-align: center;
    }
}

}

