/*------------------------------------------------------------------------
	コンテンツ行
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_contentsRow {
    $this: &;
    position: relative;

    @include g.mq('lg') {
        padding-top: 85px;
    }

    &_bg {
        padding: 50px g.get-inner-side(g.$inner-width-sp) 80px;
        background: url('../img/common/contentsRow_bg.webp') no-repeat border-box center/cover;
        
        @include g.mq('md') {
            padding: 75px g.get-inner-side(g.$inner-width-sp) 90px;
        }

        @include g.mq('lg') {
            width: calc(860px + g.get-inner-side(g.$inner-width-sp));
        }
        
        @include g.mq('xl') {
            width: calc(860px + g.get-inner-side());
        }
    }

    &_textArea {
        @include g.mq('lg') {
            width: 540px;
        }
    }

    &_title {
        font-size: 2.6rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 3.5rem;
        }
    }

    &_subTitle {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: 1;

        @include g.mq('md') {
            font-size: 2.2rem;
            margin-top: -3px;
        }
    }

    &_text {
        margin-top: 20px;
        font-size: 1.6rem;
        line-height: math.div(39.685, 18);

        @include g.mq('md') {
            font-size: 1.8rem;
        }
    }

    .el_btn {
        margin-top: 20px;
    }

    &_img {
        width: g.$inner-width-sp;
        margin-top: -30px;
        margin-inline: auto;
        text-align: center;
        
        @include g.mq('lg') {
            position: absolute;
            top: 0;
            width: calc(100% - (590px + g.get-inner-side(g.$inner-width-sp)));
            margin-top: 0;
        }

        @include g.mq('xl') {
            width: 600px;
        }

        img {
            @include g.mq('lg') {
                height: 450px;
                object-fit: cover;
            }
        }
        
        &.is_w_auto {
            img {
                @include g.mq('lg') {
                    width: auto;
                }
            }
        }

        &_note {
            margin-top: 5px;
            font-size: 1.2rem;

            @include g.mq('md') {
                font-size: 1.4rem;
            }
        }
    }
    
    &:not(.is_reverse) {
        #{$this}_bg {
            @include g.mq('lg') {
                padding-right: 0;
            }
            
            @include g.mq('xl') {
                padding-left: calc(g.get-inner-side() + 40px);
            }
        }

        #{$this}_img {
            @include g.mq('lg') {
                right: 0;
            }
            
            @include g.mq('xl') {
                right: calc(g.get-inner-side() + 40px);
            }
        }
    }
    
    &.is_reverse {
        #{$this}_bg {
            @include g.mq('lg') {
                padding-left: 0;
                margin-left: auto;
            }
            
            @include g.mq('xl') {
                padding-right: calc(g.get-inner-side() + 40px);
            }
        }

        #{$this}_textArea {
            @include g.mq('lg') {
                margin-left: auto;
            }
        }

        #{$this}_img {
            @include g.mq('lg') {
                left: 0;
            }
            
            @include g.mq('xl') {
                left: calc(g.get-inner-side() + 40px);
            }
        }
    }
}

