/*------------------------------------------------------------------------
    タグ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_tag {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    min-height: 31px;
    padding: 5px 17px;
    font-size: 1.2rem;
    line-height: 1.2;
    color: g.color('key');
    background-color: g.color('white');
    border-radius: 10px;
    
    @include g.mq('md') {
        gap: 10px;
        min-height: 44px;
        font-size: 1.6rem;
    }

    &::before {
        content: '';
        @include g.arrow('right', 5px, 2px, g.color('key'));

        @include g.mq('md') {
            font-size: 6px;
        }
    }

    &.is_gray {
        background-color: #f4f2f4;
    }
}