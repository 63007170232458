@charset "utf-8";

/*******************************************
	/css/style.css
*******************************************/
@use "base";
@use "module";
@use "page";

//----------- フォント -----------//

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');