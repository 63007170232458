/*------------------------------------------------------------------------
    フェードインアニメーション用
------------------------------------------------------------------------*/
.js_fadeIn {
    visibility: hidden;
    opacity: 0;
    transition: 1.5s;

    &.is_show {
        visibility: visible;
        opacity: 1;
    }
}