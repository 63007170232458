/**************************************************************************
    サステナビリティ　環境負荷に配慮した取組
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability.environment {

// セクション大枠
.un_section {
  padding: 70px 0 120px;

  @include g.mq('md') {
    padding: 120px 0 200px;
  }

  // 背景画像
  &.is_bg_A {
    background: url('../img/sustainability/materiality/environment/bg_A.webp') no-repeat border-box center/cover;
  }
  &.is_bg_loop {
    background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
    background-size: 100% auto;
  }

  // グラデーション
  &:first-of-type {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto auto 0;
      width: 100%;
      height: 350px;
      background-image: linear-gradient(to bottom, #fff, transparent);
    }

    .ly_inner {
      position: relative;
      z-index: 1;
    }
  }
}

// イントロ文
.un_intro {
  max-width: 1000px;
  margin: 0 auto 70px;

  @include g.mq('md') {
    margin-bottom: 120px;
  }
}

// 資源の節約
.un_resourceSaving {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include g.mq('lg') {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &_item {
    display: flex;
    align-items: center;
  }

  &_item_image {
    display: block;
    width: 60px;

    @include g.mq('lg') {
      width: 40%;
    }
  }

  &_item_label {
    margin-top: -8px;
    padding-left: 10px;
    color: g.color('key');
    font-weight: bold;
    line-height: 1.5;

    @include g.mq('lg') {
      padding-left: 2px;
    }

    span {
      display: inline-block;
    }
  }
}

// 下線リンク
.un_link {
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}

// クラウドサービスの画像
.un_cloudServiceImages {
  margin-top: 30px;

  @include g.mq('md') {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  @include g.mq('lg') {
    margin-top: 50px;
    gap: 40px;
  }

  img {
    display: block;
    margin-top: 10px;
    margin-inline: auto;
    height: 80px;

    @include g.mq('md') {
      margin: 0;
      height: 100px;
    }

    @include g.mq('lg') {
      height: 173px;
    }
  }
}

// グリッドレイアウト
.un_grid {
  @include g.mq('md') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
  }

  &.is_smallImage {
    @include g.mq('md') {
      grid-template-columns: auto 28%;
      gap: 10%;
    }
  }

  &_contents {
  }
  &.is_reverse .un_grid_contents {
    @include g.mq('md') {
      order: 2;
    }
  }

  &_image {
    margin-top: 20px;

    @include g.mq('md') {
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    &.is_block {
      @include g.mq('md') {
        display: block;
      }
    }

    picture {
      display: block;
      border-radius: 10px;
      overflow: hidden;

      @include g.mq('md') {
        border-radius: 30px;
      }
    }

    &_caption {
      display: block;
      line-height: 1.6;

      @include g.mq('md') {
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }
  &.is_reverse .un_grid_image {
    @include g.mq('md') {
      order: 1;
    }
  }
}

// 最小見出し
.un_smallTitle {
  padding-left: 10px;
  margin-block: calc((1em - 1lh) / 2);
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight:700;
  border-left: 3px solid g.color('key');
  color: g.color('key');

  @include g.mq('md') {
    font-size: 1.8rem;
  }
}

// エコツアー画像
.un_ecoTourImage {
  display: block;
  width: 100%;
  margin-inline: auto;
  border-radius: 10px;
  overflow: hidden;

  @include g.mq('md') {
    width: 60%;
    border-radius: 30px;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

// youtube埋め込み
.un_iframe {
  margin-top: 10px;
  
  iframe {
    display: block;
    width: 100%;
    aspect-ratio: 16/9;
  }
}

// 注釈
.un_annotation {
  padding: 8px 15px;
  background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
  border-radius: 6px;
  color: g.color('key');
  line-height: 1.8;
  font-size: 1.2rem;

  @include g.mq('md') {
    padding: 10px 20px;
    font-size: 1.4rem;
  }
}

}