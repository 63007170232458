/**************************************************************************
    情報セキュリティポリシー
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#policy.security {

    // メインビジュアル
    .bl_mvSub {
        background-image: url(../img/security/mv.webp);
        height: 300px;

        @include g.mq('md') {
            height: 436px;
        }
    }

    // コンテンツ
    .un_sec {
        margin: 70px 0 120px;

        p {
            text-align: left;
        }

        @include g.mq('md') {
            margin: 75px auto 120px;
        }

        &_list {
            counter-reset: number;
        }

        &_item {
            margin-bottom: 52px;
        }

        &_tit {
            counter-increment: number;
            display: flex;
            gap: .2em;
            color: g.color('key');
            font-size: 1.6rem;
            line-height: 1.8;
            margin-bottom: 6px;

            @include g.mq('md') {
                font-size: 1.8rem;
            }

            &:before {
                content: counter(number) ".";
            }
        }

    }
}