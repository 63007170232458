/**************************************************************************
    視点案内
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#branch {

// メインビジュアル
.bl_mvSub {
    background-image: url(../img/company/branch/mv.webp);

    .el_title_icon {
        width: 42px;

        @include g.mq('md') {
            width: 52px;
        }

        .branch {
            fill: g.color('white');
        }
    }
}

// リード文
.un_leadText {
    font-size: 1.6rem;
    font-weight: bold;

    @include g.mq('md') {
        font-size: 1.8rem;
        text-align: center;
    }
}

// テキストボックス
.un_textBox {
    &_text {
        font-size: 1.6rem;
        
        @include g.mq('md') {
            font-size: 1.8rem;
            text-align: center;
        }
    }

    &_list {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }

    &_item {
        display: flex;
    }
}

// ページナビ
.un_pageNav {
    &_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        max-width: g.$inner_width_small;
        margin-inline: auto;
        
        @include g.mq('md') {
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }
    }

    &_item {
        a {
            position: relative;
            display: grid;
            place-content: center start;
            height: 50px;
            padding: 0 25px 0 15px;
            line-height: 1.2;
            color: g.color('key');
            border: 1px solid g.color('key');
            
            @include g.mq('md') {
                padding: 0 20px;
                height: 70px;
            }
    
            &::before {
                @include g.arrow('right', 6px, 2px, g.color('key'));
                content: '';
                position: absolute;
                inset: 0 10px 0 auto;
                margin: auto;
    
                @include g.mq('md') {
                    right: 20px;
                    font-size: 8px;
                }
            }
        }
    }
}

// ページタイトル
.un_pageTitle {
    position: relative;
    padding-bottom: 10px;
    font-size: 3rem;
    font-weight: bold;
    line-height: 2;
    text-align: center;
    
    @include g.mq('md') {
        font-size: 4rem;
    }

    &::before {
        content: '';
        position: absolute;
        inset: auto auto 0 50%;
        translate: -50%;
        width: 50px;
        height: 3px;
        background-color: g.color('key');

        @include g.mq('md') {
            width: 75px;
            height: 4px;
        }
    }
}


// タイトル
.un_title {
    padding-left: 10px;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 2;
    border-left: 3px solid g.color('key');
    
    @include g.mq('md') {
        padding-left: 20px;
        font-size: 3.5rem;
        border-left-width: 4px;
    }
}

// サブタイトル
.un_subtitle {
    font-size: 2rem;
    font-weight: bold;

    @include g.mq('md') {
        font-size: 2.4rem;
    }
}

// 支店データ
.branchData {
    &_row {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 5px;
        
        @include g.mq('md') {
            grid-template-columns: 180px 1fr;
            gap: 10px;
        }

        &:not(:first-of-type) {
            margin-top: 10px;
        }

        dt, dd {
            padding: 10px;
            line-height: 1.5;
            
            @include g.mq('md') {
                padding: 15px;
            }
        }

        dt {
            background-color: g.color('bg');
        }

        dd {
            a {
                color: g.color('key');
                text-decoration: underline;

                &[href*="tel:"] {
                    @include g.mq('md') {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// ボタン
.un_btn {
    position: relative;
    display: grid;
    place-content: center start;
    height: 40px;
    padding: 0 20px;
    line-height: 1.2;
    color: g.color('key');
    border: 1px solid g.color('key');

    @include g.mq('md') {
        height: 50px;
        padding: 0 40px 0 30px;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 0 10px 0 auto;
        margin: auto;

        @include g.mq('md') {
            right: 20px;
        }
    }

    &:not(.is_pdf) {
        &::before {
            @include g.arrow('right', 6px, 2px, g.color('key'));
        }
    }

    &.is_pdf {
        &::before {
            width: 16px;
            height: 21px;
            font-size: 16px;
            background: url('../img/common/icon_pdf.svg') no-repeat border-box center/cover;

            @include g.mq('md') {
                width: 18px;
                height: 23px;
            }
        }
    }
}

}
