/**************************************************************************
    カウンター店舗
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#branch.counter {

// お知らせ
.un_lead {
    margin-top: 50px;
    
    @include g.mq('md') {
        margin-top: 75px;
    }

    .un_pageNav {
        margin-top: 50px;
        
        @include g.mq('md') {
            margin-top: 75px;
        }
    }

    .un_pageTitle {
        margin-top: 50px;
        
        @include g.mq('md') {
            margin-top: 75px;
        }
    }

    .un_textBox {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }
}

// ボタンリスト
.un_btnList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: g.$inner_width_small;
    margin-inline: auto;

    @include g.mq('lg') {
        grid-template-columns: repeat(2, 1fr);
    }
}

// コンテンツ
.un_contents {
    padding: 50px 0;
    margin: 30px 0 50px;

    @include g.mq('md') {
        padding: 75px 0 120px;
        margin: 50px 0 150px;
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        margin-top: 30px;

        @include g.mq('md') {
            gap: 50px;
            margin-top: 50px;
        }
    }
}

// コンテンツ中身
.un_contentsItem {
    padding: 50px 20px;

    @include g.mq('md') {
        padding: 77px 70px 108px;
    }

    &_row {
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: 350px 1fr;
            gap: 20px;
            margin-top: 20px;
        }
    }

    &_col {
        margin-top: 10px;
        
        @include g.mq('md') {
            margin-top: 20px;
        }

        @include g.mq('lg') {
            margin-top: 0;
        }
    }

    &_img {
        text-align: center;
    }
}

// QRコード
.un_qrCode {
    margin-top: 20px;

    &_title {
        max-width: 450px;
        padding: 5px;
        margin-inline: auto;
        font-weight: bold;
        text-align: center;
        background-color: g.color('bg');
    }

    &_img {
        margin-top: 10px;
        text-align: center;
    }

    &_text {
        font-size: 1.4rem;
        line-height: 1.5;

        @include g.mq('xl') {
            text-align: center;
        }
    }
}

// コンテンツ下部
.un_itemBottom {
    max-width: g.$inner_width_small;
    margin-inline: auto;
    margin-top: 20px;

    @include g.mq('md') {
        margin-top: 30px;
    }

    &_notice {
        padding: 20px;
        background-color: rgba(g.color('accent'), 0.15);

        @include g.mq('md') {
            padding: 20px 30px;
        }
    }

    &_logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        max-width: 700px;
        padding: 15px 10px 10px;
        margin-top: 20px;
        margin-inline: auto;
        border: 1px solid g.color('border');

        @include g.mq('md') {
            flex-direction: row;
            gap: 20px;
            padding: 20px;
            margin-top: 30px;
        }
    }

    .un_btnList {
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }
}

}
