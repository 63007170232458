/*------------------------------------------------------------------------
	タイトル
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_title {
	$this: &;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&_en {
		font-size: 3.5rem;
		font-weight: bold;
		letter-spacing: 0.02em;
		line-height: math.div(74, 69);
		
		@include g.mq('md') {
			font-size: 6.9rem;
			line-height: 1.2;
		}
	}

	&_ja {
		font-size: 1.5rem;
		font-weight: normal;
		letter-spacing: 0;
		
		@include g.mq('sm') {
			width: 100%;
		}
		
		@include g.mq('md') {
			margin: 0 0 -35px 17px;
			font-size: 2rem;
		}

		// 日本語大きい
		&.is_large {
			margin-bottom: -15px;
			font-weight: 500;
			
			@include g.mq('md') {
				font-size: 2.6rem;
			}
		}

		// 日本語のみ縦積み
		&.is_w_100 {
			width: 100%;
			margin: 0;
		}
	}

	&_icon {
		margin-right: 5px;

		@include g.mq('md') {
			margin-right: 15px;
		}
	}

	// 中央寄せ
	&.is_center {
		justify-content: center;
		text-align: center;
	}

	// 大きい
	&.is_large {
		#{$this}_en {
			font-size: 4.1rem;

			@include g.mq('md') {
				font-size: 7.5rem;
			}
		}

		#{$this}_ja {
			font-size: 1.6rem;

			@include g.mq('md') {
				font-size: 3rem;
			}
		}
	}

	// 小さい
	&.is_small {
		#{$this}_en {
			@include g.mq('md') {
				font-size: 4.7rem;
				line-height: math.div(51, 47);
			}
		}

		#{$this}_ja {
			@include g.mq('md') {
				margin-top: 15px;
				font-size: 1.7rem;
				font-weight: 500;
				line-height: math.div(31, 17);
			}
		}

		&:not(.is_block) {
			#{$this}_ja:not(.is_w_100) {
				@include g.mq('md') {
					margin-top: -15px;
				}
			}
		}
	}

	// 縦積み
	&.is_block {
		flex-direction: column;
		text-align: center;

		// 左寄せ
		&.is_left {
			align-items: flex-start;
			text-align: left;
		}

		#{$this}_ja {
			margin: 0;
		}

		#{$this}_icon {
			margin: 0;
		}
	}
}


