/*------------------------------------------------------------------------
    セクション
------------------------------------------------------------------------*/
@use "../../global" as g;

.ly_section {
	padding: 30px 0 50px;

    @include g.mq('md') {
        padding: 50px 0 75px;
    }
}