/*------------------------------------------------------------------------
    テーブル
------------------------------------------------------------------------*/
@use "../../global" as g;

.bl_table {
    width: 100%;
    border: 1px solid g.color('border');
    border-collapse: collapse;

    th, td {
        padding: 10px 20px;
        
        @include g.mq('sm') {
            display: block;
        }
        
        @include g.mq('md') {
            border: 1px solid g.color('border');
        }
    }
    
    th {
        color: #fff;
        background-color: g.color('accent');
        
        @include g.mq('md') {
            width: 30%;
        }
    }
    
    td {
        @include g.mq('sm') {
            border-top: 1px solid g.color('border');
        }

        @include g.mq('md') {
            width: 70%;
        }
    }
}