/**************************************************************************
    事業紹介　トップ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#business.top {

// テキスト
.un_text {
    font-size: 1.6rem;
    line-height: math.div(40, 18);

    @include g.mq('md') {
        font-size: 1.8rem;
    }

    &.is_center {
        text-align: center;
    }
}

// リンク
.un_link {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-top: 2px;

    &::before {
        content: '';
        width: 12px;
        height: 12px;
        background: url('../img/common/icon_tri_arrow_key.svg') no-repeat border-box center/contain;
    }
}

// メインビジュアル
.bl_mvSub {
    // background-image: url('../img/business/top/mv_sp.webp');
    background-image: url('../img/business/top/mv_pc.webp');

   /*  @include g.mq('md') {
    } */

    .el_title_icon {
        width: 50px;

        @include g.mq('md') {
            width: 69px;
        }

        .business {
            fill: g.color('white');
        }
    }
}

// タブ切り替え
.bl_tab {
    margin-top: 50px;

    @include g.mq('md') {
        margin-top: 83px;
    }
}

// サービス情報を探す
.un_info {
    margin-top: 50px;

    @include g.mq('md') {
        margin-top: 95px;
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 120px;
        margin-top: 100px;
        
        @include g.mq('md') {
            gap: 148px;
            margin-top: 81px;
        }
        
        @include g.mq('lg') {
            gap: 198px;
            margin-top:131px;    
        }
    }
}

.un_infoItem {
    $this: '.un_infoItem';
    width: 100vw;
    margin-inline: calc(50% - 50vw);
    color: g.color('white');

    @include g.mq('lg') {
        display: flex;
    }

    &_contents {
        @include g.mq('lg') {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }

    &_head {
        position: relative;
        width: g.$inner_width_sp;
        margin-inline: auto;
        
        @include g.mq('md') {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px 0;
        }
        
        @include g.mq('lg') {
            width: 100%;
            margin-inline: 0;
        }
    }

    &_title {
        display: flex;
        align-items: center;

        &_en {
            position: absolute;
            top: -70px;
            left: 0;
            z-index: -1;
            
            span {
                font-size: 7rem;
                font-weight: bold;
                line-height: 1;
                opacity: 0.08;
                background-clip: text;
                -webkit-text-fill-color: transparent;
    
                @include g.mq('md') {
                    font-size: 13.5rem;
                }
            }
        }

        &_icon {
            margin-right: 15px;

            @include g.mq('sm') {
                width: 60px;
            }
        }

        &_ja {
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.3;
            letter-spacing: 0;
            color: g.color('key');
            
            @include g.mq('md') {
                font-size: 3.5rem;
                letter-spacing: 0.08em;
            }
        }
    }

    &_body {
        padding: 40px g.get-inner-side(g.$inner_width_sp) 50px;
        margin-top: 20px;
        
        @include g.mq('md') {
            margin-top: 30px;
        }
        
        @include g.mq('lg') {
            flex: 1;
            width: calc(100% + 95px);
            min-height: 365px;
            padding: 40px 0 30px;
        }

        &.is_purpose {
            min-height: 0;
            padding-bottom: 60px;
        }
    }

    &_tagList {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 20px;

        @include g.mq('md') {
            margin-top: 30px;
        }
    }

    .un_link {
        margin-left: auto;
    }

    &_img {
        position: relative;
        z-index: 1;
        width: g.$inner_width_sp;
        margin-inline: auto;
        margin-top: -20px;

        @include g.mq('lg') {
            width: min(percentage(math.div(960, 1920)), 960px);
            margin-top: 0;
        }

        img {
            @include g.mq('lg') {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    // 奇数番目
    &:not(.is_even) {
        flex-direction: row-reverse;

        #{$this}_head {
            @include g.mq('lg') {
                padding-left: 61px;
            }
        }

        #{$this}_body {
            @include g.mq('lg') {
                padding-left: 130px;
                padding-right: 70px;
                margin-left: -95px;
                border-radius: 0 0 0 15px;
            }
            
            @include g.mq('xl') {
                padding-left: 175px;
                padding-right: calc((100vw - g.$inner_width) / 2);
                margin-right: 95px;
            }
        }
    }
    
    // 偶数番目
    &.is_even {        
        #{$this}_head {
            @include g.mq('lg') {
                padding-left: 61px;
            }
            
            @include g.mq('xl') {
                padding-left: calc((100vw - g.$inner_width) / 2);
            }
        }

        #{$this}_title_en {
            @include g.mq('lg') {
                padding-left: 20px;
            }
        }

        #{$this}_body {
            @include g.mq('lg') {
                padding-right: 130px;
                padding-left: 70px;
                margin-right: -95px;
                border-radius: 0 0 15px 0;
            }
            
            @include g.mq('xl') {
                padding-right: 175px;
                padding-left: calc((100vw - g.$inner_width) / 2);
            }
        }
    }

    // タイプ別設定
    $infoList: (
        // 法人企業団体向け
        specialization: (
            top: -128px, //md以上の英字の位置
            color: linear-gradient(135deg, rgba(255,226,7,1) 0%, rgba(0,138,231,1) 100%), //英字の色
            ),
        // 学校教育機関向け
        educational: (
            top: -166px,
            color: linear-gradient(120deg, rgba(255,162,0,1) 0%, rgba(255,72,121,1) 100%),
        ),
        // 官公庁・自治体向け
        local: (
            top: -162px,
            color: linear-gradient(135deg, rgba(74,176,255,1) 0%, rgba(0,0,153,1) 100%),
        ),
    );
    @each $key, $val in $infoList {
        &.is_#{$key} {
            #{$this}_title_en {
                span {
                    background-image: g.map-deep-get($val, color);
                }
                
                @include g.mq('md') {
                    top: g.map-deep-get($val, top);
                }
            }

            #{$this}_body {
                background-image: g.map-deep-get($val, color);
            }
        }
    }

    &.is_educational {
        #{$this}_img {
            img {
                @include g.mq('lg') {
                    object-position: center right;
                }
            }
        }
    }
}

.un_infoPopup {
    &_trigger {
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 5px;
        margin-top: 10px;
        margin-left: auto;
        padding: 0 20px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6;
        color: g.color('key');
        border: 1px solid g.color('key');
        border-radius: 100vmax;

        @include g.mq('md') {
            margin: 0 0 0 25px;
            font-size: 2rem;
        }

        &::before {
            content: '';
            width: 12px;
            height: 12px;
            background: url('../img/common/icon_tri_arrow_key.svg') no-repeat border-box center/contain;
        }
    }

    &_body {
        margin-inline: auto;
        text-align: center;
        color: g.color('white');
    }
}

// 課題・目的からサービスを探す
.un_purpose {
    &_area {
        margin-top: 50px;

        @include g.mq('md') {
            margin-top: 75px;
        }
        
        @include g.mq('lg') {
            margin-top: 125px;
        }
    }

    &_row {
        &:not(:first-of-type) {
            margin-top: 120px;

            @include g.mq('md') {
                margin-top: 148px;
            }
            
            @include g.mq('lg') {
                margin-top: 198px;
            }
        }
    }
}

.un_accordionList {
    max-width: g.$inner_width_small;
    margin-top: 50px;
    margin-inline: auto;

    @include g.mq('md') {
        margin-top: 75px;
    }
}

.un_purposeList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include g.mq('md') {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 50px;
    }

    &_item {
        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
        }
    }

    &_icon {
        width: 24px;
        aspect-ratio: 1/1;

        @include g.mq('md') {
            width: 32px;
        }

        &_arrow {
            fill: none;
            stroke-miterlimit: 10;
            stroke: g.color('key');
        }
    }
}

// その他事業
.un_others {
    padding: 75px 0 50px;

    @include g.mq('md') {
        padding: 128px 0 88px;
    }
    
    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
        
        @include g.mq('md') {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
        
        @include g.mq('lg') {
            gap: 57px;
        }
    }
}

.un_othersItem {
    @include g.mq('md') {
        display: flex;
        flex-direction: column;
    }

    &_img {
        img {
            width: 100%;
        }
    }

    &_textArea {
        width: percentage(math.div(555, 610));
        margin-top: -28px;
        margin-inline: auto;

        @include g.mq('md') {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }

    &_title {
        position: relative;
        height: 50px;
        padding: 0 25px;
        color: g.color('white');
        background-color: g.color('key');
        border-radius: 10px 10px 0 0;
        
        @include g.mq('lg') {
            height: 70px;
            padding: 0 35px;
        }

        &_ja {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 10px;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.2;

            @include g.mq('lg') {
                font-size: 2.4rem;
            }

            &::before {
                content: '';
                width: 4px;
                height: 26px;
                background-color: g.color('accent_2');
                border-radius: 4px;

                @include g.mq('md') {
                    height: 33px;
                }
            }
        }

        &_en {
            position: absolute;
            right: 12px;
            bottom: 6px;
            font-size: 3.3rem;
            font-weight: bold;
            line-height: 1;
            letter-spacing: 0.02em;
            opacity: 0.1;

            @include g.mq('lg') {
                font-size: 4.6rem;
            }
        }
    }

    &_body {
        padding: 20px 28px 25px;
        background-color: g.color('white');

        @include g.mq('md') {
            flex: 1;
        }
    }

    &_tagList {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .un_link {
        margin-top: 10px;
        margin-left: auto;

        @include g.mq('md') {
            margin-top: 20px;
        }
    }
}

// PICK UP
.un_pickUp {
    position: relative;
    padding: 50px 0;
    background: linear-gradient(45deg, #00d3ff 0%, #000099 100%);

    @include g.mq('md') {
        padding: 80px 0 78px;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 0;
        mix-blend-mode: screen;
        background: url('../img/business/top/pickup_bg.webp') no-repeat border-box center/cover;
    }

    &_inner {
        position: relative;
        z-index: 1;
    }

    &_list {
        padding-left: g.get-inner-side(g.$inner_width_sp);
        margin-top: 25px;

        @include g.mq('md') {
            margin-top: 48px;
        }

        @include g.mq('xl') {
            padding-left: g.get-inner-side();
        }
    }

    // ナビゲーション
    &_arrow {
        $pos_x: 15px;
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        z-index: 1;
        $_size: 50px;
        width: $_size;
        height: $_size;
        background: url('../img/common/icon_slider_arrow.svg') no-repeat border-box center/cover;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: 0.2s;
    
        @include g.mq('md') {
            $_size: 80px;
            width: $_size;
            height: $_size;
        }

        &:hover {
            @include g.mq('md') {
                opacity: 0.7;
            }
        }

        &.is_next {
            right: $pos_x;
        }
    
        &.is_prev {
            left: $pos_x;
            rotate: 180deg;
        }

        &.swiper-button-disabled {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.un_pickUpItem {
    width: 280px;
    background-color: g.color('white');
    border-radius: 10px;
    
    @include g.mq('md') {
        width: 351px;
    }

    > a {
        display: block;
    }

    &_head {
        position: relative;
        padding: 0 15px;
        border-bottom: 1px solid #ebeff6;
        
        @include g.mq('md') {
            padding: 0 37px;
        }
    }

    &_title {
        display: flex;
        align-items: center;
        min-height: 70px; //3行分
        line-height: 1.4;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0;
        color: g.color('key');
        
        @include g.mq('md') {
            min-height: 108px; //3行分
            font-size: 2.2rem;
        }
    }

    &_num {
        position: absolute;
        bottom: 0;
        right: 9px;
        font-family: g.font('en_2');
        font-size: 5rem;
        font-weight: 100;
        line-height: 0.75;
        letter-spacing: 0;
        color: rgba(g.color('key'), 0.2);

        @include g.mq('md') {
            font-size: 7.5rem;
        }
    }

    &_body {
        padding: 10px 15px 20px;

        @include g.mq('md') {
            padding: 10px 37px 30px;
        }
    }

    &_text {
        min-height: 77px; //3行分
        font-size: 1.4rem;
        line-height: math.div(31.1811, 17);
        
        @include g.mq('md') {
            min-height: 94px; //3行分
            font-size: 1.7rem;
        }
    }

    &_img {
        margin-top: 20px;
        text-align: center;
    }
}

}