/**************************************************************************
    会社情報　3分でわかる東武トップツアーズ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.digest {

/*------------------------------------------------------------------------
  メインビジュアル
------------------------------------------------------------------------*/
.bl_mvSub {
  background-image: url('../img/company/digest/mv.webp');

  .el_title_icon {
    @include g.mq('sm') {
      width: 40px;
    }
  }
}

/*------------------------------------------------------------------------
  アンカーリンク
------------------------------------------------------------------------*/
.bl_anchorLink {
  margin-top: 50px;
  margin-bottom: 40px;

  @include g.mq('md') {
    margin-top: 85px;
    margin-bottom: 54px;
  }

  &_item {
    span {
      line-height: 1.2;
    }
  }
}

/*------------------------------------------------------------------------
  縦積みレイアウト
------------------------------------------------------------------------*/
.un_stack {
  > * + * {
    margin-top: 1em;
  }
}

/*------------------------------------------------------------------------
  アイコン付きリンク
------------------------------------------------------------------------*/
.un_link {
  display: inline-flex;
  align-items: center;
}

.un_link_icon {
  display: inline-block;
  width: 29px;
  aspect-ratio: 1/1;
  margin-left: 10px;
  border: 1px solid currentColor;
  border-radius: 50%;

  .triArrow {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

/*------------------------------------------------------------------------
  大きいボタン
------------------------------------------------------------------------*/
.un_largeBtnWrapper {
  @include g.mq('md') {
    display: flex;
    justify-content: space-between;
  }

  @include g.mq('lg') {
    display: block;
  }

  > * + * {
    margin-top: 15px;

    @include g.mq('md') {
      margin-top: 0;
    }

    @include g.mq('lg') {
      margin-top: 20px;
    }

    @include g.mq('xl') {
      margin-top: 26px;
    }
  }
}

.un_largeBtn {
  position: relative;
  display: block;
  padding: 20px 60px 20px 20px;
  background-color: #fff;
  border-radius: 7px;
  line-height: 1.5;

  @include g.mq('md') {
    width: 48%;
  }

  @include g.mq('lg') {
    width: auto;
    padding: 30px 70px 30px 40px;
  }

  .el_circleArrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.un_largeBtn_label {
  display: block;
  margin: 0 0 5px;
  color: g.color('key');
  font-weight: bold;
  font-size: 1.8rem;

  @include g.mq('md') {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }

  @include g.mq('lg') {
    font-size: 2.4rem;
  }

  @include g.mq('xl') {
    font-size: 3rem;
  }
}

.un_largeBtn_text {
  display: block;
  color: g.color('base');
}



/*------------------------------------------------------------------------
  ページタイトル
------------------------------------------------------------------------*/
.un_pageTitle {
  width: 100%;
  max-width: 90%;
  aspect-ratio: 566/346;
  margin: 0 auto;

  @include g.mq('md') {
    width: 566px;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

/*------------------------------------------------------------------------
  経営理念
------------------------------------------------------------------------*/
.un_philosophy {
  position: relative;
  max-width: 1920px;
  margin: 0 auto 40px;

  @include g.mq('md') {
    margin-bottom: 54px;
  }

  @include g.mq('lg') {
    display: grid;
    grid-template-columns: 1fr 440px 1fr;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      aspect-ratio: 1920/61;
      background: url('../img/company/digest/philosophy_mask.svg') no-repeat border-box center/contain;
    }
    &::before {
      top: -1px;
    }
    &::after {
      bottom: -1px;
      rotate: 180deg;
    }
  }

  @include g.mq('xl') {
    grid-template-columns: 1fr 640px 1fr;
  }
}

// イメージ画像
.un_philosophy_image {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}

// コンテンツ
.un_philosophy_contents {
  padding: 20px;

  @include g.mq('md') {
    padding: 30px 20px;
  }

  @include g.mq('lg') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}

// メインコンテンツ
.un_philosophy_main {
  z-index: 1;
  position: relative;
  width: 300px;
  padding: 30px 0 60px;
  margin: 0 auto 10px;
  text-align: center;
  line-height: 1.4;
  color: g.color('key');

  @include g.mq('md') {
    width: 400px;
    padding: 40px 0 50px;
    margin-bottom: 30px;
  }

  @include g.mq('lg') {
    width: 380px;
  }

  @include g.mq('xl') {
    width: 535px;
    padding: 50px 0 80px;
    margin-bottom: 50px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: -1;
    height: 100%;
    aspect-ratio: 1/1;
    background: url('../img/company/digest/philosophy_circle.svg') no-repeat border-box center/contain;
    animation: rotation 15s linear infinite;

    @keyframes rotation {
      100% {
        rotate: 360deg;
      }
    }
  }
}

// 見出し
.un_philosophy_title {
  margin-bottom: 20px;

  @include g.mq('md') {
    margin-bottom: 30px;
  }

  @include g.mq('xl') {
    margin-bottom: 40px;
  }
}

// 見出し > 英語
.un_philosophy_title_en {
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;

  @include g.mq('md') {
    font-size: 1.6rem;
  }

  @include g.mq('xl') {
    font-size: 2rem;
  }
}

// 見出し > 日本語
.un_philosophy_title_ja {
  text-align: center;
  font-weight: bold;
  font-size: 2.2rem;

  @include g.mq('md') {
    font-size: 3rem;
  }

  @include g.mq('xl') {
    font-size: 4.2rem;
  }
}

// コピー
.un_philosophy_copy {
  text-align: center;
}

// コピー > メイン
.un_philosophy_copy_main {
  display: block;
  width: 100%;
  margin-bottom: 1.2rem;

  @include g.mq('md') {
    margin-bottom: 1.6rem;
  }

  @include g.mq('xl') {
    margin-bottom: 2rem;
  }
}

// コピー > サブ
.un_philosophy_copy_sub {
  display: block;
  font-size: 1.8rem;
  font-weight: 200;

  @include g.mq('md') {
    font-size: 2.6rem;
  }

  @include g.mq('xl') {
    font-size: 3.1rem;
  }
}

// ボタンラッパー
.un_philosophy_btnBox {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

/*------------------------------------------------------------------------
  数字で見る東武トップツアーズ（ヘッダー）
------------------------------------------------------------------------*/
.un_dataHeader {
  background: url('../img/company/digest/data_header_bg_sp.webp') no-repeat border-box top/cover;
  padding: 40px 0 30px;

  @include g.mq('md') {
    background: url('../img/company/digest/data_header_bg_pc.webp') no-repeat border-box top/cover;
    padding: 80px 0 70px;
  }

  @include g.mq('lg') {
    padding: 135px 0 103px;
  }
}

// 見出し
.un_dataHeader_title {
  margin: 0 0 20px;
  text-align: center;
  line-height: 1.5;
  font-weight: bold;
  font-size: 2.2rem;

  @include g.mq('md') {
    font-size: 4rem;
  }

  @include g.mq('lg') {
    margin: 0 0 30px;
    font-size: 5.5rem;
  }

  span {
    color: g.color('key');
    font-size: 2.5rem;

    @include g.mq('md') {
      font-size: 5rem;
    }

    @include g.mq('lg') {
      font-size: 6.3rem;
    }
  }
}

// ページ内リンク
.un_dataHeader_nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 6px;

  @include g.mq('md') {
    gap: 10px;
  }

  @include g.mq('lg') {
    gap: 20px;
  }
}

// ページ内リンク > ボタン
.un_dataHeader_nav_btn {
  position: relative;
  display: inline-block;
  width: calc(50% - 3px);
  padding: 13px 30px 13px 18px;
  border-radius: 999px;
  box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);
  background-color: #fff;
  line-height: 1.5;
  font-size: 1.4rem;

  @include g.mq('md') {
    width: auto;
    padding: 13px 42px 13px 20px;
  }

  @include g.mq('lg') {
    padding: 17px 50px 17px 30px;
    font-size: 1.7rem;
  }
}

// ページ内リンク > ボタン > アイコン
.un_dataHeader_nav_btn_icon {
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 5px;
  width: 32px;
  aspect-ratio: 1/1;
  rotate: 90deg;
  translate: 0 -50%;

  @include g.mq('md') {
    right: 10px;
  }

  @include g.mq('lg') {
    right: 15px;
  }

  .triArrow {
    fill: none;
    stroke: #00ab80;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

/*------------------------------------------------------------------------
  数字で見る東武トップツアーズ - セクション
------------------------------------------------------------------------*/
.un_dataSection {
  position: relative;
  padding: 40px 0 50px;

  @include g.mq('md') {
    padding: 80px 0 100px;
  }

  @include g.mq('lg') {
    padding: 124px 0 136px;
  }

  .ly_inner {
    max-width: g.$inner_width_medium;
  }
}

// 見出し
.un_dataSection_title {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: center;

  @include g.mq('md') {
    margin-bottom: 40px;
  }
}

// 見出し > 英語
.un_dataSection_title_en {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  background-image: linear-gradient(45deg, #4ab0ff 0%, #000099 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include g.mq('md') {
    margin-bottom: 10px;
    font-size: 2rem;
  }

  &.is_white {
    background: none;
    color: #fff;
    -webkit-text-fill-color: initial;
  }
}

// 見出し > 日本語
.un_dataSection_title_ja {
  font-size: 2rem;

  @include g.mq('md') {
    font-size: 3.7rem;
  }

  &.is_white {
    color: #fff;
  }

  &.is_key {
    color: g.color('key');
  }
}

// 内容
.un_dataSection.is_white .bl_data {
  color: #fff;
}
.un_dataSection.is_key .bl_data {
  color: g.color('key');
}

// イメージ画像
.un_dataSection_image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &.is_aboutus {
    img {
      object-position: left center;
    }
  }
}

/*------------------------------------------------------------------------
  数字で見る東武トップツアーズ - 国内・海外拠点
------------------------------------------------------------------------*/
.un_baseWrapper {
  position: relative;
}

// 単体枠
.un_base {
  position: relative;
  max-width: 400px;
  margin: 0 auto 40px;

  @include g.mq('md') {
    max-width: 600px;
  }

  @include g.mq('lg') {
    max-width: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
// 単体枠（モディファイア：国内）
.un_base.is_domestic {
  @include g.mq('lg') {
    margin: 0 auto;
  }
}
// 単体枠（モディファイア：海外）
.un_base.is_overseas {
  @include g.mq('lg') {
    width: 540px;
    margin: 0 0 0 auto;
  }

  @include g.mq('xl') {
    margin-top: -100px;
  }
}

// 拠点画像
.un_base_image {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}
// 拠点画像（モディファイア：国内）
.un_base.is_domestic .un_base_image {
  @include g.mq('lg') {
    width: 77.25%;
    margin-left: 5.5%;
  }
}
// 拠点画像（モディファイア：海外）
.un_base.is_overseas .un_base_image {
  @include g.mq('lg') {
    position: absolute;
    top: 0;
    right: 0;
    width: 312px;
  }
}

// 内容
.un_base_contents {
  position: relative;
  line-height: 1.5;
  text-align: center;
}
// 内容（モディファイア：国内）
.un_base.is_domestic .un_base_contents {
  @include g.mq('lg') {
    position: absolute;
    bottom: 45.7%;
    right: 0;
    width: 26%;
    padding: 0;
    min-width: 260px;
    text-align: right;
  }
}
// 内容（モディファイア：海外）
.un_base.is_overseas .un_base_contents {
  @include g.mq('lg') {
    padding: 0 0 0 15px;
    text-align: left;
    border-left: 1px solid g.color('key');
  }
}

// タイトル
.un_base_title {
  margin: 0 0 10px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;

  @include g.mq('md') {
    font-size: 2.2rem;
  }

  span {
    color: g.color('key');
  }
}
// タイトル（モディファイア：国内）
.un_base.is_domestic .un_base_title {
  @include g.mq('lg') {
    position: relative;
    padding-bottom: 10px;
    text-align: right;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -50px;
      display: block;
      width: 50px;
      height: 1px;
      background-color: g.color('key');
      rotate: -45deg;
      transform-origin: right center;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: g.color('key');
    }
  }
}
// タイトル（モディファイア：海外）
.un_base.is_overseas .un_base_title {
  @include g.mq('lg') {
    text-align: left;
  }
}

// テキスト
.un_base_text {
  margin: 0 0 10px;
  font-size: 1.1rem;

  @include g.mq('md') {
    font-size: 1.4rem;
  }
}
// タイトル（モディファイア：海外）
.un_base.is_overseas .un_base_text {
  @include g.mq('lg') {
    width: 320px;
  }
}

// リンク
.un_base_link {
  display: inline-flex;
  align-items: center;
  color: g.color('key');

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

// リンク > アイコン
.un_base_link_icon {
  display: inline-block;
  width: 24px;
  aspect-ratio: 1/1;

  .triArrow {
    fill: none;
    stroke: g.color('key');
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

/*------------------------------------------------------------------------
  従来の旅行業の枠にとらわれない〜（ヘッダー）
------------------------------------------------------------------------*/
.un_serviceHeader {
  position: relative;
  padding: 40px 0 80px;
  color: #fff;

  @include g.mq('md') {
    padding: 80px 0 150px;
  }

  @include g.mq('lg') {
    padding: 120px 0 200px;
  }

  @include g.mq('xl') {
    padding: 230px 0 280px;
  }

  .ly_inner {
    max-width: g.$inner_width_medium;
  }
}

.un_serviceHeader_grid {
  z-index: 1;
  position: relative;

  @include g.mq('lg') {
    display: grid;
    grid-template-columns: 55% 1fr;
    gap: 20px;
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('lg') {
      margin-top: 0;
    }
  }

}

.un_serviceHeader_title {
}

.un_serviceHeader_title_en {
  margin: 0 0 10px;
}

.un_serviceHeader_title_ja {
  font-size: 2rem;
  line-height: 1.75;

  @include g.mq('md') {
    font-size: 3rem;
  }

  @include g.mq('xl') {
    font-size: 4.5rem;
  }
}

.un_serviceHeader_body {
  @include g.mq('lg') {
    padding-top: 2em;
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('lg') {
      margin-top: 40px;
    }
  }
}

// イメージ画像
.un_serviceHeader_image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------------------------------------------------------
  サービス＆サステナ用の汎用セクション
------------------------------------------------------------------------*/
.un_section_wrapper {
  padding: 40px 0 50px;
  background: url(../img/company/digest/service_bg_sp.webp) repeat-y;
  background-size: 100% auto;

  @include g.mq('md') {
    padding: 80px 0 100px;
    background-image: url(../img/company/digest/service_bg_pc.webp);
  }

  @include g.mq('lg') {
    padding: 120px 0 200px;
  }

  &.is_paddingTop0 {
    @include g.mq('lg') {
      padding-top: 0;
    }
  }

  > * + * {
    margin-top: 40px;

    @include g.mq('md') {
      margin-top: 80px;
    }

    @include g.mq('lg') {
      margin-top: 154px;
    }
  }
}

// セクション
.un_section {}

// カラムレイアウト
.un_section_row {
  width: g.$inner_width_sp;
	max-width: 1920px;
	margin: 0 auto;

  @include g.mq('lg') {
    display: flex;
    width: 100%;
    gap: 20px;
  }
}
.un_section_col {
  @include g.mq('lg') {
    width: 50%;

    &.is_items_center {
      display: flex;
      align-items: center;
    }
  }

  &:nth-child(1) {
    @include g.mq('lg') {
      padding-top: 40px;
    }

    @include g.mq('xl') {
      padding-top: 60px;
    }

    @media (min-width: 1920px) {
      padding-top: 80px;
    }
  }
}
.un_section.is_reverse .un_section_col {
  @include g.mq('lg') {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
}

// タイトル
.un_section_title {
  margin: 0 0 30px;

  @include g.mq('lg') {
    padding: 0 130px 0 30px;
    margin-bottom: 40px;
  }

  @include g.mq('xl') {
    width: calc(100% - (100vw - #{g.$inner_width}) / 2);
    margin: 0 0 80px auto;
  }

  @media (min-width: 1920px) {
    width: calc(100% - (1920px - #{g.$inner_width}) / 2);
    margin-bottom: 100px;
  }
}
.un_section.is_reverse .un_section_title {
  @include g.mq('lg') {
    width: 100%;
    padding-right: 20px;
    padding-left: 130px;
    margin-left: 0;
  }

  @include g.mq('xl') {
    padding-left: 150px;
  }
}

// タイトル > 英語
.un_section_title_en {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  background-image: linear-gradient(45deg, #4ab0ff 0%, #000099 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include g.mq('md') {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }

  @include g.mq('xl') {
    font-size: 2rem;
  }

  &.is_white {
    background: none;
    color: #fff;
    -webkit-text-fill-color: initial;
  }
}

// タイトル > 日本語
.un_section_title_ja {
  line-height: 1.42;
  font-size: 2.2rem;

  @include g.mq('md') {
    font-size: 3.6rem;
  }

  @include g.mq('xl') {
    font-size: 4.2rem;
  }

  span {
    display: inline-block;
  }

  br {
    display: none;

    @include g.mq('lg') {
      display: initial;
    }
  }
}

// 冒頭テキスト
.un_section_intro {
  margin: 0 0 30px;

  @include g.mq('lg') {
    max-width: 880px;
    padding: 0 150px 0 30px;
    margin-bottom: 40px;
  }

  @include g.mq('xl') {
    width: calc(100% - (100vw - #{g.$inner_width}) / 2);
    margin: -40px 0 80px auto;
  }

  @media (min-width: 1920px) {
    width: calc(100% - (1920px - #{g.$inner_width}) / 2);
    margin-top: -50px;
    margin-bottom: 100px;
  }
}
.un_section.is_reverse .un_section_intro {
  @include g.mq('lg') {
    width: 100%;
    padding-right: 20px;
    padding-left: 130px;
    margin-left: 0;
  }

  @include g.mq('xl') {
    padding-left: 150px;
  }
}

// イメージ画像
.un_section_image {
  position: relative;
  z-index: 1;

  img {
    display: block;
    width: 100%;
  }
}

// 内容
.un_section_body {
  position: relative;
  width: 100%;
  padding: 30px 0 40px;
  margin-top: 20px;
  color: #fff;

  @include g.mq('md') {
    padding: 40px 0 50px;
  }

  @include g.mq('lg') {
    padding: 60px 30px 70px;
    margin-top: 0;
  }

  @include g.mq('xl') {
    padding: 95px 0 85px 105px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 100%;
    opacity: 0.8;

    @include g.mq('lg') {
      width: 100%;
      right: 0;
      left: auto;
    }
  }
}
.un_section:nth-child(3n-2) .un_section_body::before {
  background: linear-gradient(45deg, #2abd63 0%, #008ae7 100%);
}
.un_section:nth-child(3n-1) .un_section_body::before {
  background: linear-gradient(135deg, #7cd3ff 0%, #00a4cc 47%, #000099 100%);
}
.un_section:nth-child(3n) .un_section_body::before {
  background: linear-gradient(45deg, #009bff 0%, #00c73a 100%);
}
.un_section.is_reverse .un_section_body {
  @include g.mq('lg') {
    padding-right: 40px;

    &::before {
      left: 0;
      right: auto;
    }
  }

  @include g.mq('xl') {
    padding-left: 0;
    padding-right: 80px;
  }

  @media (min-width: 1920px) {
    padding-right: 140px;
  }
}

// 内容 > インナー
.un_section_body_inner {
  position: relative;

  @include g.mq('xl') {
    width: calc(100% - (100vw - #{g.$inner_width}) / 2);
  }

  @media (min-width: 1920px) {
    width: calc(100% - (1920px - #{g.$inner_width}) / 2);
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('md') {
      margin-top: 40px;
    }

    @include g.mq('lg') {
      margin-top: 50px;
    }
  }
}
.un_section.is_reverse .un_section_body_inner {
  @include g.mq('lg') {
    padding: 0;
    margin: 0 0 0 auto;
  }

  @include g.mq('xl') {
    width: calc(100% - (100vw - #{g.$inner_width}) / 2);
  }

  @media (min-width: 1920px) {
    width: calc(100% - (1920px - #{g.$inner_width}) / 2);
  }
}

// 大きめテキスト
.un_section_pr {
  line-height: 1.89;
  font-size: 1.6rem;
  font-weight: 200;

  @include g.mq('md') {
    font-size: 1.8rem;
  }

  @include g.mq('lg') {
    font-size: 1.9rem;
  }

  @include g.mq('xl') {
    font-size: 2.1rem;
  }
}

/*------------------------------------------------------------------------
  カーボンニュートラル用汎用セクション
------------------------------------------------------------------------*/
.un_carbonNeutralSection {
  .un_section_col {
    &:nth-child(1) {
      @include g.mq('lg') {
        width: 40%;
        padding-top: 80px;
      }

      @include g.mq('xl') {
        padding-top: 95px;
      }
    }
    &:nth-child(2) {
      @include g.mq('lg') {
        width: 60%;
      }
    }
  }

  .un_section_body {
    @include g.mq('lg') {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    @include g.mq('xl') {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    &::before {
      opacity: 1;
    }
  }
}

/*------------------------------------------------------------------------
  「人が主人公、財産」という考えを〜用汎用セクション
------------------------------------------------------------------------*/
.un_valuePeopleSection {
  .un_section_col {
    &:nth-child(1) {
      @include g.mq('lg') {
        width: 58%;
      }
    }
    &:nth-child(2) {
      @include g.mq('lg') {
        width: 42%;
      }
    }
  }

  .un_section_body {
    @include g.mq('xl') {
      padding-left: 50px;
    }
  }
}

/*------------------------------------------------------------------------
  汎用セクション内セクション
------------------------------------------------------------------------*/
.un_innerSection {}

.un_innerSection_title {
  margin: 0 0 10px;
  line-height: 1.5;
  font-weight: bold;
  font-size: 2.2rem;

  @include g.mq('md') {
    margin-bottom: 15px;
    font-size: 2rem;
  }

  @include g.mq('lg') {
    font-size: 2.8rem;
  }

  @include g.mq('xl') {
    font-size: 3.6rem;
  }
}

.un_innerSection_body {
  max-width: 540px;

  > * + * {
    margin-top: 15px;

    @include g.mq('md') {
      margin-top: 20px;
    }

    @include g.mq('lg') {
      margin-top: 25px;
    }
  }
}

/*------------------------------------------------------------------------
  汎用セクション内ボックス
------------------------------------------------------------------------*/
.un_innerBox {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 1.75;

  @include g.mq('md') {
    padding: 0 0 0 28px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 999px;
    background: #fff;
    opacity: 0.3;
  }

  > * + * {
    margin-top: 15px;

    @include g.mq('md') {
      margin-top: 20px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
}

.un_innerBox_title {
  line-height: 1.4;
  font-size: 1.8rem;
  font-weight: bold;

  @include g.mq('md') {
    font-size: 2.4rem;
  }

  > * {
    display: inline-flex;
    align-items: center;
  }
}

.un_innerBox_title_icon {
  display: inline-block;
  width: 29px;
  aspect-ratio: 1/1;
  margin-left: 10px;
  border: 1px solid currentColor;
  border-radius: 50%;

  .triArrow {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

/*------------------------------------------------------------------------
  サステナビリティ（ヘッダー）
------------------------------------------------------------------------*/
.un_sustainabilityHeader {
  position: relative;
  padding: 40px 0 80px;
  color: #fff;
  text-align: center;

  @include g.mq('md') {
    padding: 80px 0 100px;
  }

  @include g.mq('lg') {
    padding: 110px 0 160px;
  }

  @include g.mq('xl') {
    padding: 140px 0 200px;
  }

  .ly_inner {
    z-index: 1;
    position: relative;
    max-width: 800px;

    > * + * {
      margin-top: 30px;
    }
  }
}

.un_sustainabilityHeader_title {
}

.un_sustainabilityHeader_title_en {
  margin: 0 0 10px;
}

.un_sustainabilityHeader_title_ja {
  font-size: 2rem;
  line-height: 1.75;

  @include g.mq('md') {
    font-size: 3rem;
  }

  @include g.mq('xl') {
    font-size: 4.5rem;
  }
}

.un_sustainabilityHeader_body {
  @include g.mq('lg') {
    padding-top: 2em;
  }

  > * + * {
    margin-top: 30px;

    @include g.mq('lg') {
      margin-top: 40px;
    }
  }

  .el_btn {
    margin-inline: auto;
  }
}

// イメージ画像
.un_sustainabilityHeader_image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------------------------------------------------------
  スポーツ支援セクション
------------------------------------------------------------------------*/
.un_sportsSection {
  position: relative;
  padding: 40px 0 50px;
  color: #fff;

  @include g.mq('md') {
    padding: 80px 0;
  }

  @include g.mq('lg') {
    padding: 120px 0;
  }

  @include g.mq('xl') {
    padding: 155px 0;
  }

  .ly_inner {
    position: relative;
    z-index: 1;
  }
}

// レイアウト
.un_sportsSection_row {
  @include g.mq('lg') {
    display: flex;
    justify-content: space-between;
  }

  > * + * {
    margin-top: 20px;

    @include g.mq('lg') {
      margin-top: 0;
    }
  }
}
.un_sportsSection_col {
  &:nth-child(1) {
    @include g.mq('lg') {
      width: 53%;
    }
    @include g.mq('xl') {
      padding-top: 40px;
    }
  }

  &:nth-child(2) {
    @include g.mq('lg') {
      width: 41%;
    }
  }
}

// タイトル
.un_sportsSection_title {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  line-height: 1.75;

  @include g.mq('md') {
    margin-bottom: 40px;
  }
}

// 見出し > 英語
.un_sportsSection_title_en {
  display: inline-block;
  margin: 0 0 10px;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  background-image: linear-gradient(45deg, #4ab0ff 0%, #000099 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include g.mq('md') {
    margin-bottom: 10px;
    font-size: 2rem;
  }

  &.is_white {
    background: none;
    color: #fff;
    -webkit-text-fill-color: initial;
  }
}

// 見出し > 日本語
.un_sportsSection_title_ja {
  line-height: 1.75;
  font-size: 2rem;

  @include g.mq('md') {
    font-size: 3rem;
  }

  span {
    display: inline-block;
  }
}

// 内容
.un_sportsSection_box {
  line-height: 1.75;

  ul {
    list-style: disc;
    padding-left: 20px;
  }
}

// 内容 > タイトル
.un_sportsSection_box_title {
  padding: 0 0 5px;
  margin: 0 0 20px;
  border-bottom: 2px solid #00ab80;
  font-weight: normal;
  font-size: 1.6rem;

  @include g.mq('md') {
    font-size: 1.8rem;
  }

  @include g.mq('xl') {
    font-size: 2.1rem;
  }
}

// イメージ画像
.un_sportsSection_image {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

// 背景画像
.un_sportsSection_bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------------------------------------------------------
  東武グループでの位置付けセクション
------------------------------------------------------------------------*/
.un_positionSection {
  position: relative;
  padding: 40px 0 40vw;

  @include g.mq('md') {
    padding: 80px 0 40vw;
  }

  @include g.mq('lg') {
    padding: 120px 0 34vw;
  }

  @include g.mq('xl') {
    padding: 155px 0 23vw;
  }

  .ly_inner {
    position: relative;
    z-index: 1;
    max-width: g.$inner_width_medium;
  }
}

.un_positionSection_header {
  margin: 0 0 20px;

  @include g.mq('lg') {
    width: 780px;
  }
}

// 見出し
.un_positionSection_title {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  line-height: 1.5;

  @include g.mq('md') {
    margin-bottom: 40px;
  }
}

// 見出し > 英語
.un_positionSection_title_en {
  display: inline-block;
  margin: 0 0 10px;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  background-image: linear-gradient(45deg, #4ab0ff 0%, #000099 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include g.mq('md') {
    margin-bottom: 10px;
    font-size: 2rem;
  }

  &.is_white {
    background: none;
    color: #fff;
    -webkit-text-fill-color: initial;
  }
}

// 見出し > 日本語
.un_positionSection_title_ja {
  font-size: 2rem;

  @include g.mq('md') {
    font-size: 3.7rem;
  }

  span {
    display: inline-block;
  }
}

// ロゴ画像
.un_positionSection_logo {
  display: block;
  width: 100px;
}

// 内容
.un_positionSection_body {
  @include g.mq('lg') {
    margin: -100px 0 0;
  }
}
.un_positionSection_body_inner {
  @include g.mq('lg') {
    width: 490px;
    margin: 0 0 0 auto;
  }
}

// イメージ画像
.un_positionSection_image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
}



}