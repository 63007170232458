/**************************************************************************
    サステナビリティ
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability {

// メインビジュアル
.bl_mvSub {
    background-image: url(../img/sustainability/mv.webp);

    .el_title_icon {
      width: 40px;

      @include g.mq('md') {
          width: 57px;
      }

      .sustainability {
          fill: g.color('white');
      }
  }
}

// 白背景コンテンツボックス
.un_box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 11px rgb(0 0 81 / 0.1);

  &_inner {
    padding: 20px;

    @include g.mq('md') {
      padding: 80px 100px;
    }
  }

  &_footer {
    padding: 30px 40px;
    border-top: 1px solid #e1e1e1;

    @include g.mq('md') {
      padding: 50px 100px;
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      text-decoration: underline;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }

      .el_circleArrow .circle {
        fill: #00ab80;
      }
    }
  }
}
.el_gradTitle.is_border + .un_box {
  margin-top: -2px;
}

// グラデ背景ボックス
.un_gradBox {
  padding: 15px 20px;
  background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
  border-radius: 6px;

  @include g.mq('md') {
    padding: 40px 50px;
  }
}

// サブセクション
.un_subsection {
  &_title {
    color: g.color('key');
    margin-bottom: 4px;
    font-size: 1.8rem;
    font-weight: 500;

    @include g.mq('md') {
      margin-bottom: 10px;
      font-size: 2.7rem;
    }
  }

  p a {
    color: g.color('key');
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// サブタイトル
.un_subtitle {
  padding-bottom: 5px;
  color: g.color('key');
  border-bottom: 2px solid g.color('key');
  font-size: 2rem;
  line-height: 1.5;

  @include g.mq('md') {
    padding-bottom: 10px;
    font-size: 3.4rem;
  }
}

// 縦積みレイアウト
.un_stack {
  > * + * {
    margin-top: 24px;

    @include g.mq('md') {
      margin-top: 56px;
    }
  }

  // 余白小さめ
  &.is_small {
    > * + * {
      margin-top: 15px;

      @include g.mq('md') {
        margin-top: 20px;
      }
    }
  }

  // 余白さらに小さめ
  &.is_xSmall {
    > * + * {
      margin-top: 5px;

      @include g.mq('md') {
        margin-top: 10px;
      }
    }
  }

  // .un_boxを縦積み
  &.is_box_container {
    > * + * {
      margin-top: 12px;

      @include g.mq('md') {
        margin-top: 24px;
      }
    }
  }
}
.el_gradTitle.is_border + .un_stack.is_box_container {
  margin-top: -2px;
}

// テーブル
.un_table {
  width: 100%;
  color: g.color('key');

  caption {
    margin: 0 0 4px;
    color: g.color('key');
    text-align: left;
  }

  th, td {
    padding: 8px;
    border: 1px solid #64bbe5;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;

    @include g.mq('md') {
      padding: 20px;
    }
  }

  thead th {
    background-color: #edf4fb;
  }

  &.is_large {
    th, td {
      font-size: 1.8rem;

      @include g.mq('md') {
        font-size: 2.7rem;
      }
    }

    thead th {
      padding: 20px;
      font-size: 1.5rem;

      @include g.mq('md') {
        padding: 30px;
        font-size: 1.8rem;
      }
    }
  }
}

// .un_tableのアノテーション
.un_tableAnnotation {
  color: g.color('key');
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: right;
  margin-top: 8px !important;

  @include g.mq('md') {
    font-size: 1.4rem;
  }
}

// ページ一覧
.un_pageList {
  &_item {
    list-style: none;
    border-bottom: 1px solid #e1e1e1;

    &:first-child {
      border-top: 1px solid #e1e1e1;
    }
  }

  &_item_link {
    display: block;
    padding: 15px 0;

    @include g.mq('md') {
      display: grid;
      gap: 20px;
      grid-template-columns: 30% 1fr;
    }

    @include g.mq('lg') {
      gap: 44px;
      grid-template-columns: 250px 1fr;
    }
  }

  &_item_image {
    display: block;
    aspect-ratio: 16/9;
    object-fit: cover;
    margin-bottom: 10px;

    @include g.mq('md') {
      aspect-ratio: 250/182;
    }
  }

  &_item_body {
    @include g.mq('md') {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &_item_label {
    color: g.color('key');
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 8px;

    @include g.mq('md') {
      font-size: 2.2rem;
    }

    @include g.mq('lg') {
      font-size: 2.7rem;
    }
  }

  &_item_text {
    line-height: 1.7;
  }
}

// 幅100%のイメージ画像
.un_image {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}

// リストマークつける
ul.un_ul {
  list-style: disc;
  padding-left: 20px;
}

// ローカルナビ
.un_localNav {
  padding: 50px 0;
  background: url('../img/sustainability/materiality/bg_local_nav.webp') no-repeat border-box center/cover;

  @include g.mq('md') {
    padding: 100px 0;
  }

  &_btnList {
    @include g.mq('md') {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto);
      gap: 16px 2%;
    }

    @include g.mq('lg') {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
      gap: 31px 2%;
    }

    > li + li {
      margin-top: 10px;

      @include g.mq('md') {
        margin-top: 0;
      }
    }
  }

  &_btn {
    width: 100%;
    max-height: none;
    max-width: none;
    padding-right: 75px;
    line-height: 1.5;

    @include g.mq('lg') {
      min-height: 120px;
    }

    span {
      text-align: left;
    }

    &.is_current {
      background-color: g.color('key');
      pointer-events: none;

      span {
        color: #fff;
      }

      .circle {
        fill: #fff;
      }

      .arrow {
        stroke: g.color('key');
      }
    }
  }
}

}