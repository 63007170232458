/**************************************************************************
    ソーシャルメディアポリシー
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#policy.socialmedia {

    // メインビジュアル
    .bl_mvSub {
        background-image: url(../img/socialmedia/mv.webp);
        height: 300px;

        @include g.mq('md') {
            height: 436px;
        }
    }

    // コンテンツ
    .un_sec {
        padding: 70px 0 80px;

        @include g.mq('md') {
            padding: 100px 0;
        }

        &.is_bg {
            background: #f5f7fb;
        }

        p {
            text-align: left;
        }

        &_list {
            counter-reset: number;
        }

        &_item {
            margin-top: 52px;
        }

        &_tit {
            counter-increment: number;
            display: flex;
            color: g.color('key');
            font-size: 1.6rem;
            margin-bottom: 6px;
            line-height: 1.8;
            display: flex;
            gap: .2em;

            @include g.mq('md') {
                font-size: 1.8rem;
            }

            &:before {
                content: counter(number) ".";
            }
        }

        &_discList {
            list-style-type: disc;
            padding-left: 3.5rem;
        }

        &_numList02 {
            counter-reset: numList02;

            > li {
                counter-increment: numList02;
                position: relative;
                padding-left: 3.5rem;
                margin-top: 0.7em;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: counter(numList02) ".";
                }
            }
        }

        &_numList {
            counter-reset: numList;
            margin-top: 0.5em;

            > li {
                counter-increment: numList;
                position: relative;
                padding-left: 3.5rem;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "(" counter(numList) ")";
                }
            }
        }

        &_endTxt {
            margin-top: 20px;
            
            >p {
                font-size: 1.2rem;
                text-align: right;

                @include g.mq('md') {
                    font-size: 1.4rem;
                }
            }
        }

        &_mainTit {
            margin-bottom: 20px;
            line-height: 1.5;
            font-weight: bold;
            font-size: 2.4rem;

            @include g.mq('md') {
                font-size: 4.2rem;
                margin-bottom: 40px;
            }
        }

        &_info {
            padding: 15px 20px;
            background: rgb(g.color('key'), .07);
            margin-bottom: 2em;

            @include g.mq('md') {
                padding: 30px 50px;
            }
        }

        &_pdfLink {
            color: g.color('key');
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            &::after {
                content: '';
                width: 14px;
                height: 18px;
                background: url('../img/common/icon_pdf.svg') no-repeat border-box center/cover;
                display: block;
            }
        }

    }

}

