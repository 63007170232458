/**************************************************************************
    会社概要
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.outline {
	// メインビジュアル
	.bl_mvSub {
		background-image: url(../img/company/outline/mv.webp);
	}

	.un_main {
		padding: 50px 0 100px;
		
		@include g.mq('md') {
			padding: 80px 0 154px;
			background: url(../img/company/outline/bg.webp) no-repeat border-box center/cover;
		}
	}

	// セクション
	.un_section {
		margin-top: 50px;

		@include g.mq('md') {
			margin-top: 100px;
		}

		&.is_first {
			margin-top: 0;
		}
	}

	// 会社概要
	.un_outline {
		&_row {
			display: flex;

			&:not(:first-of-type) {
				margin-top: 20px;

				@include g.mq('md') {
					margin-top: 35px;
				}
			}

			dt, dd {
				line-height: math.div(39.6055, 16);
			}

			dt {
				width: 80px;
				padding-right: 15px;
				border-right: 2px solid #f4f2f4;
				
				@include g.mq('md') {
					width: 162px;
					padding-right: 20px;
				}

				.small {
					display: block;
					margin-top: 3px;
					font-size: 1.2rem;
					line-height: 1.4;
				}
			}

			dd {
				flex: 1;
				padding-left: 15px;
				
				@include g.mq('md') {
					padding-left: 20px;
				}

				a {
					text-decoration: underline;
					color: g.color('key');
				}
			}
		}
	}

	.un_decimalList {
		list-style: decimal;
		margin-left: 1.4em;
	}

	// 組織図
	.un_management {
		&_img {
			margin-top: 35px;

			@include g.mq('md') {
				margin-top: 50px;
			}

			a {
				display: block;
				width: fit-content;
				margin-inline: auto;
			}
		}
	}

	// 下部リンク
	.un_bottomLink {
		display: block;
		width: fit-content;
		margin-top: 35px;
		color: g.color('key');
		text-decoration: underline;

		@include g.mq('md') {
			margin-top: 50px;
		}
	}
}
