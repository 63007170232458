/*------------------------------------------------------------------------
	フッターロゴ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_footerLogoArea {
	padding: 20px 0;
	color: g.color('base');
	background-color: g.color('white');
	
	@include g.mq('md') {
		padding: 40px 0;
	}

	&_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 20px;

		@include g.mq('md') {
			gap: 40px;
		}
	}

	&_item {
		img {
			@include g.mq('sm') {
				width: 90px;
			}
		}

		&.is_gstc {
			img {
				@include g.mq('sm') {
					width: 73px;
				}
			}	
		}
	}

	.el_copyright {
		display: block;
		margin-top: 20px;
		text-align: center;

		@include g.mq('md') {
			margin-top: 40px;
		}
	}
}
