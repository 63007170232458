/*------------------------------------------------------------------------
	フッターナビ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_fnav {
	display: grid;
	grid-template-columns: repeat(2, auto);
	gap: 30px 25px;
	
	@include g.mq('lg_max') {
		display: none;
	}
	
	@include g.mq('xl') {
		grid-template-columns: repeat(3, auto);
	}
	
	&.is_en {
		grid-template-columns: repeat(4, 1fr);
		margin-top: 30px;
	}

	&_list {
		&.is_col_span {
			grid-column: span 2;
		}
	}

    &_item {
		font-size: 1.5rem;
		font-weight: 600;
		line-height: math.div(32, 15);
		letter-spacing: 0;

		&.is_primary {
			padding-bottom: 5px;
			font-size: 1.8rem;
		}

		&.is_unlink {
			color: #adacac;
		}
	}
}

