/*------------------------------------------------------------------------
	言語選択リスト
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_languageList {
    display: flex;

	@include g.mq('lg_max') {
		max-width: g.$inner-width-sp;
		margin: 20px auto 0;
	}

	> li {
		font-size: 1.3rem;
		letter-spacing: 0;
		line-height: 0.8;

		&:not(:first-of-type) {
			border-left: 1px solid g.color('base');
		}

		a {
			display: block;
			padding: 0 5px;
		}
	}
	
}