/**************************************************************************
    ベース (接頭辞なし)
**************************************************************************/
@use "sass:math";
@use "../global" as g;

html {
    font-size: 10px;
    overflow-y: scroll; //スクロールバーを常に表示
}

body {
    font-family: g.font('base');
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: math.div(57, 26);
    -webkit-text-size-adjust: 100%;
    color: g.color('base');
    overflow: hidden;

    @include g.mq('md') {
        font-size: 1.6rem;
        line-height: math.div(35, 16);
    }

    &.is_fixed {
        overflow: hidden;
    }
}

a {
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none; //クリックした時に選択状態にならないようにする
    -moz-user-select: none; // Firefox
    -webkit-user-select: none; // Safari、Chromeなど
    
    &:hover {
        @include g.mq('md') {
            opacity: 0.7;
        }
    }

    &[href*="tel:"] {
        @include g.mq('md') {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
        }
    }
}

p {
    text-align: justify;
}

.en p {
    text-align: left;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}