/*------------------------------------------------------------------------
	SNSリスト
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_sns {
    padding: 20px 0 50px;
    background-color: g.color('bg');
    
    @include g.mq('md') {
        padding: 70px 0;
    }

    &_text {
        font-size: 1.5rem;
        line-height: math.div(57, 30);
        letter-spacing: 0;

        @include g.mq('md') {
            font-size: 1.7rem;
            text-align: center;
        }
    }

    &_list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-top: 20px;
    }
}
