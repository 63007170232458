/**************************************************************************
    役員一覧
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#company.officer {
	// メインビジュアル
	.bl_mvSub {
		background-image: url(../img/company/officer/mv.webp);
	}

	.un_main {
		padding: 70px 0 120px;
		
		@include g.mq('md') {
			padding: 140px 0 165px;
		}
	}

	.un_section {
		&:not(:first-of-type) {
			margin-top: 60px;

			@include g.mq('md') {
				margin-top: 110px;
			}
		}
	}

	.un_officerList {
		display: grid;
		grid-template-columns: 1fr;
		gap: 30px;
		
		@include g.mq('md') {
			grid-template-columns: repeat(2, 1fr);
			gap: 50px 20px;
		}

		&_image {
			text-align: center;

			@include g.mq('lg') {
				width: min(percentage(math.div(217, 500)), 217px);
				margin-inline: auto;
			}
		}

		&_item {
			@include g.mq('lg') {
				display: flex;
				align-items: end;
				gap: 20px;
			}
		}

		&_data {
			text-align: center;

			@include g.mq('lg') {
				flex: 1;
				padding-bottom: 20px;
				text-align: left;
			}

			dt {
				font-size: 2.2rem;
				font-weight: 500;
				color: g.color('key');

				@include g.mq('md') {
					font-size: 2.9rem;
				}
			}

			dd {
				line-height: math.div(25.5118, 16);
				letter-spacing: 0;
			}
		}
	}
}
