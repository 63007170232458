/**************************************************************************
    サステナビリティ　東武トップツアーズのサステナビリティ
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#sustainability.management {

// セクション大枠
.un_section {
    padding: 70px 0 120px;

    @include g.mq('md') {
        padding: 120px 0 200px;
    }

    // 背景画像
    $bg_list: ('A', 'B', 'C', 'D');
    @each $val in $bg_list {
        &.is_bg_#{$val} {
            background: url('../img/sustainability/management/bg_#{$val}.webp') no-repeat border-box center/cover;
        }
    }

    // グラデーション
    &:first-of-type {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            inset: 0 auto auto 0;
            width: 100%;
            height: 350px;
            background-image: linear-gradient(to bottom, #fff, transparent);
        }

        .ly_inner {
            position: relative;
            z-index: 1;
        }
    }
}

// サステナビリティビジョン
.un_vision {
  p {
    & + p {
      margin-top: 2em;
    }
  }

  b {
    display: block;
    color: g.color('key');
    font-weight: bold;
    font-size: 1.6rem;
    line-height: math.div(30, 16);

    @include g.mq('md') {
      font-size: 2.1rem;
    }
  }
}

// ステークホルダーとのかかわりの画像
.un_stakeholderEngagementImage {
  img {
    display: block;
    width: 100%;

    @include g.mq('md') {
      width: 70%;
      margin-inline: auto;
    }
  }
}

}