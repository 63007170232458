/**************************************************************************
    サステナビリティ　社会に貢献する事業の継続
**************************************************************************/
@use "sass:math";
@use "../../global" as g;


#sustainability.social-contribution {

    //common拡張
    .un_box__noBG {
        background-color: transparent;
        box-shadow: none;
    }

    .un_table{
        th,td{
            display:block;
            width:100%;
        }

        th {
            background-color: #edf4fb;    
        }

       td{
        border-top:none;
        margin-bottom:10px;
        text-align:left;
       }

       @include g.mq('md'){
           th,td{
               display:table-cell;
               width:auto;
           }

           td{
            border-top: 1px solid #64bbe5;
           }
       }
    }


    // イントロ文
    .un_intro {
        max-width: 1000px;
        margin: 0 auto 70px;
    
        @include g.mq('md') {
        margin-bottom: 120px;
        }
    }

     //タイトル
     .un_line_title{
        color: g.color('key');
        font-size: 1.6rem;
        border-left:solid 3px g.color('key');
        padding-left:13px;
        line-height: 1.5;

        @include g.mq('md') {
            font-size: 1.8rem;
        }
    }

    .un_alignment_title{
        font-size:1.6rem;
        color:g.color('key');
        margin-top: 20px;

        @include g.mq('md'){
            font-size:1.8rem;
        }
    }

    .un_alignment_list{
        margin-top: 10px;

        li{
            padding-left:16px;
            background: url('../img/sustainability/materiality/social-contribution/arrow_blue_right.svg') left center no-repeat;
            background-size:12px auto;

            &:nth-child(n+2){
                margin-top:10px;
            }

            a{
                text-decoration: underline;

                &:hover{
                    color:#444;
                }
            }
        }
    }

    //テキスト
    .un_society_annotation{
        display: inline-block;
        padding-top:10px;
        font-size:1.2rem;
        line-height:1.3;
        color:#333333;

        @include g.mq('md'){
            font-size:1.4rem;
        }
    }

    //リンク
    .un_outerLink{
        &::before{
            content: url('../img/sustainability/materiality/social-contribution/outer_link.svg');
            display: inline-block;
            width:14px;
            margin-right: 5px;
        }
    }


    // セクション大枠
    .un_section {
        
        padding: 70px 0 120px;

        @include g.mq('md') {
          padding: 120px 0 200px;
        }

        // 背景画像
        &.is_bg_A {
            background: url('../img/sustainability/materiality/social-contribution/bg_A.webp') no-repeat border-box center/cover;
        }
        &.is_bg_loop {
            background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
            background-size: 100% auto;
        }

        // グラデーション
        &:first-of-type {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                inset: 0 auto auto 0;
                width: 100%;
                height: 350px;
                background-image: linear-gradient(to bottom, #fff, transparent);
            }

            .ly_inner {
                position: relative;
                z-index: 1;
            }
        }
    }

    //sustainability-commonの拡張
    .un_subtitle{
        padding-bottom:2rem;
        margin-bottom: 2rem;

        @include g.mq('md') {
            padding-bottom:2.5rem;
            margin-bottom: 3.4rem;
        }
    }

    //内部グループ
    .un_innerGroup{
        margin-top:30px;

        @include g.mq('md'){
            margin-top:40px;
        }
    }

   

    //レイアウト
    .un_image-layout{
        > * + * {
            margin-top: 40px;
        }

        p{
            margin-top: 1rem;
        }

        &_image{
            margin-top:10px;
        }

        &_inner{
            @include g.mq('lg'){
                display:flex;
                align-items: flex-start;
                

                .un_image-layout_content{
                    flex:1;
                    width:70%;
                    padding-right: 30px;
                }

                .un_image-layout_image{
                    margin-top:0;
                    width:30%;
                }

            }
        }

        
    }



    .un_ex_column{
        background: linear-gradient(
             135deg,
            rgba(255, 226, 7, 0.1) 0%,
            rgba(9, 141, 88, 0.1) 97%
        );
        border-radius: 6px;
        padding:20px;

        @include g.mq('lg'){
            padding:80px 100px;
        }


        &_title{
            font-size:1.8rem;
            color:g.color('key');
            border-bottom:solid 1px g.color('key');

            @include g.mq('lg'){
                font-size:2.4rem;
            }
        }

        &_box{
            margin-top:20px;

            &:nth-of-type(n+2){
                margin-top:40px;
            }

        }

        .un_ex_column_two_set{
            @include g.mq('lg'){
                display:flex;
                gap:30px;
            }
        }

        p{
            color:g.color('key');
            font-size:1.4rem;

            @include g.mq('lg'){
                font-size:1.6rem;
            }
        }

    }

    .un_ex_column_content{

        &_title{
            font-size:1.4rem;
            line-height: 1.3;
            color:g.color('key');
            background: url('../img/sustainability/materiality/social-contribution/arrow_blue_right.svg') left center no-repeat;
            background-size:14px auto;
            padding-left:20px;
            margin-top:20px;

            @include g.mq('lg'){
                font-size:1.8rem;
                margin-top:0;
            }

        }

        &_list{
            margin-top:40px;

            &:first-child{
                margin-top:20px;
            }

            .un_ex_column_content_list_image{
                margin:0 auto;
                text-align: center;
                max-width:480px;
            }
        
            @include g.mq('lg'){
                display:flex;
                flex-direction: row-reverse;
                align-items: flex-start;

                .un_ex_column_content_list_image{
                    width:200px;
                }

                .un_ex_column_content_list_content{
                    flex:1;
                    padding-right:40px;
                }
            }
        }
        
    }

    .un_reference_gallery{

        > * + * {
            margin-top: 20px;
        }

         > div{

            img{
                width:100%;
            }
        }

        @include g.mq('md'){
            > * + * {
                margin-top: 0;
            }

            display:flex;
            flex-wrap: wrap;
            gap:20px;

            > div{
                flex:1;
            }
           
        }

    }

    .un_tours{

        &_gallery{

            margin-top:20px;
            padding:20px;
            background-color: #f5f5f5;
            border-radius:4px;
    
            li:nth-child(n+2){
                margin-top:20px;
            }
    
            @include g.mq('md'){
                display:flex;
                flex-wrap: wrap;
                gap:20px;
                padding:0;
                background-color: transparent;
    
                li{
                    margin-top: 0;
                    flex:1;
                }

                li:nth-child(n+2){
                    margin-top:0;
                }
            }
        }

    }

    .un_education{

        &_list{

            li{
                &:nth-child(n+2){
                    margin-top:40px;
                }
                .un_education_list_title{
                    font-size:1.4rem;
                    line-height: 1.3;
                    color:g.color('key');
                    background: url('../img/sustainability/materiality/social-contribution/arrow_blue_right.svg') left center no-repeat;
                    background-size:14px auto;
                    padding-left:20px;
                    margin-top:20px;

                    @include g.mq('md'){
                        font-size:1.8rem;
                    }

                }
            }
        }

        &_image_layout{

            @include g.mq('lg'){
                display:flex;
                flex-wrap: wrap;
                align-items: flex-start;
                flex-direction: row-reverse;
                padding-bottom: 10px;

                img{
                    width:38%;
                }

                p{
                    flex:1;
                    width:62%;
                    padding-right:30px;
                }
            }
        }

        .un_education_list_sub{
            margin-top:10px;
            padding:24px 20px;
            background:#f5f5f5;
            border-radius:4px;

            @include g.mq('md'){
                background:transparent;
                margin-top: 30px;
                padding:0;
            }

            .un_education_list_sub_item{
                text-align: center;
                
                &:nth-child(n+2){
                    margin-top:20px;

                    @include g.mq('md'){
                        margin-top:30px;
                    }
                }


                .un_education_list_sub_image{
                    width:100%;

                    @include g.mq('md'){
                        width:30%;
                        max-width:260px;
                    }
                }

                .un_education_list_sub_content{
                    text-align:left;
    
                    h6{
                        font-weight: normal;
                        color: g.color('key');
                        font-size:1.4rem;

                        @include g.mq('md'){
                            font-size:1.6rem;
                         }
                    }

                }
    
               
            }

            
        }
    }

    .un_education_list_logo{
        margin:20px auto 0;
        width:100%;
        max-width:400px;
        border:solid 1px #b4b4b4;
        display:block;
        
        @include g.mq('md'){
            margin-left:0;
            margin-right:0;
        }

    }

    .un_safety{
        &_image_layout{

            .un_safety_image_layout_image{
                margin:0 auto;
                width:100%;
                max-width:300px;

                img{
                    border:solid 1px #b4b4b4;
                }
            }

            p{
                margin-top:20px;
            }

            @include g.mq('lg'){
                display:flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-bottom: 10px;

                .un_safety_image_layout_image{
                    width:30%;
                    max-width:240px;
                }

                p{
                    flex:1;
                    padding-left:30px;
                    margin-top: 0;
                }
            }

        }
    }
    

}