/**************************************************************************
    採用情報
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#employment {

/*------------------------------------------------------------------------
  メインビジュアル
------------------------------------------------------------------------*/
.bl_mvSub {
  .el_title_icon {
    width: 40px;

    @include g.mq('md') {
      width: 60px;
    }

    .employment {
      stroke: g.color('white');
    }
  }

  &.is_top {
    background-image: url('../img/employment/mv.webp');
  }

  &.is_career {
    background-image: url('../img/employment/mv_career.webp');
  }

  &.is_handicapped {
    background-image: url('../img/employment/mv_handicapped.webp');
  }
}

/*------------------------------------------------------------------------
  リンク
------------------------------------------------------------------------*/
.un_link {
  color: g.color('key');
  text-decoration: underline;
}

/*------------------------------------------------------------------------
  縦積み
------------------------------------------------------------------------*/
.un_stack {
  > * + * {
    margin-top: 15px;

    @include g.mq('md') {
      margin-top: 20px;
    }

    @include g.mq('lg') {
      margin-top: 25px;
    }
  }
}

/*------------------------------------------------------------------------
  リスト
------------------------------------------------------------------------*/
.un_ul {
  list-style: disc;
  padding-left: 20px;
}
.un_ol {
  list-style: decimal;
  padding-left: 20px;
}

/*------------------------------------------------------------------------
  汎用セクション
------------------------------------------------------------------------*/
.un_section {
  padding: 70px 0 100px;

  @include g.mq('md') {
    padding: 120px 0 150px;
  }

  // 背景画像
  &.is_bg_career {
    background: url('../img/employment/bg_career_sp.webp') no-repeat border-box center/cover;

    @include g.mq('lg') {
      background-image: url('../img/employment/bg_career_pc.webp');
    }
  }
  &.is_bg_handicapped {
    background: url('../img/employment/bg_handicapped.webp') no-repeat border-box center/cover;
  }
  &.is_bg_loop {
    background: url('../img/employment/bg_loop_sp.webp') repeat-y border-box 50% 50%;
    background-size: 100% auto;

    @include g.mq('lg') {
      background-image: url('../img/employment/bg_loop_pc.webp');
    }
  }

  // グラデーション
  &:first-of-type {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto auto 0;
      width: 100%;
      height: 350px;
      background-image: linear-gradient(to bottom, #fff, transparent);
    }

    .ly_inner {
      position: relative;
      z-index: 1;
    }
  }

  .ly_inner > * + * {
    margin-top: 20px;

    @include g.mq('md') {
      margin-top: 40px;
    }

    @include g.mq('xl') {
      margin-top: 50px;
    }
  }
}

.un_section_intro {
  width: fit-content;
  margin: 0 auto;
}

/*------------------------------------------------------------------------
  汎用サブセクション
------------------------------------------------------------------------*/
.un_subsection {
  padding: 0 0 20px;
  margin: 0 0 15px;
  border-bottom: 1px solid g.color('border');
  line-height: 1.75;

  @include g.mq('lg') {
    display: grid;
    grid-template-columns: 230px 1fr;
    gap: 20px;
    padding-bottom: 50px;
    margin-bottom: 40px;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.un_subsection_title {
  margin: 0 0 15px;
  color: g.color('key');
  font-weight: bold;
  font-size: 1.5rem;

  @include g.mq('md') {
    font-size: 2rem;
  }

  @include g.mq('lg') {
    margin-bottom: 0;
  }
}

.un_subsection_body {
  @include g.mq('lg') {
    padding: 5px 0 0;
  }
}

/*------------------------------------------------------------------------
  職種
------------------------------------------------------------------------*/
.un_position {
  display: flex;
  margin: 0 0 5px;
  color: g.color('key');
  font-weight: bold;
}

.un_position_number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0.3em 10px 0 0;
  line-height: 1.5;
  background-color: g.color('key');
  border-radius: 50%;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;

  @include g.mq('md') {
    width: 22px;
    height: 22px;
    font-size: 1.4rem;
  }
}

.un_position_label {
  display: inline-block;
  width: calc(100% - 30px);

  @include g.mq('md') {
    width: calc(100% - 32px);
  }
}

/*------------------------------------------------------------------------
  白背景ボックス
------------------------------------------------------------------------*/
.un_box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 11px rgb(0 0 81 / 0.1);
}

.un_box_inner {
  padding: 20px;

  @include g.mq('md') {
    padding: 80px 100px;
  }
}

/*------------------------------------------------------------------------
  カード（よくある質問へのリンク）
------------------------------------------------------------------------*/
.un_card {
  display: flex;
  flex-direction: column;
  width: 282px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);

  &_label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    height: 140px;

    &_icon {
      display: block;
      width: 62px;
      aspect-ratio: 1/1;
      margin-inline: auto;
    }

    &_text {
      display: block;
      padding: 0 15px;
      margin-inline: auto;
      text-align: center;
      color: g.color('key');
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.2;

      @include g.mq('md') {
        font-size: 2.2rem;
      }

      > span {
        font-size: 1.3rem;

        @include g.mq('md') {
          font-size: 1.6rem;
        }
      }
    }
  }

  &_body {
    flex-grow: 1;
    display: block;
    padding: 10px 15px;
    background-image: linear-gradient(135deg, #e1f6f9, #e1e9f8);
    line-height: 1.6;
    text-align: justify;

    > span {
      font-size: 1.1rem;

      @include g.mq('md') {
        font-size: 1.4rem;
      }
    }

    @include g.mq('md') {
      padding: 24px 20px;
    }
  }
}

/*------------------------------------------------------------------------
  お問い合わせ
------------------------------------------------------------------------*/
.un_contact {
  display: block;
  padding: 30px 0;
  background-color: #fff;

  @include g.mq('lg') {
    padding: 80px 0 85px;
  }

  @include g.mq('xl') {
    padding: 90px 0 100px;
  }

  .el_btn {
    margin-top: 20px;

    @include g.mq('md') {
      margin-top: 30px;
    }
  }
}

.un_contact_grid {
  @include g.mq('lg') {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 100px;
  }

  @include g.mq('xl') {
    gap: 100px;
  }

  > * {
    align-content: center;
  }

  > * + * {
    margin-top: 20px;

    @include g.mq('lg') {
      margin-top: 0;
    }
  }
}

.un_contact_title {
  font-weight: bold;
  color: g.color('key');
  line-height: 1.5;
}

.un_contact_title_en {
  font-size: 2.2rem;

  @include g.mq('md') {
    font-size: 3rem;
  }

  @include g.mq('lg') {
    font-size: 4.5rem;
  }

  @include g.mq('xl') {
    font-size: 6.9rem;
  }
}

.un_contact_title_ja {
  font-size: 1.1rem;

  @include g.mq('md') {
    font-size: 1.5rem;
  }

  @include g.mq('lg') {
    font-size: 1.8rem;
  }

  @include g.mq('xl') {
    font-size: 2.2rem;
  }
}

.un_contact_text {
  line-height: 1.73;

  a {
    color: g.color('key');
    text-decoration: underline;
  }
}

}