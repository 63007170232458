/*------------------------------------------------------------------------
	コンテンツ白板
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.ly_contsBox {
	padding: 50px g.get-inner-side(g.$inner-width-sp);
	background-color: g.color('white');
	box-shadow: 0 0 9px rgba(#000051, 0.1);
	border-radius: 10px;

	@include g.mq('md') {
		padding: 70px g.get-inner-side(g.$inner-width-sp) 75px;
	}

	@include g.mq('xl') {
		padding: 70px g.get-inner-side(g.$inner-width-small) 75px;
	}
}