/**************************************************************************
    サステナビリティ　コーポレートガバナンス
**************************************************************************/
@use "sass:math";
@use "../../global" as g;


#sustainability.corporate-governance {

    //common拡張
    .un_box__noBG {
        background-color: transparent;
        box-shadow: none;
    }

    // セクション大枠
    .un_section {
        
        padding: 70px 0 120px;

        @include g.mq('md') {
            padding: 120px 0 200px;
        }

        // 背景画像
        &.is_bg_A {
            background: url('../img/sustainability/materiality/corporate-governance/bg_A.webp') no-repeat border-box center/cover;
        }
        &.is_bg_loop {
            background: url('../img/sustainability/bg_repeat.webp') repeat-y border-box 50% 50%;
            background-size: 100% auto;
        }

        // グラデーション
        &:first-of-type {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                inset: 0 auto auto 0;
                width: 100%;
                height: 350px;
                background-image: linear-gradient(to bottom, #fff, transparent);
            }

            .ly_inner {
                position: relative;
                z-index: 1;
            }
        }
    }

    //タイトル
    .un_line_title{
        color: g.color('key');
        font-size: 1.6rem;
        border-left:solid 3px g.color('key');
        padding-left:13px;
        line-height: 1.5;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

        & + * {
            margin-top: 10px;
        }
    }

    // イントロ文
    .un_intro {
        max-width: 1000px;
        margin: 0 auto 70px;
    
        @include g.mq('md') {
        margin-bottom: 120px;
        }
    }

    //外部リンク
    .un_linkage{
        margin-top:20px;
        line-height:1.8;
    }

    .un_outerLink{
        color: g.color('key');
        text-decoration: underline;

        &::before{
            content: url('../img/sustainability/materiality/social-contribution/outer_link.svg');
            display: inline-block;
            width:14px;
            margin-right: 5px;
        }
    }

    //リスト
    .un_cg_list{
        list-style-type: disc;
        padding-left: 16px;
        margin-top:20px;

        li{
            line-height: 1.5;
            text-indent: -2px;
            margin-bottom:16px;
        }
    }


    //テキスト
    .un_cg_annotation{
        display: inline-block;
        padding-top:10px;
        font-size:1.2rem;
        line-height:1.3;
        color:#333333;

        @include g.mq('md'){
            font-size:1.4rem;
        }
    }

    //カラムレイアウト
    .un_cg_columns{

        &_content{
            margin-top:10px;
        }

        @include g.mq('md') {
            display:flex;

            &_content{
                margin-block: calc((1em - 1lh) / 2);
            }

            &_visual{
                width:32%;
                max-width:320px;
            }

            &_content{
                flex:1;
                padding-left:20px;
            }

            &.is_reverse{
                flex-direction: row-reverse;
                .un_cg_columns_content{
                    padding-right:20px;
                    padding-left:0;
                }
            }

            &.is_half{
                gap:20px;
                .un_cg_columns_content{
                    padding-left: 0;
                }
                .un_cg_columns_visual{
                    padding-left:0;
                    width:auto;
                    max-width:50%;
                    flex:1;
                }
            }

            
        }
       
      
    }


}