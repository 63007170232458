/**************************************************************************
    ソーシャルメディアポリシー
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#policy {

// メインビジュアル
.bl_mvSub {
    background-image: url(../img/business/detail/mv.webp);
}

// リンク
.un_link {
    color: g.color('key');
    text-decoration: underline;
}

}

