/*------------------------------------------------------------------------
    PDFボタン
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_pdfBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 489px;
    max-width: 100%;
    height: 80px;
    font-size: 1.6rem;
    box-shadow: 0 0 6px rgba(g.color('key'), 0.15);
    border-radius: 15px;
    background-color: g.color('white');

    @include g.mq('md') {
        height: 105px;
        font-size: 1.8rem;
    }

    &::before {
        content: '';
        width: 17px;
        height: 23px;
        background: url('../img/common/icon_pdf.svg') no-repeat border-box center/cover;
    }

    span {
        font-size: 1.2rem;

        @include g.mq('md') {
            font-size: 1.4rem;
        }
    }
}

