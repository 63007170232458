/*------------------------------------------------------------------------
	コピーライト
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_copyright {
	display: block;
	margin-top: 30px;
	font-size: 1.3rem;
	font-weight: 600;
	letter-spacing: 0;
	text-align: center;

	@include g.mq('md') {
		margin-top: 50px;
	}
	
	@include g.mq('lg') {
		margin-top: 15px;
		text-align: center;
	}
	
	@include g.mq('xl') {
		margin-top: 0;
		text-align: right;
	}
}