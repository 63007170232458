/*------------------------------------------------------------------------
	メガメニュー
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_megamenu {
	$this: &;
    visibility: hidden;
	overflow: hidden;
	transition: 0.3s;
	width: 100%;
	height: 0;
	border-bottom: 1px solid g.color('base');
	color: g.color('base');
	background-color: rgba(#f4fafb, 0.95);

	@include g.mq('lg_max') {
		&.is_show {
			visibility: visible;
		}
	}
	
	@include g.mq('lg') {
		position: absolute;
		top: map-get(g.$header_h, 'lg');
		left: 0;
		opacity: 0;
		height: auto;
	}

	&::before {
		position: absolute;
		inset: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: url('../img/common/megamenu_bg.webp') no-repeat border-box bottom right/cover;
		
		@include g.mq('lg') {
			content: '';
		}
	}

	// 英語サイト
	&.is_en {
		@include g.mq('lg') {
			top: map-get(g.$header_h, 'md');
		}
	}
	
	&_inner {
		display: grid;
		grid-template-columns: 1fr;
		padding: 20px 0;

		@include g.mq('lg') {
			position: relative;
			padding: 25px 0 40px;
		}
	}

	&_head {
		display: flex;
		align-items: end;
		gap: 25px;

		&_title {
			display: flex;
			align-items: end;
			gap: 15px;
			font-size: 2rem;
			
			@include g.mq('lg_max') {
				display: none;
			}
		}

		&_btn {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 45px;
			font-size: 1.8rem;
			color: g.color('white');
			background-color: g.color('key');
			
			@include g.mq('lg') {
				width: auto;
				height: 25px;
				padding: 0 25px;
				margin-bottom: 3px;
				font-size: 1.2rem;
				font-weight: normal;
				color: g.color('base');
				background-color: g.color('white');
				border-radius: 100vmax;
				border: 1px solid g.color('base');
			}
		}
	}

	&_body {
		@include g.mq('lg') {
			margin-top: 25px;
		}
	}

	&_row {
		@include g.mq('lg') {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 25px;
		}
		
		&.is_col3 {
			@include g.mq('lg') {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}

	&_list {
		@include g.mq('lg_max') {
			margin-top: 10px;

			&:first-of-type {
				margin-top: 20px;
			}
		}

		@include g.mq('lg') {
			max-width: 460px;
		}
	}

	&_item {
		font-weight: 700;

		@include g.mq('lg') {
			font-size: 1.8rem;
			font-weight: 400;
		}

		&:not(:first-of-type) {
			margin-top: 10px;

			&:has(> p) {
				@include g.mq('lg') {
					margin-top: 25px;
				}
			}
		}


		> a {
			display: inline-flex;

			&::before {
				content: '';
				flex-shrink: 0;
				$_size: 22px;
				width: $_size;
				height: $_size;
				margin-top: 4px;
				margin-right: 10px;
				background: url('../img/common/icon_circle_tri_arrow.svg') no-repeat border-box center/cover;
			}
		}

		> p {
			font-size: 1.4rem;
			font-weight: normal;
			line-height: 1.6;
			text-align: left;

			@include g.mq('lg_max') {
				display: none;
			}
		}
	}

	&_close {
		position: absolute;
		top: 20px;
		right: 20px;
		display: inline-flex;
		align-items: center;
		gap: 5px;
		padding: 2px 12px;
		font-size: 1.2rem;
		font-weight: normal;
		border-radius: 100vmax;
		background-color: g.color('white');
		border: 1px solid g.color('base');

		&_icon {
			@include g.close-icon(12px, 1px, g.color('base'));
			position: relative;
		}


		@include g.mq('lg_max') {
			display: none;
		}
	}
}