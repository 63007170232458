/*------------------------------------------------------------------------
	ハンバーガーアイコン
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_menubar {
    position: relative;
    display: block;
    $_size: 39px;
    width: $_size;
    height: $_size;

    span {
        $paddingY: 15px; //バーの間隔をここで調整
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
        height: 2px;
        background-color: g.color('white');
        transition: all .4s;

        &:nth-of-type(1) {
            top: $paddingY; 
        }

        &:nth-of-type(2) {
            bottom: $paddingY; 
        }
    }

    &.is_active {
        span {
            &:nth-of-type(1) {
                top: 50%;
                transform: translateY(-50%) rotate(-30deg);
            }

            &:nth-of-type(2) {
                bottom: 50%;
                transform: translateY(50%) rotate(30deg);
            }
        }
    }
}