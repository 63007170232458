/*------------------------------------------------------------------------
	ニュースリスト
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_newsList {
    &_item {
        padding: 20px 0;
        
        @include g.mq('lg') {
            display: grid;
            grid-template-columns: 254px 1fr;
            align-items: start;
            gap: 20px;
            padding: 32px 0;
        }
    
        &:not(:first-of-type) {
            border-top: 1px solid g.color('border');
        }
    
        &:first-of-type {
            padding-top: 10px;
        }
    
        &:last-of-type {
            padding-bottom: 0;
        }

        // 日付なし
        &.is_none_date {
            @include g.mq('lg') {
                grid-template-columns: 150px 1fr;
                align-items: center;
            }
        }
    }
    
    &_data {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    
    &_date {
        color: g.color('base_light');
    }
    
    &_text {
        margin-top: 5px;
        line-height: math.div(31, 16);
        letter-spacing: 0;
    
        a {
            display: block;
        }
    }

    &_add {
        display: block;

        a {
            color: g.color('key');
            text-decoration: underline;
        }
    }
}