/*------------------------------------------------------------------------
	メッセージ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_message {
    @include g.mq('lg') {
        overflow: hidden;
    }
    
    &_inner {
        max-width: g.$inner-width-sp;
        margin-inline: auto;
        
        @include g.mq('md') {
            display: flex;
            align-items: end;
            flex-direction: row-reverse;
            gap: 30px;
        }
        
        @include g.mq('lg') {
            gap: 80px;
        }
    
        @include g.mq('xl') {
            max-width: g.$inner_width_medium;
        }

        &.is_align_start {
            @include g.mq('md') {
                align-items: flex-start;
            }
        }

        &.is_md_block {
            @include g.mq('md') {
                display: block;
            }

            @include g.mq('lg') {
                display: flex;
            }
        }
    }

    &_textArea {
        @include g.mq('md') {
            flex: 1;
            margin-top: 10px;
        }
    }

    &_title {
        @include g.mq('lg') {
            position: relative;
            width: 50vw;
            margin-right: -50vw;
        }

        &_en {
            font-size: 6rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1.2;
            color: #f4f2f4;
            
            @include g.mq('md') {
                font-size: min(g.get_vw(147, 1920), 14.7rem);
            }
            
            @include g.mq('lg') {
                white-space: nowrap;
                margin-left: -100px;
            }

            &.is_small {
                font-size: 5rem;

                @include g.mq('md') {
                    font-size: min(g.get_vw(100, 1920), 10rem);
                }

                @include g.mq('lg') {
                    margin-left: -150px;
                }
            }
        }

        &_ja {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 10px;
            font-weight: bold;
            
            @include g.mq('md') {
                margin-top: 20px;
                margin-left: 13px;
            }

            &::before {
                content: '';
                width: 50px;
                height: 1px;
                background-color: g.color('key');
            }
        }
    }

    &_text {
        margin-top: 20px;
        font-size: 2rem;
        font-weight: 500;
        line-height: math.div(45.3543, 26);
        letter-spacing: 0.06em;

        @include g.mq('md') {
            font-size: 2.6rem;
            margin-top: 30px;
        }

        &.is_small {
            font-size: 1.6rem;

            @include g.mq('md') {
                font-size: 1.8rem;
            }
        }
    }

    &_foot {
        margin-top: 20px;
        
        @include g.mq('md') {
            margin-top: 40px;
            margin-bottom: 110px;
        }

        @include g.mq('xl') {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &_sign {
        &_position {
            font-size: 1.2rem;
            line-height: math.div(28.3465, 15);

            @include g.mq('md') {
                font-size: 1.5rem;
            }
        }

        &_name {
            font-size: 1.2rem;
            line-height: math.div(31.6829, 21);

            @include g.mq('md') {
                font-size: 2.1rem;
            }
        }
    }

    .el_btn {
        margin-top: 20px;
        
        @include g.mq('md') {
            margin-top: 30px;
        }

        @include g.mq('xl') {
            margin-top: 0;
        }
    }
    
    &_img {
        margin-top: 20px;
        text-align: center;

        @include g.mq('md') {
            width: min(percentage(math.div(598, 1200)), 598px);
            margin-inline: auto;
        }
    }
}