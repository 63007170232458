/*------------------------------------------------------------------------
	グロナビ２
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_subGnav {
	&_list {
		display: flex;
		gap: 10px;
	}

	&_item {
		a {
			display: block;
			padding: 3px 6px;
			font-size: 1rem;
			color: #fff;
			border: 1px solid g.color('white');
			
			@include g.mq('xl') {
				padding: 3px 10px;
				font-size: 1.4rem;
			}
		}
	}
}
