/*------------------------------------------------------------------------
	ボタン
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.el_btn {
	overflow: hidden;
	display: flex;
	align-items: center;
	width: fit-content;
	gap: 15px;
	height: 40px;
	padding: 0 10px 0 21px;
	border-radius: 100vmax;
	background-color: #fff;

	@include g.mq('md') {
		gap: 20px;
		height: 51px;
		padding: 0 15px 0 28px;
	}

	span {
		font-family: g.font('en');
		font-weight: 600;
		line-height: 1.2;
		color: g.color('key');
	}

	&:not(.is_anime_none) {
		@include g.bg_flow();

		.el_circleArrow {
			position: relative;
			z-index: 2;

			.circle,
			.arrow,
			.triArrow {
				transition: 0.3s;
			}
		}

		&:hover {
			.el_circleArrow {
				.circle {
					fill: g.color('white');
				}

				.arrow,
				.triArrow {
					stroke: g.color('key');
				}
			}
		}
	}

	// 大きい
	&.is_large {
		width: 489px;
		max-width: g.$inner-width-sp;
		height: 85px;
		padding: 0 20px;

		@include g.mq('md') {
			height: 105px;
			padding: 0 35px;
		}

		span {
			flex: 1;
			font-size: 1.6rem;
			text-align: center;

			@include g.mq('md') {
				font-size: 2rem;
			}
		}

		.el_circleArrow {
			position: absolute;
			inset: 0 20px 0 auto;
			margin: auto;

			@include g.mq('md') {
				right: 35px;
			}
		}
	}

	// 影あり
	&.is_box_shadow {
		box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);
	}

	// 青色
	&.is_blue {
		background-color: g.color('key');

		span {
			color: #fff;
		}

		.el_circleArrow {
			.circle {
				fill: g.color('white');
			}

			.arrow,
			.triArrow {
				stroke: g.color('key');
			}
		}

		&:not(.is_anime_none) {
			@include g.bg_flow(g.color('white'));

			&:hover {
				span {
					color: g.color('key');
				}
				
				.el_circleArrow {
					.circle {
						fill: g.color('key');
					}

					.arrow,
					.triArrow {
						stroke: g.color('white');
					}
				}
			}
		}
	}

	// 日本語
	&.is_ja {
		span {
			font-family: g.font('base');
			font-weight: 700;
		}
	}
}