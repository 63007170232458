/**************************************************************************
スポーツ事業
**************************************************************************/
@use "sass:math";
@use "../../global" as g;

#business.global {
  // メインビジュアル
  .bl_mvSub.is_small {
    background-image: url("../img/business/global/mv_global.webp");

    @include g.mq('lg') {
      background-position: center bottom 35%;
    }
  }

  // セクション大枠
  .un_section {
    padding: 70px 0 120px;

    @include g.mq("md") {
      padding: 120px 0 200px;
    }

    // 背景画像
    &.is_bg_loop {
      background: url("../img/business/bg_repeat.webp") repeat-y border-box 50%
        50%;
      background-size: 100% auto;
    }

    // グラデーション
    &:first-of-type {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        inset: 0 auto auto 0;
        width: 100%;
        height: 350px;
        background-image: linear-gradient(to bottom, #fff, transparent);
      }

      .ly_inner {
        position: relative;
        z-index: 1;
      }
    }
  }

  // イントロ文
  .un_intro {
    max-width: 1000px;
    margin: 0 auto 70px;

    @include g.mq("md") {
      margin-bottom: 120px;
    }
  }

  .bl_subNav_item {
    background-color: #fff;
  }

  .bl_subNav_text {
    padding: 10px 15px;
    font-size: 0.9em;
    line-height: 2;

    @include g.mq("md") {
      padding: 0 27px 30px;
    }

    @include g.mq("lg") {
      padding: 0 27px 30px;
    }
  }
}
