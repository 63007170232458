/**************************************************************************
    404ページ
**************************************************************************/
@use "../global" as g;
@use "sass:math";

#not-found {

    // メインビジュアル
    .bl_mvSub {
        background-image: url(../img/not-found/mv.webp);
        height: 300px;

        @include g.mq('md') {
            height: 436px;
        }
    }

    // コンテンツ
    .un_sec {
        margin: 70px 0 120px;

        @include g.mq('md') {
            margin: 75px auto 140px;
        }

        p {
            margin-bottom: 1em;
        }

        &_btnList {
            margin-top: 36px;
            display: flex;
            flex-wrap: wrap;
            gap: 18px;

            @include g.mq('md') {
                margin-top: 46px;
                gap: 23px;
            }
        }
    }

}