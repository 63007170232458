/**************************************************************************
    サイトマップ
**************************************************************************/
@use "../global" as g;
@use "sass:math";

#sitemap {

    // メインビジュアル
    .bl_mvSub {
        background-image: url(../img/sitemap/mv.webp);
        height: 300px;

        @include g.mq('md') {
            height: 436px;
        }
    }

    // コンテンツ
    .un_sec {
        margin: 70px 0;

        @include g.mq('md') {
            margin: 75px auto;
        }

        &:last-of-type {
            margin-bottom: 120px;
        }

        &_tit {
            color: g.color('key');
            font-size: 1.6rem;
            border-left: solid 3px g.color('key');
            padding-left: 13px;
            line-height: 1.5;

            @include g.mq('md') {
                font-size: 1.8rem;
            }
        }
    }

    // リンクリスト
    .un_list {
        $this: '.un_list';
        margin-top: 14px;

        &.is_line03 {
            @include g.mq('md') {
                margin-top: 24px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 0 40px;
            }

            #{$this}_item {
                &:nth-of-type(-n + 3) {
                    a {
                        @include g.mq('md') {
                            border-top: 1px solid #e1e1e1;
                        }
                    }
                }
            }
        }

        &_item {
            width: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
                padding: 10px;
                border-bottom: 1px solid #e1e1e1;
            }

            &:first-of-type {
                a {
                    border-top: 1px solid #e1e1e1;
                }
            }
        }

        &_label {
            %icon {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
    
                &::after {
                    content: '';
                    display: block;
                }
                
            }

            &.is_pdf {
                @extend %icon;

                &::after {
                    width: 14px;
                    height: 18px;
                    background: url('../img/common/icon_pdf.svg') no-repeat border-box center/cover;
                }
            }
            
            &.is_blank {
                @extend %icon;
    
                &::after {
                    width: 1em;
                    height: 1em;
                    font-size: 14px;
                    background: url('../img/common/icon_blank.svg') no-repeat border-box center/cover;
                }
            }
        }
    }

    .un_list02 {
        width: max-content;
        list-style: disc;
        padding-left: 24px;

        @include g.mq('md') {
            margin-left: auto;
            display: flex;
            gap: 44px;
        }
    }

    // アイコン
    .un_icon {
        width: 24px;
        aspect-ratio: 1/1;

        @include g.mq('md') {
            width: 32px;
        }

        &_arrow {
            fill: none;
            stroke-miterlimit: 10;
            stroke: g.color('key');
        }
    }

}