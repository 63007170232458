/*------------------------------------------------------------------------
	グロナビ
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_gnav {
	top: map-get(g.$header_h, 'sm');
	
	@include g.mq('md') {
		top: map-get(g.$header_h, 'md');
	}

	@include g.mq('lg_max') {
		height: 0;
		overflow: hidden;
		visibility: hidden;
		transition: 0.3s;
		position: fixed;
		left: 0;
		width: 100%;
		z-index: map-get(g.$z_index, 'header');
		background-color: rgba(#f4fafb, 0.95);

		&.is_show {
			overflow: scroll;
			visibility: visible;
		}
	}
	
	&_inner {
		@include g.mq('lg_max') {
			min-height: calc(100% - map-get(g.$header_h, 'sm'));
			padding-bottom: 30px;
		}
	}
	
	&_list {
		@include g.mq('lg') {
			display: flex;
			flex-wrap: wrap;
			gap: 0 15px;
			width: 100%;
		}
	}

	&_item {
		font-weight: 500;
		line-height: 1.5;

		> label, > a {
			font-size: 1.4rem;

			@include g.mq('lg_max') {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px g.get-inner-side(g.$inner-width-sp);
				color: g.color('base');
				border-bottom: 1px solid g.color('base');

				&::after {
					content: '';
				}
			}

			@include g.mq('xl') {
				font-size: 1.6rem;
			}
		}

		> label {
			transition: 0.2s;
			cursor: pointer;

			@include g.mq('lg_max') {
				&::after {
					@include g.arrow('bottom', 6px, 2px, g.color('key'));
					transition: 0.2s;
				}

				&.is_active {
					&::after {
						transform: rotate(45deg);
					}
				}
			}

			&:hover {
				opacity: 0.7;
			}
		}

		> a {
			@include g.mq('lg_max') {
				&::after {
					@include g.arrow('right', 6px, 2px, g.color('key'));
				}
			}
		}
	}

	input {
		display: none;

		&:checked + .bl_megamenu {
			@include g.mq('lg') {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.el_btn {
		gap: 5px;
		font-family: g.font('base');
		font-size: 1.7rem;
		font-weight: 500;
		box-shadow: 0 0 5px rgba(g.color('base_dark'), 0.3);
	}

	.el_logo {
		margin-top: 20px;
		text-align: center;
	}
}

