/*------------------------------------------------------------------------
	アコーディオン
------------------------------------------------------------------------*/
@use "../../global" as g;
@use "sass:math";

.bl_accordion {
	$this: &;
	border-left: 1px solid g.color('border');
	border-right: 1px solid g.color('border');
    
	&:first-of-type {
		border-top: 1px solid g.color('border');
	}

    &_head {
        cursor: pointer;
        position: relative;
        display: flex;
		justify-content: space-between;
        align-items: center;
		gap: 10px;
        padding: 10px 20px;
		background-color: g.color('white');
		border-bottom: 1px solid g.color('border');
		
		@include g.mq('md') {
			padding: 10px 35px;
		}

		&::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 1em;
            height: 1em;
            font-size: 12px;
            background: url('../img/common/icon_triangle.svg') no-repeat border-box center/cover;
            
            @include g.mq('md') {
                font-size: 18px;
            }
        }
    }

    &_icon {
		position: relative;
        width: 1em;
        height: 1em;
        font-size: 14px;
		
		@include g.mq('md') {
			font-size: 16px;
		}

        &::before, &::after {
            content: '';
            position: absolute;
            inset: 0;
            margin: auto;
            width: 100%;
            height: 1px;
            background-color: g.color('base');
            transition: .2s;
        }

        &::after {
            transform: rotate(90deg);
        }
    }
	
	&_head.is_active {
		#{$this}_icon {
			&::after {
				opacity: 0;
				transform: rotate(0);
			}
		}
	}
    
    &_bodyWrap {
		visibility: hidden;
        overflow: hidden;
		opacity: 0;
		height: 0;
        transition: 0.4s;
        
        &.is_active {
			visibility: visible;
			opacity: 1;
		}
    }
    
    &_body {
        padding: 20px;
		background-color: #edf4fb;
		
		@include g.mq('md') {
			padding: 20px 35px;
			border-bottom: 1px solid g.color('border');
		}
    }
}
