/**************************************************************************
    会社概要　トップ
**************************************************************************/
@use "../../global" as g;
@use "sass:math";

#sustainability.top {

// メインビジュアル
.bl_mvSub {
    background-image: url('../img/sustainability/top/mv.webp');
}

// アンカーリンク
.bl_anchorLink {
    margin-top: 50px;

    @include g.mq('md') {
        margin-top: 85px;
    }
}

//お知らせ
.un_news{
    margin-top: 30px;
    
    @include g.mq('md') {
        margin-top: 65px;
    }

    &_box{
        display: grid;
        grid-template-columns:1fr;
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 20px;
        background: #f5f3f5;
        border-radius: 5px;
        
        @include g.mq('md') {
            grid-template-columns: 156px 1fr;
            padding: 30px 40px;
        }
    }

    &_title{
        padding-bottom: 5px;
        margin-bottom: 15px;
        border-bottom: 1px solid g.color('accent_2');
        font-size: 2.6rem;
        
        @include g.mq('md') {
            padding-bottom: 0;
            margin-right: 30px;
            border-bottom: none;
            border-right: 1px solid g.color('accent_2');
            font-size: 3.4rem;
        }
    }

    .bl_newsList_item{
        border-top: none;
        padding: 15px 0;
    }

    .bl_newsList_text{
        a{
            text-decoration: underline;
        }
    }
}

// トップメッセージ
.un_message {
    margin-top: 30px;

    @include g.mq('md') {
        margin-top: 150px;
    }

    &_head {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            inset: auto 0 30px auto;
            width: calc(100% - g.get-inner-side(g.$inner-width-sp));
            aspect-ratio: 16/9;
            background: url('../img/sustainability/top/message_bg.webp') no-repeat border-box bottom right/cover;
            
            @include g.mq('md') {
                aspect-ratio: 16/10;
            }
            
            @include g.mq('lg') {
                width: 65%;
                aspect-ratio: 16/7;
                inset: -80px 0 auto auto;
            }
        }
    }

    &_title {
        font-size: min(g.get_vw(100, 768), 10rem);
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.2;
        color: #f4f2f4;

        @include g.mq('lg_max') {
            width: g.$inner-width-sp;
            margin-inline: auto;
        }
        
        @include g.mq('lg') {
            position: absolute;
            z-index: 2;
            inset: 30% auto auto 38%;
            translate: 0 -50%;
            font-size: min(g.get_vw(122, 1920), 12.2rem);
        }
    }

    &_img {
        position: relative;
        z-index: 1;
        width: calc(100% - g.get-inner-side(g.$inner-width-sp));
        margin-top: 20px;
        text-align: center;

        @include g.mq('md') {
            margin-top: 75px;
        }

        @include g.mq('lg') {
            width: 65%;
            margin-top: 0;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
        }

    }

    &_subtitle {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 20px;
        font-weight: bold;
        
        @include g.mq('md') {
            margin-top: 40px;
            margin-left: 13px;
        }

        &::before {
            content: '';
            width: 50px;
            height: 1px;
            background-color: g.color('key');
        }
    }

    &_section-title {
        margin-top: 35px;
        font-size: 2rem;
        font-weight: bold;
        line-height: math.div(45.3543, 26);
        letter-spacing: 0.06em;
        color: g.color('key');

        @include g.mq('md') {
            margin-top: 55px;
            font-size: 2.2rem;
        }
    }

    &_textArea {
        margin-top: 35px;

        @include g.mq('md') {
            margin-top: 70px;
        }
    }

    &_text {
        margin-top: 20px;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: math.div(45.3543, 26);
        letter-spacing: 0.06em;
        color: g.color('key');

        @include g.mq('md') {
            font-size: 2rem;
            margin-top: 30px;
        }
    }
}

// サステナブル経営・方針
.un_about {
    margin-top: 50px;
    margin-bottom: 50px;

    @include g.mq('md') {
        margin-top: 90px;
        margin-bottom: 140px;
    }

    .el_btn {
        margin-top: 20px;
    }

    .bl_contentsRow_text{
        margin-top: 5px;
    }

    .bl_contentsRow_img{
        @include g.mq('lg') {
            width: min(percentage(math.div(877, 1920)), 877px);
            right: 0;
        }

        img{
            width: 100%;
        }
    }
}

// 東武トップツアーズのマテリアリティ
.un_material{
    background: url('../img/sustainability/top/materiality.webp') no-repeat border-box right center/cover;

    .ly_inner{
        place-content: center right;
        
        @include g.mq('lg') {
            min-height: 628px;
        }
    }

    .bl_toSite{
        &_title{
            line-height: 1.65;
        }
    
        &_textArea{
            @include g.mq('lg') {
                margin-right: 35px;
            }
        }
    }
}

// リスト
.un_thinking {
    padding: 20px 0 75px;

    @include g.mq('md') {
        padding: 100px 0 128px;
    }

    @include g.mq('lg') {
        padding: 195px 0 128px;
    }

    &_title {
        position: relative;

        &_en {
            position: absolute;
            inset: -100px auto auto 50%;
            translate: -50%;
            z-index: 0;
            font-size: 15rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1.2;
            white-space: nowrap;
            opacity: 0.08;
            background-image: linear-gradient(-45deg, #ffe207 0%, #008ae7 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            
            @include g.mq('lg') {
                top: -170px;
                font-size: 25.2rem;
            }
        }

        &_ja {
            color: g.color('key');
            font-weight: bold;
            letter-spacing: 0.06em;
            line-height: 1.2;
            text-align: center;

            &_small {
                font-size: 2.2rem;

                @include g.mq('md') {
                    font-size: 3.3rem;
                }
            }

            &_large {
                margin-top: 16px;
                font-size: 3rem;

                @include g.mq('md') {
                    font-size: 5.2rem;
                }
            }
        }
    }

    &_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;

        @include g.mq('md') {
            gap: 75px;
        }

        @include g.mq('lg') {
            gap: 100px;
        }

        @include g.mq('xl') {
            max-width: g.$inner_width;
            margin-inline: auto;
        }
    }

    .bl_contentsRow {
        $this: '.bl_contentsRow';

        @include g.mq('lg') {
            display: flex;
            padding-top: 0;
        }

        &_title,
        &_subTitle,
        &_text {
            color: g.color('white');
        }

        &_bg{
            position: relative;
        }
        
        &_textArea{
            @include g.mq('lg') {
                position: relative;
            }
        }

        &_title{
            line-height: 1.75;
            
            @include g.mq('md') {
                font-size: 3.2rem;
            }
            
            @include g.mq('xl') {
                position: relative;
            }

            i{
                position: absolute;
                bottom: g.get_vw(40);
                right: g.get_vw(30);
                width: 100px;

                @include g.mq('md') {
                    bottom: 30px;
                    right: 30px;
                    width: 158px;
                }

                @include g.mq('lg') {
                    top: auto;
                    bottom: -56px;
                    right: -10px;
                }
                
                @include g.mq('xl') {
                    bottom: auto;
                    top: 284px;
                    right: -25px;
                }
            }
        }
        
        &_text{
            margin-top: 10px;
        }

        &_img {
            position: relative;

            @include g.mq('lg') {
                position: static;
            }

            img{
                @include g.mq('md') {
                    height: auto;
                }
                
                @include g.mq('lg') {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &:not(.is_reverse){
            #{$this}_bg {
                @include g.mq('xl') {
                    padding-left: g.get-inner-side(g.$inner-width-sp);
                }
            }
        }

        &.is_reverse{
            flex-direction: row-reverse;

            #{$this}_title{
                i{
                    @include g.mq('lg') {
                        top: -55px;
                        right: -30px;
                    }
                }
            }

            #{$this}_bg {
                @include g.mq('xl') {
                    padding-right: g.get-inner-side(g.$inner-width-sp);
                }
            }
        }

        // 社会に貢献する事業の継続
        &.is_philosophy {
            .bl_contentsRow_bg {
                background: url('../img/sustainability/top/list_bg_01.webp') no-repeat border-box center/cover;
            }
        }

        // 環境負荷に配慮した取組
        &.is_sustainability {
            .bl_contentsRow_bg {
                background: url('../img/sustainability/top/list_bg_02.webp') no-repeat border-box center/cover;
            }
        }

        // 人権の尊重とダイバーシティ＆インクルージョンの推進
        &.is_diversity {
            .bl_contentsRow_bg {
                background: url('../img/sustainability/top/list_bg_03.webp') no-repeat border-box center/cover;
            }

            .bl_contentsRow_textArea{
                @include g.mq('xl') {
                    width: 653px;
                }
            }

            .bl_contentsRow_img {
                @include g.mq('xl') {
                    width: 477px;
                }
            }
        }

        // 人財の確保・育成
        &.is_training {
            .bl_contentsRow_bg {
                background: url('../img/sustainability/top/list_bg_04.webp') no-repeat border-box center/cover;
            }

            .bl_contentsRow_textArea{
                @include g.mq('xl') {
                    width: 410px;
                }
            }

            .bl_contentsRow_img {
                @include g.mq('xl') {
                    width: 754px;
                    right: calc((100% - 1280px) / 2 - 0px);
                }

                img{
                    width: 100%;
                }
            }
        }

        // コーポレート・ガバナンス
        &.is_governance {
            .bl_contentsRow_bg {
                background: url('../img/sustainability/top/list_bg_05.webp') no-repeat border-box center/cover;
            }

            .bl_contentsRow_img {
                @include g.mq('xl') {
                    width: 693px;
                    left: calc((100% - 1280px) / 2 - 40px);
                }

                img{
                    width: 100%;
                }
            }
        }
    }
}

// サステナブルデザインガバナンス
.un_sdgs{
    .ly_inner{
        @include g.mq('md') {
            min-height: 528px;
        }
    }
}

// イニシアティブへの参画・社外認証取得
.un_initiatives{
    padding: 50px 0;
    
    @include g.mq('md') {
        padding: 100px 0;
    }

    &_box{
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background: #FFF;
        border-radius: 5px;
        
        @include g.mq('md') {
            padding: 20px 40px;
        }
        
        @include g.mq('lg') {
            gap: 60px;
            grid-template-columns: 1fr 455px;
            padding: 20px 20px 20px 70px;
        }
    }

    &_title{
        margin-bottom: 10px;
        font-size: 2.6rem;
        color: g.color('key');
        line-height: 1.75;
        
        @include g.mq('md') {
            margin-bottom: 0;
            font-size: 3.2rem;
        }
    }

    &_text{
        font-size: 1.6rem;
        margin-bottom: 15px;
        
        @include g.mq('md') {
            font-size: 1.8rem;
            margin-bottom: 30px;
        }
    }

    &_img{
        img{
            width: 100%;
        }
    }
}

}